import { useEffect, useState, useContext } from "react";
import { Handle, Position } from "reactflow";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { QueuesContext } from "../../contexts/queuesContext";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "#1f1f23",
  border: "2px solid #000",
  boxShadow: 24,
  color: "white",
  p: 4,
};

function QueueNode({ data, isConnectable }) {
  const [selectedQueue, setSelectedQueue] = useState(
    data.nodeInfo.selectedQueue ? data.nodeInfo.selectedQueue : null
  );
  const { queues } = useContext(QueuesContext);

  useEffect(() => {
    // save switchstate to localstorage whenever it changes inside the node with the same id
    const prevFlows = JSON.parse(localStorage.getItem("flows"));
    prevFlows.forEach((flow) => {
      const node = flow.nodes.find((node) => node.id === data.id);
      if (node) {
        const object = {
          selectedQueue: selectedQueue,
        };
        data.nodeInfo = object;

        localStorage.setItem("flows", JSON.stringify(prevFlows));
      }
    });
  }, [selectedQueue]);

  return (
    <div className="queueNodeBody">
      <Tooltip title="Selecione uma fila para continuar o atendimento." arrow>
        <InfoOutlinedIcon
          sx={{
            color: "skyblue",
            position: "absolute",
            right: "1px",
            top: "1px",
            zIndex: "1000",
          }}
          size="small"
        />
      </Tooltip>
      <Autocomplete
        id="combo-box-demo"
        options={queues}
        sx={{
          width: "180px",
          color: "white",
        }}
        onChange={(event, value) => {
          setSelectedQueue(value);
        }}
        getOptionLabel={(option) => option.name}
        noOptionsText="Nenhuma fila encontrada com este nome."
        value={selectedQueue ? selectedQueue : null}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Selecione uma fila"
            sx={{
              color: "white",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              height: "40px",
            }}
            InputLabelProps={{
              style: { color: "white" },
            }}
            inputProps={{
              ...params.inputProps,
              style: { color: "white", height: "3px" },
            }}
          />
        )}
      />

      <Handle
        type="target"
        position={Position.Left}
        id="a"
        isConnectable={isConnectable}
        style={{
          borderRadius: "0 4px 4px 0",
          background: "black",
          width: "10px",
          height: "10px",
          marginLeft: "-1px",
          marginTop: "-1px",
        }}
      />
    </div>
  );
}

export default QueueNode;
