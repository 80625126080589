import React, {
  useCallback,
  useState,
  useRef,
  useContext,
  useEffect,
} from "react";

// Import React Flow components and styles
import ReactFlow, {
  Background,
  addEdge,
  useNodesState,
  useEdgesState,
  MarkerType,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
  MiniMap,
  Controls,
  ReactFlowProvider,
  Panel,
} from "reactflow";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
// Import node components and styles
import TextUpdaterNode from "./TextNode";
import CustomNode from "./CustomNode";
import CustomConnectionLine from "./CustomConnectionLine";
import InputTextNode from "./InputTextNode";
import "reactflow/dist/style.css";
import "./style.css";
import Tooltip from "@mui/material/Tooltip";

// Import context menu and other node components
import ContextMenu from "../../contexts/contextMenu";
import ConditionalNode from "./conditionNode";
import StartNode from "./startNode";
import ApiNode from "./apiNode";
import EndNode from "./endNode";
import ChangeFlowNode from "./changeFlowNode";
import { v4 as uuidv4 } from "uuid";
import SuccessEdge from "./successEdge";
import FailureEdge from "./failureEdge";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import DefaultEdge from "./defaultEdge";
import { ThemeContext } from "../../contexts/themeContext";
import DownloadIcon from "@mui/icons-material/Download"; // Styling for default edges
import UploadIcon from "@mui/icons-material/Upload";
import BackupIcon from "@mui/icons-material/Backup";
import DateNode from "./dateNode";
import DayNode from "./dayNode";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import QueueNode from "./queueNode";
import JsNode from "./jsNode";
import api from "../../utils/api";
import { VariablesContext } from "../../contexts/variablesContext";
import { UsedVariablesContext } from "../../contexts/usedVariablesContext";
import ChoiceNode from "./choiceNode";

const connectionLineStyle = {
  strokeWidth: 3,
  stroke: "black",
};

// Define custom node types
const nodeTypes = {
  custom: CustomNode,
  text: TextUpdaterNode,
  inputText: InputTextNode,
  condition: ConditionalNode,
  start: StartNode,
  api: ApiNode,
  end: EndNode,
  date: DateNode,
  change: ChangeFlowNode,
  day: DayNode,
  queue: QueueNode,
  jsNode: JsNode,
  choice: ChoiceNode,
};

// Define default edge options
const defaultEdgeOptions = {
  style: { strokeWidth: 3, stroke: "black" },
  markerEnd: {
    type: MarkerType.ArrowClosed,
    color: "black",
  },
};

// Define edge types
const edgeTypes = {
  failure: FailureEdge,
  success: SuccessEdge,
  default: DefaultEdge,
};

// Main FlowTest component
const FlowTest = ({ flow, setFlows, flows, setIndex, saveFlows, botId }) => {
  // States to manage nodes, edges, and React Flow instance
  const [nodes, setNodes, onNodesChange] = useNodesState(flow.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(flow.edges);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [menu, setMenu] = useState(null);
  const { hasMiniMap } = useContext(ThemeContext);
  const { darkMode } = useContext(ThemeContext);
  const { variables } = useContext(VariablesContext);
  const { usedVariables } = useContext(UsedVariablesContext);

  const [ctrlPressed] = useState(false);

  // State for tracking the starting point of the selection box
  const [selectionStart] = useState(null);
  const [selectedNodes] = useState([]);
  const [edgeMenuFlag, setEdgeMenuFlag] = useState(false);
  const [selectedEdgeId, setSelectedEdgeId] = useState(null);
  const [selectedEdgePosition, setSelectedEdgePosition] = useState({
    x: 0,
    y: 0,
  });
  // Ref for the React Flow component
  const ref = useRef(null);
  const [selectedMultipleNodesFlag, setSelectedMultipleNodesFlag] =
    useState(false);
  const [history, setHistory] = useState([]);
  const [currentStep, setCurrentStep] = useState(-1);
  //BLOCO UNDO REDO

  // const pushToHistory = useCallback((action, data) => {
  //   if (Array.isArray(action)) {
  //     setHistory((prevHistory) => [
  //       ...prevHistory,
  //       ...action.map((a) => ({ action: a.action, data: a.data })),
  //     ]);

  //     setCurrentStep((prevStep) => prevStep + action.length); // Update currentStep by the length of the action array
  //   } else {
  //     setHistory((prevHistory) => [...prevHistory, { action, data }]);
  //     setCurrentStep((prevStep) => prevStep + 1);
  //   }
  // }, []);

  const pushToHistory = useCallback(
    (action, data) => {
      if (Array.isArray(action)) {
        setHistory((prevHistory) => [
          ...prevHistory,
          ...action.map((a) => ({ action: a.action, data: a.data })),
        ]);
        setCurrentStep((prevStep) => prevStep + action.length); // Update currentStep by the length of the action array
      } else {
        setHistory((prevHistory) => [
          ...prevHistory.slice(0, currentStep + 1),
          { action, data },
        ]);
        setCurrentStep((prevStep) => prevStep + 1);
      }
    },
    [currentStep]
  );

  const redo = useCallback(() => {
    if (currentStep < history.length - 1) {
      let flag = false;
      const nextStep = currentStep + 1;
      const nextAction = history[nextStep];
      if (Array.isArray(nextAction)) {
        nextAction.forEach((action) => {
          switch (action.action) {
            case "addEdge":
              // Redo the action of adding an edge
              setEdges((prevEdges) => [...prevEdges, action.data]);
              break;
            case "addNode":
              // Redo the action of adding a node
              setNodes((prevNodes) => [...prevNodes, action.data]);
              break;
            case "deleteEdge":
              // Redo the action of deleting an edge
              setEdges((prevEdges) =>
                prevEdges.filter((edge) => edge.id !== action.data.id)
              );
              break;
            case "deleteNode":
              // Redo the action of deleting a node
              setNodes((prevNodes) =>
                prevNodes.filter((node) => node.id !== action.data.id)
              );
              break;
            case "deleteNodes":
              // Redo the action of deleting multiple nodes
              setNodes((prevNodes) =>
                prevNodes.filter((node) => !action.data.includes(node))
              );
              break;
            case "deleteEdges":
              // Redo the action of deleting multiple edges
              setEdges((prevEdges) =>
                prevEdges.filter((edge) => !action.data.includes(edge))
              );
              break;
            default:
              break;
          }
        });
      } else {
        switch (nextAction.action) {
          case "addEdge":
            // Redo the action of adding an edge
            setEdges((prevEdges) => [...prevEdges, nextAction.data]);
            break;
          case "addNode":
            // Redo the action of adding a node
            setNodes((prevNodes) => [...prevNodes, nextAction.data]);
            break;
          case "deleteEdge":
            // Redo the action of deleting an edge
            setEdges((prevEdges) =>
              prevEdges.filter((edge) => edge.id !== nextAction.data.id)
            );

            const nodesStep2 = history[nextStep + 1];
            if (nodesStep2?.action === "deleteNode") {
              flag = true;
              // filter the nodes that are not in the nodestep2
              setNodes((prevNodes) =>
                prevNodes.filter((node) => node.id !== nodesStep2.data.id)
              );
              setCurrentStep((prevStep) => prevStep + 2);
            }

            break;
          case "deleteNode":
            // Redo the action of deleting a node
            setNodes((prevNodes) =>
              prevNodes.filter((node) => node.id !== nextAction.data.id)
            );

            const edgesStep = history[nextStep + 1];
            if (edgesStep?.action === "deleteEdge") {
              setEdges((prevEdges) => [...prevEdges, edgesStep.data]);
            }

            break;
          case "deleteNodes":
            setNodes((prevNodes) => {
              const newNodes = prevNodes.filter(
                (node) =>
                  nextAction.data.map((n) => n.id).indexOf(node.id) === -1
              );
              return newNodes;
            });
            break;
          case "deleteEdges":
            // Redo the action of deleting multiple edges
            setEdges((prevEdges) =>
              prevEdges.filter((edge) => !nextAction.data.includes(edge))
            );
            const nodesStep = history[nextStep + 1];
            setNodes((prevNodes) => {
              const newNodes = prevNodes.filter(
                (node) =>
                  nodesStep.data.map((n) => n.id).indexOf(node.id) === -1
              );
              return newNodes;
            });

            setCurrentStep((prevStep) => prevStep + 2);

            break;
          default:
            break;
        }
      }

      if (nextAction.action !== "deleteEdges" && !flag)
        setCurrentStep(nextStep);
      flag = false;
    }
  }, [currentStep, history, setEdges, setNodes]);

  const undo = useCallback(() => {
    if (currentStep >= 0) {
      const lastAction = history[currentStep];
      if (Array.isArray(lastAction)) {
        lastAction.forEach((action) => {
          switch (action.action) {
            case "addEdge":
              // Reverse the action of adding an edge
              setEdges((prevEdges) =>
                prevEdges.filter((edge) => edge.id !== action.data.id)
              );
              break;
            case "addNode":
              // Reverse the action of adding a node
              setNodes((prevNodes) =>
                prevNodes.filter((node) => node.id !== action.data.id)
              );
              break;
            case "deleteEdge":
              // Reverse the action of deleting an edge
              setEdges((prevEdges) => [...prevEdges, action.data]);
              break;
            case "deleteNode":
              // Reverse the action of deleting a node
              setNodes((prevNodes) => [...prevNodes, action.data]);
              break;
            case "deleteNodes":
              setNodes((prevNodes) => prevNodes.concat(action.data));
              const newLastAction = history[currentStep - 1];
              if (newLastAction.action === "deleteEdges") {
                setEdges((prevEdges) => prevEdges.concat(newLastAction.data));
              }
              break;
            case "deleteEdges":
              setEdges((prevEdges) => prevEdges.concat(action.data));
              break;
            default:
              break;
          }
        });
      } else {
        switch (lastAction.action) {
          case "addEdge":
            // Reverse the action of adding an edge
            setEdges((prevEdges) =>
              prevEdges.filter((edge) => edge.id !== lastAction.data.id)
            );
            break;
          case "addNode":
            // Reverse the action of adding a node
            setNodes((prevNodes) =>
              prevNodes.filter((node) => node.id !== lastAction.data.id)
            );
            break;
          case "deleteEdge":
            // Reverse the action of deleting an edge
            setEdges((prevEdges) => [...prevEdges, lastAction.data]);
            break;
          case "deleteNode":
            // Reverse the action of deleting a node
            setNodes((prevNodes) => [...prevNodes, lastAction.data]);
            const newLastAction2 = history[currentStep - 1];
            if (newLastAction2?.action === "deleteEdge") {
              setEdges((prevEdges) => prevEdges.concat(newLastAction2.data));
              setCurrentStep((prevStep) => prevStep - 1);
            }

            break;
          case "deleteNodes":
            setNodes((prevNodes) => prevNodes.concat(lastAction.data));
            const newLastAction = history[currentStep - 1];
            if (newLastAction?.action === "deleteEdges") {
              setEdges((prevEdges) => prevEdges.concat(newLastAction.data));
            }
            setCurrentStep((prevStep) => prevStep - 1);

            break;
          case "deleteEdges":
            setEdges((prevEdges) => prevEdges.concat(lastAction.data));
            break;
          default:
            break;
        }
      }

      setCurrentStep((prevStep) => prevStep - 1);
    }
  }, [currentStep, history, setEdges, setNodes]);

  // FIM BLOCO UNDO REDO

  // Function to export flow as JSON
  useEffect(() => {
    // Export flow as JSON whenever nodes change
    exportFlowAsJSON();
  }, [nodes, edges]);
  const exportFlowAsJSON = () => {
    if (reactFlowInstance) {
      const localFlow = reactFlowInstance.toObject();
      const completeFlow = Object.assign(localFlow, {
        id: flow.id,
        name: flow.name,
        label: flow.label,
      });

      // Update flows state with the modified flow
      setFlows(flows.map((f) => (f.id === flow.id ? completeFlow : f)));
    }
  };

  // Handle connection of nodes
  const onConnect = useCallback(
    (params) => {
      // Check if the source node is already connected
      const isConnected = (params) => {
        const isAlreadyConnected = edges.some(
          (edge) =>
            edge.source === params.source &&
            edge.sourceHandle === params.sourceHandle
        );
        return isAlreadyConnected;
      };

      // Check if source node is already connected
      const isAlreadyConnected = isConnected(params);
      if (isAlreadyConnected) {
        return;
      }

      let edge;
      // Configure edge based on sourceHandle
      if (params.sourceHandle === "success") {
        edge = {
          ...params,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            color: "#4a8e4e",
          },
          tooltipText: "teste0",
          type: "success",
          id: `success${params.source}->${params.target}`,
        };
      } else {
        if (params.sourceHandle === "failure") {
          edge = {
            ...params,
            markerEnd: {
              type: MarkerType.ArrowClosed,
              color: "#a14e4e",
            },
            type: "failure",
            id: `failure${params.source}->${params.target}`,
          };
        } else if (params.sourceHandle === "within") {
          edge = {
            ...params,
            markerEnd: {
              type: MarkerType.ArrowClosed,
              color: "#325048",
            },
            type: "default",
            id: `within${params.source}->${params.target}/Data ou horário dentro do intervalo`,
          };
        } else if (params.sourceHandle === "outside") {
          edge = {
            ...params,
            markerEnd: {
              type: MarkerType.ArrowClosed,
              color: "#325048",
            },
            type: "default",
            id: `outside${params.source}->${params.target}/Data ou horário fora do intervalo`,
          };
        } else {
          edge = {
            ...params,
            markerEnd: {
              type: MarkerType.ArrowClosed,
              color: "#5b5bf7",
            },
            tooltipText: "teste",
            type: "default",
            id: `${params.sourceHandle}-${params.source}->${params.target}/Conexão comum`,
          };
        }
      }

      // Add the edge to the state
      pushToHistory("addEdge", edge);
      setEdges((eds) => addEdge(edge, eds));
    },
    [edges, setEdges]
  );
  // Handle drag over for node drop
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  // Handle node drop
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      // Get the type of the dropped element
      const type = event.dataTransfer.getData("application/reactflow");

      // Check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      // Get the position of the drop
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      // Generate a unique ID for the new node
      const id = uuidv4();

      // Create a new node with the obtained information
      const newNode = {
        id: id,
        type: type,
        position,
        data: {
          label: `${type}`,
          id: id,
          nodeInfo: [],
          setIndex,
        },
      };

      // Add the new node to the state
      setNodes((nds) => nds.concat(newNode));
      pushToHistory("addNode", newNode);
    },
    [reactFlowInstance, setIndex, setNodes]
  );

  // Handle node deletion
  const onNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter(
            (edge) => !connectedEdges.includes(edge)
          );

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `${source}->${target}`,
              source,
              target,
            }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [setEdges, edges, nodes]
  );

  // Handle click on the pane to close context menu
  const onPaneClick = useCallback(() => {
    setMenu(null);
    setEdgeMenuFlag(false);
    setSelectedMultipleNodesFlag(false);
  }, [setMenu, menu]);

  // Handle node right-click to show context menu

  // Edge menu state and handlers

  const animationRef = useRef(null);
  const edgeMenuFlagRef = useRef(false);
  const selectedMultipleNodesFlagRef = useRef(false);

  // Handle edge click to show edge menu
  const handleEdgeClick = (event, edge) => {
    // cancel the animation of the previous edge
    animationRef.current?.cancel();

    setEdgeMenuFlag(true);
    edgeMenuFlagRef.current = true;

    let elemento = document.querySelector(`[id="${edge.id}"]`);

    setSelectedEdgeId(edge.id);
    setSelectedEdgePosition({ x: event.clientY + 50, y: event.clientX });

    animationRef.current = elemento.animate(
      [
        { stroke: "rgb(91,91,247)" },
        { stroke: "aquamarine" },
        { stroke: "rgb(91,91,247)" },
      ],
      {
        duration: 1000,
        iterations: Infinity,
      }
    );
  };

  // Add an event listener to stop the animation when edgeMenuFlag becomes false
  useEffect(() => {
    if (!edgeMenuFlag) {
      animationRef.current?.cancel();
      edgeMenuFlagRef.current = false;
    }
  }, [edgeMenuFlag]);

  // Handle deletion of the selected edge
  const handleDeleteEdge = () => {
    pushToHistory(
      "deleteEdge",
      edges.find((edge) => edge.id === selectedEdgeId)
    );

    setEdges(edges.filter((edge) => edge.id !== selectedEdgeId));
    setEdgeMenuFlag(false);
  };

  const importJSONFromFile = async () => {
    const element = document.createElement("input");
    element.type = "file";
    element.accept = ".txt";
    element.onchange = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = async (readerEvent) => {
        const content = readerEvent.target.result;
        // const json = JSON.parse(content);
        // setFlows(json);
        await api.post("/setValue", {
          key: botId,
          value: content,
        });
        window.location.reload();
      };
    };
    element.click();
  };

  const saveJSONToFile = () => {
    const element = document.createElement("a");
    const object = {
      flows: flows,
      variables: variables,
      usedVariables: usedVariables,
    };
    const file = new Blob([JSON.stringify(object)], {
      // type is text
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "flows.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // CÓDIGO SELEÇÃO MULTIPLA QUANDO APERTA CTRL

  const onNodeContextMenu = useCallback(
    (event, node) => {
      // Prevent native context menu from showing
      event.preventDefault();

      // Calculate position of the context menu
      const pane = ref.current.getBoundingClientRect();
      setMenu({
        id: node.id,
        top: event.clientY < pane.height - 200 && event.clientY,
        left: event.clientX < pane.width - 200 && event.clientX,
        right: event.clientX >= pane.width - 200 && pane.width - event.clientX,
        bottom:
          event.clientY >= pane.height - 200 && pane.height - event.clientY,
      });
    },
    [setMenu, menu]
  );

  const handleSelectionContextMenu = useCallback(
    (event, nodes) => {
      event.preventDefault();
      const pane = ref.current.getBoundingClientRect();
      setMenu({
        id: "selection",
        nodes,
        top: event.clientY < pane.height - 200 && event.clientY,
        left: event.clientX < pane.width - 200 && event.clientX,
        right: event.clientX >= pane.width - 200 && pane.width - event.clientX,
        bottom:
          event.clientY >= pane.height - 200 && pane.height - event.clientY,
      });
    },
    [setMenu]
  );

  return (
    <ReactFlowProvider>
      <div
        style={{
          flexGrow: 1,
          height: "100%",
          width: "100%",
        }}
      >
        {/* Render the React Flow component */}
        <ReactFlow
          nodes={nodes}
          // panOnDrag={ctrlPressed ? [2] : [0, 1, 2]}
          // panOnDrag={[0, 1, 2]}
          selectionOnDrag={true}
          selectionMode="partial"
          onSelectionContextMenu={handleSelectionContextMenu}
          edges={edges}
          selected={selectedNodes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onPaneClick={onPaneClick}
          ref={ref}
          fitView
          onEdgeClick={handleEdgeClick}
          nodeTypes={nodeTypes}
          connectionLineComponent={CustomConnectionLine}
          connectionLineStyle={connectionLineStyle}
          onDrop={onDrop}
          edgeTypes={edgeTypes}
          defaultEdgeOptions={defaultEdgeOptions}
          onDragOver={onDragOver}
          onInit={setReactFlowInstance}
          onNodesDelete={onNodesDelete}
          onNodeContextMenu={onNodeContextMenu}
          // onMouseDown={handleMouseDown}
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {/* Background styling */}
          <Background
            variant="dots"
            gap={50}
            size={2}
            color="#2f2f39"
            style={{
              background: darkMode === "dark" ? "#1f1f23" : "#949391",
            }}
          />

          {/* MiniMap and Controls */}
          {hasMiniMap && (
            <>
              <MiniMap
                style={{
                  background: "#1f1f23",
                }}
              />
              <Controls />
            </>
          )}
          <Panel position="right">
            <Tooltip title="Importar/Exportar" placement="top">
              <Button
                sx={{
                  color: "white",
                  background: darkMode === "dark" ? "#1f1f23" : "#949391",
                  ":hover": { background: "#18181b", cursor: "pointer" },
                  fontSize: "1.1rem",
                  height: "3rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                label="Export"
              >
                <BackupIcon style={{ color: "white" }} />
              </Button>
              <Popover
                id="menu-list-grow"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                sx={{
                  backgroundColor: "transparent",
                  color: "transparent",
                  background: "transparent",
                }}
                style={{
                  backgroundColor: "transparent",
                  color: "transparent",
                  background: "transparent",
                }}
                anchorPosition={{ top: 400, left: 400 }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "transparent",
                    color: "transparent",
                    background: "#1f1f23",
                  }}
                >
                  <MenuItem
                    onClick={saveJSONToFile}
                    sx={{
                      color: "white",
                      background: darkMode === "dark" ? "#1f1f23" : "#949391",
                      ":hover": { background: "#18181b", cursor: "grab" },
                      fontSize: "1.1rem",
                      height: "3rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // round edges
                    }}
                  >
                    <DownloadIcon style={{ color: "white" }} />
                  </MenuItem>
                  <MenuItem
                    onClick={importJSONFromFile}
                    sx={{
                      color: "white",
                      background: darkMode === "dark" ? "#1f1f23" : "#949391",

                      ":hover": { background: "#18181b", cursor: "grab" },
                      fontSize: "1.1rem",
                      height: "3rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // round edges
                    }}
                  >
                    <UploadIcon />
                  </MenuItem>
                </div>
              </Popover>
            </Tooltip>
          </Panel>
          <Panel
            position="right"
            style={{
              marginTop: "4rem",
            }}
          >
            <Tooltip title="Salvar flows" placement="top">
              <Button
                sx={{
                  color: "white",
                  background: darkMode === "dark" ? "#1f1f23" : "#949391",
                  ":hover": { background: "#18181b", cursor: "pointer" },
                  fontSize: "1.1rem",
                  height: "3rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={saveFlows}
                label="Salvar"
              >
                <SaveAsIcon style={{ color: "white" }} />
              </Button>
            </Tooltip>
          </Panel>
          <Panel position="left">
            <Tooltip title="Desfazer">
              <Button
                style={{
                  background: "transparent",
                }}
              >
                <UndoIcon onClick={undo} style={{ color: "white" }} />
              </Button>
            </Tooltip>
            <Tooltip title="Refazer">
              <Button
                style={{
                  background: "transparent",
                }}
              >
                <RedoIcon onClick={redo} style={{ color: "white" }} />
              </Button>
            </Tooltip>
          </Panel>

          {/* Render context menu if available */}
          {menu && (
            <ContextMenu
              {...menu}
              edges={edges}
              pushToHistory={pushToHistory}
            />
          )}

          {/* Render edge menu if available */}
          {edgeMenuFlag && (
            <div
              style={{
                position: "absolute",
                top: selectedEdgePosition.x - 180,
                left: selectedEdgePosition.y + 10,
                zIndex: 100,
                background: "transparent",
                borderRadius: 5,
                padding: 10,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Button
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  color: "darkred",
                }}
                startIcon={<DeleteIcon size="large" />}
                onClick={handleDeleteEdge}
              ></Button>
            </div>
          )}
        </ReactFlow>
        {ctrlPressed && selectionStart && (
          <div
            id="selection-box"
            style={{
              position: "absolute",
              border: "2px dashed #5b5bf7",
              pointerEvents: "none", // Make sure it doesn't interfere with other events
              zIndex: 9999,
            }}
          />
        )}
      </div>
    </ReactFlowProvider>
  );
};

export default FlowTest;
