export const basicTemplate = {
  flows: [
    {
      nodes: [
        {
          width: 180,
          height: 40,
          id: "307695a0-acdf-45df-8936-f60f2f24575e",
          type: "start",
          position: { x: -510.84045780910213, y: 14.330315877599617 },
          data: { label: "start", id: "307695a0-acdf-45df-8936-f60f2f24575e" },
          selected: false,
          positionAbsolute: { x: -510.84045780910213, y: 14.330315877599617 },
          dragging: false,
        },
        {
          width: 180,
          height: 40,
          id: "a437aa2f-b026-4c40-a9e3-891b1828b111",
          type: "end",
          position: { x: 523.7440182515255, y: 364.27958217082914 },
          data: {
            label: "end",
            id: "a437aa2f-b026-4c40-a9e3-891b1828b111",
            nodeInfo: [],
          },
          positionAbsolute: { x: 523.7440182515255, y: 364.27958217082914 },
          selected: false,
          dragging: false,
        },
        {
          width: 250,
          height: 334,
          id: "63536f4c-2a81-4c88-8030-6eef50efcc0e",
          type: "choice",
          position: { x: -215.1719670112282, y: -217.83155102053624 },
          data: {
            label: "choice",
            id: "63536f4c-2a81-4c88-8030-6eef50efcc0e",
            nodeInfo: {
              text: "Olá! Este é o nó de múltipla escolha. O usuário deve escolher dentre os números abaixo para escolher uma opção.",
              options: ["Nova opção", "Nova opção"],
            },
          },
          selected: false,
          positionAbsolute: { x: -215.1719670112282, y: -217.83155102053624 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "716e9b46-d1b2-49df-87cd-3f050e41d368",
          type: "text",
          position: { x: 175.35651636006125, y: -193.2025886152138 },
          data: {
            label: "text",
            id: "716e9b46-d1b2-49df-87cd-3f050e41d368",
            nodeInfo: { inputValue: "Escolheu opção 1. " },
          },
          selected: false,
          positionAbsolute: { x: 175.35651636006125, y: -193.2025886152138 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "f2354993-fe8e-47ab-a36c-6c3542837728",
          type: "text",
          position: { x: 173.6706227073781, y: -65.44443319162869 },
          data: {
            label: "text",
            id: "f2354993-fe8e-47ab-a36c-6c3542837728",
            nodeInfo: {
              inputValue:
                "Escolheu opção 2. Por favor, informe um número. (Este é apenas um exemplo de caso de uso).",
            },
          },
          selected: false,
          positionAbsolute: { x: 173.6706227073781, y: -65.44443319162869 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "78b25d6f-cc88-4b9e-918e-aeedd46aff40",
          type: "text",
          position: { x: -205.28516447747327, y: 155.6776149505423 },
          data: {
            label: "text",
            id: "78b25d6f-cc88-4b9e-918e-aeedd46aff40",
            nodeInfo: { inputValue: "Não escolheu nenhuma das opções" },
          },
          selected: false,
          positionAbsolute: { x: -205.28516447747327, y: 155.6776149505423 },
          dragging: false,
        },
        {
          width: 200,
          height: 63,
          id: "550529ab-1012-4553-a71b-b5c4194e21c8",
          type: "change",
          position: { x: 548.2338463078776, y: -205.78259790653135 },
          data: {
            label: "change",
            id: "550529ab-1012-4553-a71b-b5c4194e21c8",
            nodeInfo: {
              switchState: true,
              selectedNode: "a95d8da0-de5e-4ae0-8f46-3b709f94cfa8",
              flowId: "c69c0160-d7fb-4414-a5d1-7f988698e8e3",
            },
          },
          selected: false,
          positionAbsolute: { x: 548.2338463078776, y: -205.78259790653135 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "03776342-c081-425a-88a7-5c26faa27442",
          type: "inputText",
          position: { x: 529.5784427407616, y: -51.70682968189912 },
          data: {
            label: "inputText",
            id: "03776342-c081-425a-88a7-5c26faa27442",
            nodeInfo: [],
          },
          selected: false,
          positionAbsolute: { x: 529.5784427407616, y: -51.70682968189912 },
          dragging: false,
        },
        {
          width: 180,
          height: 40,
          id: "ace8ec7e-d484-45ae-8289-71cbda422104",
          type: "end",
          position: { x: -189.6122852411146, y: 286.527104464674 },
          data: {
            label: "end",
            id: "ace8ec7e-d484-45ae-8289-71cbda422104",
            nodeInfo: [],
          },
          selected: false,
          positionAbsolute: { x: -189.6122852411146, y: 286.527104464674 },
          dragging: false,
        },
        {
          width: 250,
          height: 163,
          id: "512d831e-9f1f-4b0c-a2a1-904ab82b93ea",
          type: "condition",
          position: { x: 170.52065448140337, y: 88.12357309993737 },
          data: {
            label: "condition",
            id: "512d831e-9f1f-4b0c-a2a1-904ab82b93ea",
            nodeInfo: {
              conditions: {
                0: [
                  {
                    variable: {
                      label: "respostaUsuario",
                      type: "texto",
                      data: { label: "respostaUsuario" },
                    },
                    operator: ">",
                    value: "10",
                  },
                ],
              },
            },
          },
          selected: false,
          positionAbsolute: { x: 170.52065448140337, y: 88.12357309993737 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "87711741-32be-4477-ad2d-6188a9f59d0f",
          type: "text",
          position: { x: 504.70805055638107, y: 103.9215227325727 },
          data: {
            label: "text",
            id: "87711741-32be-4477-ad2d-6188a9f59d0f",
            nodeInfo: {
              inputValue:
                "Você escolheu {{respostaUsuario}}. {{respostaUsuario}} é maior que 10.",
            },
          },
          selected: false,
          positionAbsolute: { x: 504.70805055638107, y: 103.9215227325727 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "ddae3e43-6057-40aa-b9dc-b8201b4376ed",
          type: "text",
          position: { x: 507.71483661710033, y: 234.37927104426825 },
          data: {
            label: "text",
            id: "ddae3e43-6057-40aa-b9dc-b8201b4376ed",
            nodeInfo: { inputValue: "Muito obrigado pelo contato. Até mais!" },
          },
          selected: false,
          positionAbsolute: { x: 507.71483661710033, y: 234.37927104426825 },
          dragging: false,
        },
        {
          width: 200,
          height: 87,
          id: "019d17ef-a895-4464-867c-427874cc3175",
          type: "change",
          position: { x: 193.4085322885291, y: 301.9432500719546 },
          data: {
            label: "change",
            id: "019d17ef-a895-4464-867c-427874cc3175",
            nodeInfo: {
              switchState: false,
              selectedNode: null,
              flowId: "05cd94bf-a096-4a8d-bfb9-162b25cfcff2",
            },
          },
          selected: false,
          positionAbsolute: { x: 193.4085322885291, y: 301.9432500719546 },
          dragging: false,
        },
      ],
      edges: [
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "d80e4987-b97d-4f9d-9085-d0156718b0c2",
          sourceHandle: "b",
          target: "a437aa2f-b026-4c40-a9e3-891b1828b111",
          targetHandle: "b",
          tooltipText: "teste",
          type: "default",
          id: "b-d80e4987-b97d-4f9d-9085-d0156718b0c2->a437aa2f-b026-4c40-a9e3-891b1828b111/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "1eae3eec-28f7-46f0-a3db-2bba44838c7c",
          sourceHandle: "b",
          target: "a437aa2f-b026-4c40-a9e3-891b1828b111",
          targetHandle: "b",
          tooltipText: "teste",
          type: "default",
          id: "b-1eae3eec-28f7-46f0-a3db-2bba44838c7c->a437aa2f-b026-4c40-a9e3-891b1828b111/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "ed73700d-56e6-4d93-b93e-c7e17e6a1084",
          sourceHandle: "b",
          target: "a437aa2f-b026-4c40-a9e3-891b1828b111",
          targetHandle: "b",
          tooltipText: "teste",
          type: "default",
          id: "b-ed73700d-56e6-4d93-b93e-c7e17e6a1084->a437aa2f-b026-4c40-a9e3-891b1828b111/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "1e88a64f-ff96-471d-876b-c375fd1fa0d4",
          sourceHandle: "b",
          target: "a437aa2f-b026-4c40-a9e3-891b1828b111",
          targetHandle: "b",
          tooltipText: "teste",
          type: "default",
          id: "b-1e88a64f-ff96-471d-876b-c375fd1fa0d4->a437aa2f-b026-4c40-a9e3-891b1828b111/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "e6bbdc2e-663c-4b0d-90d9-d9682674d8b9",
          sourceHandle: "b",
          target: "a437aa2f-b026-4c40-a9e3-891b1828b111",
          targetHandle: "b",
          tooltipText: "teste",
          type: "default",
          id: "b-e6bbdc2e-663c-4b0d-90d9-d9682674d8b9->a437aa2f-b026-4c40-a9e3-891b1828b111/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "307695a0-acdf-45df-8936-f60f2f24575e",
          sourceHandle: "b",
          target: "63536f4c-2a81-4c88-8030-6eef50efcc0e",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-307695a0-acdf-45df-8936-f60f2f24575e->63536f4c-2a81-4c88-8030-6eef50efcc0e/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "63536f4c-2a81-4c88-8030-6eef50efcc0e",
          sourceHandle: "0",
          target: "716e9b46-d1b2-49df-87cd-3f050e41d368",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "0-63536f4c-2a81-4c88-8030-6eef50efcc0e->716e9b46-d1b2-49df-87cd-3f050e41d368/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "63536f4c-2a81-4c88-8030-6eef50efcc0e",
          sourceHandle: "1",
          target: "f2354993-fe8e-47ab-a36c-6c3542837728",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "1-63536f4c-2a81-4c88-8030-6eef50efcc0e->f2354993-fe8e-47ab-a36c-6c3542837728/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "63536f4c-2a81-4c88-8030-6eef50efcc0e",
          sourceHandle: "else",
          target: "78b25d6f-cc88-4b9e-918e-aeedd46aff40",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "else-63536f4c-2a81-4c88-8030-6eef50efcc0e->78b25d6f-cc88-4b9e-918e-aeedd46aff40/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "716e9b46-d1b2-49df-87cd-3f050e41d368",
          sourceHandle: "b",
          target: "550529ab-1012-4553-a71b-b5c4194e21c8",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-716e9b46-d1b2-49df-87cd-3f050e41d368->550529ab-1012-4553-a71b-b5c4194e21c8/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "f2354993-fe8e-47ab-a36c-6c3542837728",
          sourceHandle: "b",
          target: "03776342-c081-425a-88a7-5c26faa27442",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-f2354993-fe8e-47ab-a36c-6c3542837728->03776342-c081-425a-88a7-5c26faa27442/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "78b25d6f-cc88-4b9e-918e-aeedd46aff40",
          sourceHandle: "b",
          target: "ace8ec7e-d484-45ae-8289-71cbda422104",
          targetHandle: "b",
          tooltipText: "teste",
          type: "default",
          id: "b-78b25d6f-cc88-4b9e-918e-aeedd46aff40->ace8ec7e-d484-45ae-8289-71cbda422104/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "03776342-c081-425a-88a7-5c26faa27442",
          sourceHandle: "b",
          target: "512d831e-9f1f-4b0c-a2a1-904ab82b93ea",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-03776342-c081-425a-88a7-5c26faa27442->512d831e-9f1f-4b0c-a2a1-904ab82b93ea/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "512d831e-9f1f-4b0c-a2a1-904ab82b93ea",
          sourceHandle: "0",
          target: "87711741-32be-4477-ad2d-6188a9f59d0f",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "0-512d831e-9f1f-4b0c-a2a1-904ab82b93ea->87711741-32be-4477-ad2d-6188a9f59d0f/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "87711741-32be-4477-ad2d-6188a9f59d0f",
          sourceHandle: "b",
          target: "ddae3e43-6057-40aa-b9dc-b8201b4376ed",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-87711741-32be-4477-ad2d-6188a9f59d0f->ddae3e43-6057-40aa-b9dc-b8201b4376ed/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "ddae3e43-6057-40aa-b9dc-b8201b4376ed",
          sourceHandle: "b",
          target: "a437aa2f-b026-4c40-a9e3-891b1828b111",
          targetHandle: "b",
          tooltipText: "teste",
          type: "default",
          id: "b-ddae3e43-6057-40aa-b9dc-b8201b4376ed->a437aa2f-b026-4c40-a9e3-891b1828b111/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "512d831e-9f1f-4b0c-a2a1-904ab82b93ea",
          sourceHandle: "else",
          target: "019d17ef-a895-4464-867c-427874cc3175",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "else-512d831e-9f1f-4b0c-a2a1-904ab82b93ea->019d17ef-a895-4464-867c-427874cc3175/Conexão comum",
        },
      ],
      viewport: {
        x: 1156.132747753678,
        y: 232.25123487257883,
        zoom: 0.6999731726921323,
      },
      id: "b260685a-dc40-4963-a01a-a8d8a98f1426",
      name: "Flow 1",
      label: "Flow 1",
    },
    {
      nodes: [
        {
          width: 180,
          height: 40,
          id: "a95d8da0-de5e-4ae0-8f46-3b709f94cfa8",
          type: "start",
          position: { x: -168.80742690485891, y: -57.37055056329613 },
          data: { label: "start", id: "a95d8da0-de5e-4ae0-8f46-3b709f94cfa8" },
          selected: false,
          positionAbsolute: { x: -168.80742690485891, y: -57.37055056329613 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "0d9a0ddf-a93c-4c7a-b91b-495514f44ca7",
          type: "text",
          position: { x: 84.61497786822025, y: -88.32688774084033 },
          data: {
            label: "text",
            id: "0d9a0ddf-a93c-4c7a-b91b-495514f44ca7",
            nodeInfo: { inputValue: "Você está no flow 2. Até mais!" },
          },
          selected: false,
          positionAbsolute: { x: 84.61497786822025, y: -88.32688774084033 },
          dragging: false,
        },
        {
          width: 180,
          height: 40,
          id: "fb1624fc-567a-40c0-b9c8-3143bf992d24",
          type: "end",
          position: { x: 398.88373121812117, y: -56.11651689888376 },
          data: {
            label: "end",
            id: "fb1624fc-567a-40c0-b9c8-3143bf992d24",
            nodeInfo: [],
          },
          selected: true,
          positionAbsolute: { x: 398.88373121812117, y: -56.11651689888376 },
          dragging: false,
        },
      ],
      edges: [
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "a95d8da0-de5e-4ae0-8f46-3b709f94cfa8",
          sourceHandle: "b",
          target: "0d9a0ddf-a93c-4c7a-b91b-495514f44ca7",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-a95d8da0-de5e-4ae0-8f46-3b709f94cfa8->0d9a0ddf-a93c-4c7a-b91b-495514f44ca7/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "0d9a0ddf-a93c-4c7a-b91b-495514f44ca7",
          sourceHandle: "b",
          target: "fb1624fc-567a-40c0-b9c8-3143bf992d24",
          targetHandle: "b",
          tooltipText: "teste",
          type: "default",
          id: "b-0d9a0ddf-a93c-4c7a-b91b-495514f44ca7->fb1624fc-567a-40c0-b9c8-3143bf992d24/Conexão comum",
        },
      ],
      viewport: {
        x: 659.8029141146649,
        y: 351.46095064991107,
        zoom: 1.1486983549970349,
      },
      id: "c69c0160-d7fb-4414-a5d1-7f988698e8e3",
      name: "Flow 2",
      label: "Flow 2",
    },
    {
      nodes: [
        {
          width: 180,
          height: 40,
          id: "6da694c0-3a43-456c-9b41-e49218cf91c3",
          type: "start",
          position: { x: -199.5, y: -11.5 },
          data: { label: "start", id: "6da694c0-3a43-456c-9b41-e49218cf91c3" },
          selected: false,
          positionAbsolute: { x: -199.5, y: -11.5 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "a370dd53-9666-4e0a-82f1-660307a87869",
          type: "text",
          position: { x: 51, y: -44.499999999999986 },
          data: {
            label: "text",
            id: "a370dd53-9666-4e0a-82f1-660307a87869",
            nodeInfo: {
              inputValue:
                "como {{respostaUsuario}} <= 10, você veio para o Flow 3",
            },
          },
          selected: true,
          positionAbsolute: { x: 51, y: -44.499999999999986 },
          dragging: false,
        },
        {
          width: 180,
          height: 40,
          id: "df25e012-6571-4730-b4b0-f9d29b1f74e5",
          type: "end",
          position: { x: 312, y: -12.5 },
          data: {
            label: "end",
            id: "df25e012-6571-4730-b4b0-f9d29b1f74e5",
            nodeInfo: [],
          },
          selected: false,
          positionAbsolute: { x: 312, y: -12.5 },
          dragging: false,
        },
      ],
      edges: [
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "6da694c0-3a43-456c-9b41-e49218cf91c3",
          sourceHandle: "b",
          target: "a370dd53-9666-4e0a-82f1-660307a87869",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-6da694c0-3a43-456c-9b41-e49218cf91c3->a370dd53-9666-4e0a-82f1-660307a87869/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "a370dd53-9666-4e0a-82f1-660307a87869",
          sourceHandle: "b",
          target: "df25e012-6571-4730-b4b0-f9d29b1f74e5",
          targetHandle: "b",
          tooltipText: "teste",
          type: "default",
          id: "b-a370dd53-9666-4e0a-82f1-660307a87869->df25e012-6571-4730-b4b0-f9d29b1f74e5/Conexão comum",
        },
      ],
      viewport: { x: 643.5, y: 414, zoom: 2 },
      id: "05cd94bf-a096-4a8d-bfb9-162b25cfcff2",
      name: "Flow 3",
      label: "Flow 3",
    },
  ],
  variables: [
    { label: "IDUsuarioLogado", type: "texto", data: { label: "1" } },
    {
      label: "PrimeiraMensagem",
      type: "texto",
      data: { label: "PrimeiraMensagem" },
    },
    {
      label: "respostaUsuario",
      type: "texto",
      data: { label: "respostaUsuario" },
    },
  ],
  usedVariables: [
    {
      nodeId: "03776342-c081-425a-88a7-5c26faa27442",
      variableId: "respostaUsuario",
    },
    {
      nodeId: "512d831e-9f1f-4b0c-a2a1-904ab82b93ea",
      variableId: "respostaUsuario",
      conditionIndex: 0,
      index: "0",
    },
    {
      variableId: "respostaUsuario",
      nodeId: "87711741-32be-4477-ad2d-6188a9f59d0f",
    },
    {
      label: "respostaUsuario",
      nodeId: "87711741-32be-4477-ad2d-6188a9f59d0f",
    },
    {
      variableId: "respostaUsuario",
      nodeId: "87711741-32be-4477-ad2d-6188a9f59d0f",
    },
    {
      variableId: "respostaUsuario",
      nodeId: "a370dd53-9666-4e0a-82f1-660307a87869",
    },
  ],
};
