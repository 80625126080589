import { useCallback, useEffect, useState, useContext } from "react";
import { Handle, Position } from "reactflow";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { VariablesContext } from "../../contexts/variablesContext";
import { UsedVariablesContext } from "../../contexts/usedVariablesContext";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";
import DangerousIcon from "@mui/icons-material/Dangerous";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import InfoIcon from "@mui/icons-material/Info";
import Editor from "@monaco-editor/react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "#1f1f23",
  border: "2px solid #000",
  boxShadow: 24,
  color: "white",
  p: 4,
};

const httpRequestOptions = [
  {
    label: "GET",
  },
  {
    label: "POST",
  },
  {
    label: "PUT",
  },
  {
    label: "DELETE",
  },
  {
    label: "PATCH",
  },
];

function ApiNode({ data, isConnectable }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [httpRequestType, setHttpRequestType] = useState(
    data.nodeInfo?.httpRequestType ?? "GET"
  );
  const [httpRequestUrl, setHttpRequestUrl] = useState(
    data.nodeInfo?.httpRequestUrl ?? ""
  );
  const [requestBody, setRequestBody] = useState(
    data.nodeInfo?.requestBody ?? ""
  );
  const [requestHeaders, setRequestHeaders] = useState(
    data.nodeInfo?.requestHeaders ?? ""
  );
  const [responseBody, setResponseBody] = useState(
    data.nodeInfo?.responseBody ?? ""
  );
  const [value, setValue] = useState(0);
  const [whatsWritten, setWhatsWritten] = useState(
    data.nodeInfo?.selectedVariable?.label ?? ""
  );
  const { variables, setVariables } = useContext(VariablesContext);
  const [selectedVariable, setSelectedVariable] = useState(
    data.nodeInfo?.selectedVariable ?? null
  );
  const { usedVariables, setUsedVariables } = useContext(UsedVariablesContext);
  const [bodyJsonChecked, setBodyJsonChecked] = useState(true);
  const [headersJsonChecked, setHeadersJsonChecked] = useState(true);
  const [apiSpec, setApiSpec] = useState(data.nodeInfo?.spec ?? "");
  const [testApiResult, setTestApiResult] = useState(null);
  const [isError, setIsError] = useState(false);
  const [description, setDescription] = useState(
    data.nodeInfo?.description ?? ""
  );
  useEffect(() => {
    // save httpRequestType to localstorage whenever it changes inside the node with the same id
    const prevFlows = JSON.parse(localStorage.getItem("flows"));
    prevFlows?.forEach((flow) => {
      const node = flow.nodes.find((node) => node.id === data.id);
      if (node) {
        // take the nodeInfo from the node and update it
        const object = {
          httpRequestType: httpRequestType,
          httpRequestUrl: httpRequestUrl,
          requestBody: requestBody,
          requestHeaders: requestHeaders,
          responseBody: responseBody,
          selectedVariable: selectedVariable,
          spec: apiSpec,
          flowId: flow.id,
          description: description,
        };

        data.nodeInfo = object;

        localStorage.setItem("flows", JSON.stringify(prevFlows));
      }
    });
  }, [
    httpRequestType,
    httpRequestUrl,
    requestBody,
    requestHeaders,
    responseBody,
    selectedVariable,
    apiSpec,
    data,
  ]);

  const variablesWithoutIDUsuarioLogadoAndPrimeiraMensagem = variables?.filter(
    (variable) =>
      variable.label !== "IDUsuarioLogado" &&
      variable.label !== "PrimeiraMensagem"
  );

  const handleSelectVariable = (event, value) => {
    // Check if the variable already exists
    const variableExists = usedVariables.find(
      (variable) => variable.nodeId === data.id
    );

    // If it exists, update it
    if (variableExists) {
      const prevUsedVariables = JSON.parse(
        localStorage.getItem("usedVariables")
      );
      const updatedUsedVariables = prevUsedVariables.map((variable) => {
        if (variable.nodeId === data.id) {
          variable.variable = value;
        }
        return variable;
      });
      setUsedVariables(updatedUsedVariables);
      localStorage.setItem(
        "usedVariables",
        JSON.stringify(updatedUsedVariables)
      );
    } else {
      // If it doesn't exist, create it
      const newUsedVariable = {
        nodeId: data.id,
        variableId: value.label,
        variable: value,
      };
      setUsedVariables([...usedVariables, newUsedVariable]);
    }

    setSelectedVariable(value);
  };
  const handleNewVariable = (event, value) => {
    if (!variables.includes(whatsWritten)) {
      const newVariable = {
        label: whatsWritten,
        type: "texto",
        data: { label: whatsWritten },
      };
      setVariables([...variables, newVariable]);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const BodyTab = (props) => {
    const { value, index } = props;

    return (
      <div
        role="tabpanel"
        id={`simple-tabpanel-${index}`}
        hidden={value !== index}
      >
        <Paper
          elevation={3}
          style={{
            width: "80%",
            marginLeft: "10%",
            marginTop: "20px",
            height: "fit-content",
            backgroundColor: "#18181b",
            color: "white",
            padding: "10px",
          }}
        >
          Envie um JSON no formato para o corpo da requisição. Assegure-se de
          que o JSON seja válido.
          <Tooltip
            title="Para adicionar uma variável no corpo da requisição, adicione o nome da variável entre aspas e chaves duplas. Ex: ''{{nomeDaVariavel}}''"
            placement="top"
            arrow
            sx={{
              color: "white",
            }}
          >
            <InfoIcon
              sx={{
                color: "white",
              }}
            />
          </Tooltip>
        </Paper>
        <div
          style={{
            marginLeft: "15%",
            marginTop: "20px",
          }}
        >
          <Editor
            onChange={(event) => setRequestBody(event)}
            value={requestBody}
            theme="vs-dark"
            language="json"
            width="80%"
            height="300px"
          />
        </div>
      </div>
    );
  };
  const DescriptionTab = (props) => {
    const { value, index } = props;

    return (
      <div
        role="tabpanel"
        id={`simple-tabpanel-${index}`}
        hidden={value !== index}
      >
        <Paper
          elevation={3}
          style={{
            width: "80%",
            marginLeft: "10%",
            marginTop: "20px",
            height: "fit-content",
            backgroundColor: "#18181b",
            color: "white",
            padding: "10px",
          }}
        >
          Descreva o comportamento da API para facilitar o entendimento futuro.
        </Paper>

        <TextField
          onChange={(event) => setDescription(event.target.value)}
          value={description}
          hidden={value !== index}
          InputLabelProps={{
            style: { color: "white" },
          }}
          inputProps={{
            style: { color: "white", height: "165px" },
          }}
          variant="outlined"
          sx={{
            width: "80%",
            color: "white",
            border: "1px solid white",
            borderColor: "white",
            height: "200px",
            marginLeft: "10%",
            marginTop: "20px",
          }}
          multiline
        />
      </div>
    );
  };

  const HeadersTab = (props) => {
    const { value, index } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
      >
        <Paper
          elevation={3}
          style={{
            width: "80%",
            marginLeft: "10%",
            marginTop: "20px",
            height: "fit-content",
            backgroundColor: "#18181b",
            color: "white",
            padding: "10px",
          }}
        >
          Envie os headers da requisição.
        </Paper>
        <TextField
          onChange={(event) => setRequestHeaders(event.target.value)}
          value={requestHeaders}
          hidden={value !== index}
          InputLabelProps={{
            style: { color: "white" },
          }}
          inputProps={{
            style: { color: "white", height: "165px" },
          }}
          variant="outlined"
          sx={{
            width: "80%",
            color: "white",
            border: "1px solid white",
            borderColor: "white",
            height: "200px",
            marginLeft: "10%",
            marginTop: "20px",
          }}
          multiline
        />
      </div>
    );
  };

  const ResponseTab = (props) => {
    const { value, index } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
      >
        <Paper
          elevation={3}
          style={{
            width: "80%",
            marginLeft: "10%",
            marginTop: "20px",
            height: "fit-content",
            backgroundColor: "#18181b",
            color: "white",
            padding: "10px",
          }}
        >
          Selecione a variável onde deseja armazenar a resposta da requisição. E
          especificação da API. (Mudar depois)
        </Paper>
        <Autocomplete
          onInputChange={(event, value) => {
            setWhatsWritten(value);
          }}
          disablePortal
          id="combo-box-demo"
          options={
            variablesWithoutIDUsuarioLogadoAndPrimeiraMensagem
              ? variablesWithoutIDUsuarioLogadoAndPrimeiraMensagem
              : []
          }
          value={selectedVariable ? selectedVariable : null}
          sx={{
            width: "80%",
            marginLeft: "10%",
            marginTop: "20px",
            color: "white",
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"variáveis"}
              InputLabelProps={{
                style: { color: "white" },
              }}
              inputProps={{
                ...params.inputProps,
                style: { color: "white" },
              }}
              value={selectedVariable ? selectedVariable : null}
            />
          )}
          onChange={(event, value) => {
            handleSelectVariable(event, value);
          }}
          noOptionsText={
            <button
              onClick={(value) => {
                handleNewVariable(value);
              }}
            >
              adicionar variável
            </button>
          }
        />
        <TextField
          onChange={(event) => setApiSpec(event.target.value)}
          value={apiSpec}
          hidden={value !== index}
          placeholder="Ex: name"
          InputLabelProps={{
            style: { color: "white" },
          }}
          inputProps={{
            style: { color: "white" },
          }}
          variant="outlined"
          sx={{
            width: "80%",
            color: "white",
            border: "1px solid white",
            borderColor: "white",
            height: "60px",
            marginLeft: "10%",
            marginTop: "20px",
          }}
        />
      </div>
    );
  };

  const TestApiTab = (props) => {
    const { value, index } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
      >
        <Paper
          elevation={3}
          style={{
            width: "80%",
            marginLeft: "10%",
            marginTop: "20px",
            height: "400px",
            overflow: "auto",
            border: "1px solid white",
            backgroundColor: "#18181b",
            color: "white",
            padding: "10px",
            ...(isError && {
              borderColor: "red",
            }),
          }}
        >
          {testApiResult ? (
            <div>
              <p>Resultado da requisição:</p>
              <pre>{JSON.stringify(testApiResult, null, 2)}</pre>
            </div>
          ) : (
            <p>Teste a API para ver o resultado da requisição</p>
          )}
        </Paper>
      </div>
    );
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function toCamelCaseHeaders(headers) {
    const camelCaseHeaders = {};
  
    // Itera sobre as chaves do objeto de cabeçalhos
    for (const key in headers) {
      // Divide a chave por '-' e converte cada parte para que a primeira letra seja maiúscula e o restante seja minúsculo
      const camelCaseKey = key
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join('-');
  
      // Adiciona a chave convertida para camel-case ao novo objeto de cabeçalhos
      camelCaseHeaders[camelCaseKey] = headers[key];
    }
  
    return camelCaseHeaders;
  }

  const handleTestAPI = () => {
    let camelCaseHeaders = toCamelCaseHeaders(JSON.parse(requestHeaders))
    console.log(camelCaseHeaders)
    fetch(httpRequestUrl, {
      method: httpRequestType,
      headers: camelCaseHeaders,
      body: requestBody ? requestBody : null,
    })
      // .then((response) => response.json())
      .then((response) => {
        console.log("response", response);
        if (!response.ok) {
          // if error is 400 something..
          if (response.status >= 400 && response.status < 500) {
            setTestApiResult({
              error: "O erro aconteceu do lado do cliente (na requisição).",
              status: response.status,
              leiaMais:
                "https://developer.mozilla.org/pt-BR/docs/Web/HTTP/Status/400",
            });
          } else if (response.status >= 500) {
            setTestApiResult({
              error: "O erro aconteceu do lado do servidor (na API).",
              status: response.status,
              leiaMais:
                "https://developer.mozilla.org/pt-BR/docs/Web/HTTP/Status/500",
            });
          }
          setIsError(true);
        } else {
          response.json().then((data) => {
            setTestApiResult(data);
            setIsError(false);
          });
        }
      })
      .catch((error, response) => {
        console.log(error);
        setTestApiResult({
          error: "Ocorreu um erro ao tentar fazer a requisição.",
          leiaMais:
            "https://developer.mozilla.org/pt-BR/docs/Web/HTTP/Status/400",
        });
        setIsError(true);
      });
  };

  return (
    <div className="customNodeBodyInput" title={description}>
      <Button
        onClick={handleOpen}
        sx={{
          color: "#e99848",
        }}
        title={description}
      >
        Configurar request
      </Button>
      <Box sx={{ "& > :not(style)": { m: 1 } }}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableEnforceFocus
        >
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Select
                onChange={(event) => setHttpRequestType(event.target.value)}
                value={httpRequestType}
                sx={{
                  color: "white",
                  border: "1px solid white",
                }}
              >
                {httpRequestOptions.map((option, index) => (
                  <MenuItem value={option.label} key={index}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                onChange={(event) => setHttpRequestUrl(event.target.value)}
                value={httpRequestUrl}
                InputLabelProps={{
                  style: { color: "white" },
                }}
                inputProps={{
                  style: { color: "white" },
                }}
                variant="outlined"
                sx={{
                  width: "75%",
                  color: "white",
                  border: "1px solid white",
                  borderColor: "white",
                }}
              />
              <Tooltip
                title="Para adicionar uma variável na requisição, adicione o nome da variável entre chaves duplas. Ex: {{nomeDaVariavel}}"
                placement="top"
                arrow
                sx={{
                  color: "white",
                }}
              >
                <InfoIcon
                  sx={{
                    color: "white",
                  }}
                />
              </Tooltip>
              <Button
                onClick={() => {
                  handleTestAPI();
                  // change to tab 3
                  setValue(3);
                }}
                sx={{
                  color: "#e99848",
                }}
              >
                Testar API
              </Button>
            </div>
            <Tabs
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#4678e7",
                  color: "white",
                },
              }}
              TabScrollButtonProps={{
                style: {
                  color: "white",
                },
              }}
              value={value}
              onChange={handleChange}
            >
              <Tab
                label="Body"
                sx={{
                  color: "white",
                }}
                {...a11yProps(0)}
              />
              <Tab
                label="Headers"
                sx={{
                  color: "white",
                }}
                {...a11yProps(1)}
              />
              <Tab
                label="Response"
                sx={{
                  color: "white",
                }}
                {...a11yProps(2)}
              />
              <Tab
                label="Visualizar teste da API"
                sx={{
                  color: "white",
                }}
                {...a11yProps(3)}
              />
              <Tab
                label="Descrição da API"
                sx={{
                  color: "white",
                }}
                {...a11yProps(4)}
              />
            </Tabs>
            {BodyTab({ value, index: 0 })}
            {HeadersTab({ value, index: 1 })}
            {ResponseTab({ value, index: 2 })}
            {TestApiTab({ value, index: 3 })}
            {DescriptionTab({ value, index: 4 })}
          </Box>
        </Modal>
        <Tooltip title="Saída para caso a requisição der certo">
          <Handle
            type="source"
            position={Position.Right}
            id="success"
            isConnectable={isConnectable}
            style={{
              borderRadius: "0 4px 4px 0",
              background: "black",
              width: "10px",
              height: "10px",
              right: "-12px",
            }}
          />
        </Tooltip>
        <Handle
          type="target"
          position={Position.Left}
          id="a"
          isConnectable={isConnectable}
          style={{
            borderRadius: "0 4px 4px 0",
            background: "black",
            width: "10px",
            height: "10px",
            left: "-12px",
          }}
        />
        <Tooltip title="Saída para caso a requisição der errado">
          <Handle
            type="source"
            position={Position.Bottom}
            id="failure"
            isConnectable={isConnectable}
            style={{
              borderRadius: "0 4px 4px 0",
              background: "darkred",
              width: "10px",
              height: "10px",
              bottom: "-12px",
            }}
          />
        </Tooltip>
      </Box>
    </div>
  );
}

export default ApiNode;
