// Import React and ReactDOM
import React from "react";
import ReactDOM from "react-dom/client";

// Import styles
import "./index.css";

// Import the main component
import LandingPage from "./pages/landing";

// Import context providers
import { VariablesProvider } from "./contexts/variablesContext";
import { UsedVariablesProvider } from "./contexts/usedVariablesContext";
import { ThemeProvider } from "./contexts/themeContext";
import { QueuesProvider } from "./contexts/queuesContext";
import { IndexProvider } from "./contexts/indexContext";
// Create a React root
const root = ReactDOM.createRoot(document.getElementById("root"));

// Render the main application within context providers
root.render(
  <React.StrictMode>
    <IndexProvider>
      <VariablesProvider>
        <UsedVariablesProvider>
          <ThemeProvider>
            <QueuesProvider>
              <LandingPage />
            </QueuesProvider>
          </ThemeProvider>
        </UsedVariablesProvider>
      </VariablesProvider>
    </IndexProvider>
  </React.StrictMode>
);
