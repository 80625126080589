import React, { useContext, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Chip from "@mui/material/Chip";
import RttIcon from "@mui/icons-material/Rtt";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import FlagIcon from "@mui/icons-material/Flag";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import JavascriptIcon from "@mui/icons-material/Javascript";
import { ThemeContext } from "../../contexts/themeContext";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import UTurnRightIcon from "@mui/icons-material/UTurnRight";
import MapIcon from "@mui/icons-material/Map";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TodayIcon from "@mui/icons-material/Today";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { VariablesContext } from "../../contexts/variablesContext";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import KeyboardOptionKeySharpIcon from "@mui/icons-material/KeyboardOptionKeySharp";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import api from "../../utils/api";
import { movideskTemplate } from "../../utils/baseMoviDeskTemplate";
import { salesForceTemplate } from "../../utils/baseSalesForceTemplate";
import { basicTemplate } from "../../utils/baseBasicTemplate";
import templatelogo from "../../assets/templatelogo.png";

const drawerWidth = 400;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft(props) {
  const theme = useTheme();
  const { setPathType } = useContext(ThemeContext);
  const { hasMiniMap, setHasMiniMap } = useContext(ThemeContext);
  const { darkMode, setDarkMode } = useContext(ThemeContext);
  const { variables, setVariables } = useContext(VariablesContext);
  const [newVariable, setNewVariable] = useState({ name: "", value: "" });

  const addNewVariable = () => {
    // check if the variable already exists
    const variableExists = variables?.find(
      (variable) => variable.label === newVariable.name
    );
    if (variableExists) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Essa variável já existe!",
      });
      return;
    }
    // check if the variable name is empty
    if (newVariable.name === "") {
      // alert("Nome da variável não pode ser vazio");
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Nome da variável não pode ser vazio",
      });
      return;
    }
    // check if the variable value is empty
    if (newVariable.value === "") {
      // alert("Valor da variável não pode ser vazio");
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Valor da variável não pode ser vazio",
      });
      return;
    }
    // add the new variable to the variables array
    // alert("Variável adicionada com sucesso");
    Swal.fire({
      icon: "success",
      title: "Sucesso!",
      text: "Variável adicionada com sucesso",
    });
    const newVariableObject = {
      label: newVariable.name,
      type: "texto",
      data: { label: newVariable.name },
      value: newVariable.value,
    };
    setVariables([...variables, newVariableObject]);
    setNewVariable({ name: "", value: "" });
  };

  const handleDrawerOpen = () => {
    props.handleDrawerOpen();
  };
  const [open, setOpen] = useState(false);
  const [openTemplates, setOpenTemplates] = useState(false);
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  const saveFlows = async (whichTemplate) => {
    let flows;

    if (whichTemplate === "movidesk") {
      // find the api node inside flows
      flows = movideskTemplate;
      // let apiNode = flows?.flows[0].nodes.find((node) => node.type === "api");
      // let body = JSON.parse(apiNode.data.nodeInfo.requestBody);
      // body?.createdBy = { id: "{{IDCliente}}", phone: "{{IDCliente}}" };
      // body?.clients = [
      //   {
      //     id: "{{IDCliente}}",
      //     profileType: 1,
      //     personType: 1,
      //   },
      // ];
      // body.actions = [
      //   {
      //     type: 1,
      //     description: "Descrever a ação",
      //   },
      // ];
      // // Indent the JSON body with 2 spaces
      // apiNode.data.nodeInfo.requestBody = JSON.stringify(body, null, 2);
    } else if (whichTemplate === "salesforce") {
      flows = salesForceTemplate;
    } else if (whichTemplate === "basico") {
      flows = basicTemplate;
    }

    const response = await api.post("/setValue", {
      key: `${props.botId}`,
      value: JSON.stringify(flows),
    });

    if (response.status === 200) {
      // alert("Flows salvos com sucesso!");
      Swal.fire({
        icon: "success",
        title: "Flows salvos com sucesso!",
      });
      window.location.reload();
    } else {
      // alert("Erro ao salvar os flows");
      Swal.fire({
        icon: "error",
        title: "Erro ao salvar os flows",
      });
    }

    setOpenTemplates(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            background: "#18181b",
            color: "#e7e7e4",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2 id="modal-modal-title">Adicionar nova variável</h2>
          <p id="modal-modal-description">
            Adicione uma nova variável para utilizar no seu fluxo.
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Nome da variável"
              variant="outlined"
              value={newVariable.name}
              sx={{
                color: "#e7e7e4",
              }}
              inputProps={{ style: { color: "#e7e7e4" } }}
              InputLabelProps={{ style: { color: "#e7e7e4" } }}
              onChange={(e) =>
                setNewVariable({ ...newVariable, name: e.target.value })
              }
            />
            <TextField
              id="outlined-basic"
              label="Valor da variável"
              variant="outlined"
              value={newVariable.value}
              sx={{
                color: "#e7e7e4",
              }}
              inputProps={{ style: { color: "#e7e7e4" } }}
              InputLabelProps={{ style: { color: "#e7e7e4" } }}
              onChange={(e) =>
                setNewVariable({ ...newVariable, value: e.target.value })
              }
            />
            <Button
              onClick={() => {
                addNewVariable();
                setOpen(false);
              }}
              sx={{
                color: "#e99848",
              }}
            >
              Adicionar
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openTemplates}
        onClose={() => setOpenTemplates(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            height: 600,
            background: "#18181b",
            color: "#e7e7e4",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2>Escolha um template para adicionar ao seu fluxo.</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <Card
              sx={{
                maxWidth: 280,
                height: 400,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignContent: "space-between",
              }}
            >
              <CardHeader
                title="Integração MoviDesk"
                style={{
                  background: "#1f1f23",
                  color: "white",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              />
              <CardMedia
                component="img"
                image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuhO5qsDKVqMzrrDqRtXKa1zYSJzhUTyHYli8MN7FQRw&s"
                alt="Movidesk"
              />
              <CardContent
                style={{
                  background: "#1f1f23",
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Button
                  onClick={() => {
                    saveFlows("movidesk");
                  }}
                  sx={{
                    color: "#e99848",
                  }}
                >
                  Escolher
                </Button>
              </CardContent>
            </Card>
            <Card
              sx={{
                maxWidth: 280,
                height: 400,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignContent: "space-between",
              }}
            >
              <CardHeader
                title="Integração Salesforce"
                style={{
                  background: "#1f1f23",
                  color: "white",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              />
              <CardMedia
                component="img"
                image="https://t.ctcdn.com.br/XjYJpNsCr29m2aramZr_Sg-pJ6Y=/i490048.jpeg"
                alt="Movidesk"
              />
              <CardContent
                style={{
                  background: "#1f1f23",
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Button
                  onClick={() => {
                    saveFlows("salesforce");
                  }}
                  sx={{
                    color: "#e99848",
                  }}
                >
                  Escolher
                </Button>
              </CardContent>
            </Card>
            <Card
              sx={{
                maxWidth: 280,
                height: 400,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignContent: "space-between",
              }}
            >
              <CardHeader
                title="Template básico"
                style={{
                  background: "#1f1f23",
                  color: "white",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              />
              <CardMedia component="img" image={templatelogo} alt="Movidesk" />
              <CardContent
                style={{
                  background: "#1f1f23",
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Button
                  onClick={() => {
                    saveFlows("basico");
                  }}
                  sx={{
                    color: "#e99848",
                  }}
                >
                  Escolher
                </Button>
              </CardContent>
            </Card>
          </div>
        </Box>
      </Modal>
      <Drawer
        sx={{
          // width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            background: darkMode === "dark" ? "#18181b" : "#b2acab",
            color: darkMode === "dark" ? "#e7e7e4" : "black",
          },
        }}
        variant="persistent"
        anchor="left"
        open={props.open}
      >
        <DrawerHeader>
          {darkMode === "dark" ? (
            <LightModeIcon
              onClick={() => {
                setDarkMode("light");
              }}
              style={{ color: "#e7e7e4", marginRight: "auto" }}
            />
          ) : (
            <DarkModeIcon
              onClick={() => {
                setDarkMode("dark");
              }}
              style={{ color: "#18181b", marginRight: "auto" }}
            />
          )}
          <Button
            style={{
              color: darkMode === "dark" ? "#e7e7e4" : "#18181b",
              fontWeight: "bold",
            }}
            onClick={() => setOpenTemplates(true)}
          >
            Templates
          </Button>
          <Button
            style={{
              color: darkMode === "dark" ? "#e7e7e4" : "#18181b",
              fontWeight: "bold",
            }}
            onClick={() => setOpen(true)}
          >
            Adicionar variável
          </Button>

          <IconButton
            onClick={handleDrawerOpen}
            sx={{ color: darkMode === "dark" ? "#e7e7e4" : "#18181b" }}
          >
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <h3
          style={{
            color: darkMode === "dark" ? "#e7e7e4" : "black",
            textAlign: "left",
            marginLeft: "33px",
          }}
        >
          Nós de fluxo
        </h3>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            padding: "0 2rem",
            gap: "1rem",
          }}
        >
          <Chip
            sx={{
              color: "#e7e7e4",
              background: darkMode === "dark" ? "#1f1f23" : "#949391",
              ":hover": { background: "#18181b", cursor: "grab" },
              fontSize: "1.1rem",
              height: "3rem",
              width: "8em",
            }}
            onDragStart={(event) => onDragStart(event, "text")}
            draggable
            label="Texto"
            icon={<RttIcon style={{ color: "#4678e7" }} />}
          ></Chip>
          <Chip
            sx={{
              color: "#e7e7e4",
              background: darkMode === "dark" ? "#1f1f23" : "#949391",
              ":hover": { background: "#18181b", cursor: "grab" },
              fontSize: "1.1rem",
              height: "3rem",
              width: "8em",
            }}
            onDragStart={(event) => onDragStart(event, "end")}
            draggable
            label="Final"
            icon={<FlagIcon style={{ color: "#4678e7" }} />}
          ></Chip>
          <Chip
            sx={{
              color: "#e7e7e4",
              background: darkMode === "dark" ? "#1f1f23" : "#949391",
              ":hover": { background: "#18181b", cursor: "grab" },
              fontSize: "1.1rem",
              height: "3rem",
              width: "8em",
            }}
            onDragStart={(event) => onDragStart(event, "change")}
            draggable
            label="Trocar fluxo"
            icon={<FollowTheSignsIcon style={{ color: "#4678e7" }} />}
          ></Chip>
          <Chip
            sx={{
              color: "#e7e7e4",
              background: darkMode === "dark" ? "#1f1f23" : "#949391",
              ":hover": { background: "#18181b", cursor: "grab" },
              fontSize: "1.1rem",
              height: "3rem",
              width: "8em",
            }}
            onDragStart={(event) => onDragStart(event, "date")}
            draggable
            label="Data"
            icon={<DateRangeIcon style={{ color: "#4678e7" }} />}
          ></Chip>
          <Chip
            sx={{
              color: "#e7e7e4",
              background: darkMode === "dark" ? "#1f1f23" : "#949391",
              ":hover": { background: "#18181b", cursor: "grab" },
              fontSize: "1.1rem",
              height: "3rem",
              width: "8em",
            }}
            onDragStart={(event) => onDragStart(event, "day")}
            draggable
            label="Dia da semana"
            icon={<TodayIcon style={{ color: "#4678e7" }} />}
          />
          <Chip
            sx={{
              color: "#e7e7e4",
              background: darkMode === "dark" ? "#1f1f23" : "#949391",
              ":hover": { background: "#18181b", cursor: "grab" },
              fontSize: "1.1rem",
              height: "3rem",
              width: "8em",
            }}
            onDragStart={(event) => onDragStart(event, "queue")}
            draggable
            label="Fila de execução"
            icon={<TransferWithinAStationIcon style={{ color: "#4678e7" }} />}
          />
        </Box>
        <Divider
          sx={{
            marginTop: "0.5rem",
          }}
        />
        <h3
          style={{
            color: darkMode === "dark" ? "#e7e7e4" : "black",
            textAlign: "left",
            marginLeft: "33px",
          }}
        >
          Nós de input
        </h3>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            padding: "0 2rem",
            gap: "1rem",
          }}
        >
          <Chip
            sx={{
              color: "#e7e7e4",
              background: darkMode === "dark" ? "#1f1f23" : "#949391",
              ":hover": { background: "#18181b", cursor: "grab" },
              fontSize: "1.1rem",
              height: "3rem",
              width: "8em",
            }}
            onDragStart={(event) => onDragStart(event, "inputText")}
            draggable
            label="text"
            icon={<RttIcon style={{ color: "#ffa54c" }} />}
          ></Chip>
          <Chip
            sx={{
              color: "#e7e7e4",
              background: darkMode === "dark" ? "#1f1f23" : "#949391",
              ":hover": { background: "#18181b", cursor: "grab" },
              fontSize: "1.1rem",
              height: "3rem",
              width: "8em",
            }}
            onDragStart={(event) => onDragStart(event, "api")}
            draggable
            label="API"
            icon={<QueryStatsIcon style={{ color: "#ffa54c" }} />}
          />
          <Chip
            sx={{
              color: "#e7e7e4",
              background: darkMode === "dark" ? "#1f1f23" : "#949391",
              ":hover": { background: "#18181b", cursor: "grab" },
              fontSize: "1.1rem",
              height: "3rem",
              width: "8em",
            }}
            onDragStart={(event) => onDragStart(event, "jsNode")}
            draggable
            label="Nó Javascript"
            icon={<JavascriptIcon style={{ color: "#ffa54c" }} />}
          />
        </Box>
        <h3
          style={{
            color: darkMode === "dark" ? "#e7e7e4" : "black",
            textAlign: "left",
            marginLeft: "33px",
          }}
        >
          Nós condicionais
        </h3>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            padding: "0 2rem",
            gap: "1rem",
          }}
        >
          <Chip
            sx={{
              color: "#e7e7e4",
              background: darkMode === "dark" ? "#1f1f23" : "#949391",
              ":hover": { background: "#18181b", cursor: "grab" },
              fontSize: "1.1rem",
              height: "3rem",
              width: "8em",
            }}
            onDragStart={(event) => onDragStart(event, "condition")}
            draggable
            label="Condição"
            icon={<CompareArrowsIcon style={{ color: "purple" }} />}
          />
          <Chip
            sx={{
              color: "#e7e7e4",
              background: darkMode === "dark" ? "#1f1f23" : "#949391",
              ":hover": { background: "#18181b", cursor: "grab" },
              fontSize: "1.1rem",
              height: "3rem",
              width: "8em",
            }}
            onDragStart={(event) => onDragStart(event, "choice")}
            draggable
            label="Múltipla escolha"
            icon={<KeyboardOptionKeySharpIcon style={{ color: "purple" }} />}
          />
        </Box>
        <Divider
          sx={{
            marginTop: "0.5rem",
          }}
        />
        <h3
          style={{
            color: darkMode === "dark" ? "#e7e7e4" : "black",
            textAlign: "left",
            marginLeft: "33px",
          }}
        >
          Opções de tema
        </h3>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            padding: "0 2rem",
            gap: "1rem",
          }}
        >
          <Chip
            sx={{
              color: "#e7e7e4",
              background: darkMode === "dark" ? "#1f1f23" : "#949391",
              ":hover": { background: "#18181b", cursor: "grab" },
              fontSize: "1.1rem",
              height: "3rem",
              width: "8em",
            }}
            onClick={() => setPathType("straight")}
            label="straight"
            icon={<ArrowForwardIcon style={{ color: "darkred" }} />}
          ></Chip>
          <Chip
            sx={{
              color: "#e7e7e4",
              background: darkMode === "dark" ? "#1f1f23" : "#949391",
              ":hover": { background: "#18181b", cursor: "grab" },
              fontSize: "1.1rem",
              height: "3rem",
              width: "8em",
            }}
            onClick={() => setPathType("bezier")}
            label="bezier"
            icon={<UTurnRightIcon style={{ color: "darkred" }} />}
          ></Chip>
          <Chip
            sx={{
              color: "#e7e7e4",
              background: darkMode === "dark" ? "#1f1f23" : "#949391",
              ":hover": { background: "#18181b", cursor: "grab" },
              fontSize: "1.1rem",
              height: "3rem",
              width: "8em",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() => setPathType("smoothstep")}
            label="smoothstep"
            icon={<TrendingUpIcon style={{ color: "darkred" }} />}
          ></Chip>
          <Chip
            sx={{
              color: "#e7e7e4",
              background: darkMode === "dark" ? "#1f1f23" : "#949391",
              ":hover": { background: "#18181b", cursor: "grab" },
              fontSize: "1.1rem",
              height: "3rem",
              width: "8em",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() => setHasMiniMap(!hasMiniMap)}
            label="Mini Map"
            icon={<MapIcon style={{ color: "darkred" }} />}
          />
        </Box>
      </Drawer>
      <Main open={props.open}>
        <DrawerHeader />
      </Main>
    </Box>
  );
}
