import React, { useState, useCallback, useContext, useEffect } from "react";
import { Handle, Position } from "reactflow";
import InputAdornment from "@mui/material/InputAdornment";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { VariablesContext } from "../../contexts/variablesContext";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { UsedVariablesContext } from "../../contexts/usedVariablesContext";
import Chip from "@mui/material/Chip";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "#1f1f23",
  border: "2px solid #000",
  boxShadow: 24,
  color: "white",
  paddingBottom: "20px",
};

function TextUpdaterNode({ data, isConnectable }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { variables, setVariables } = useContext(VariablesContext);
  const { usedVariables, setUsedVariables } = useContext(UsedVariablesContext);
  const [inputValue, setInputValue] = useState(data.nodeInfo.inputValue ?? "");
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    // save inputvalue to localstorage whenever it changes inside the node with the same id
    const prevFlows = JSON.parse(localStorage.getItem("flows"));
    prevFlows.forEach((flow) => {
      const node = flow.nodes.find((node) => node.id === data.id);
      if (node) {
        const object = {
          inputValue: inputValue,
          flowId: data.flowId,
        };

        data.nodeInfo = object;

        localStorage.setItem("flows", JSON.stringify(prevFlows));
      }
    });
  }, [inputValue, data]);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectVariable = (event, value) => {
    // concat the variable to the text
    setInputValue(inputValue + ` {{${value.label}}}`);
    handleInputChange();
    // setusedvariables
    const newUsedVariable = {
      variableId: value.label,
      nodeId: data.id,
    };

    setUsedVariables([...usedVariables, newUsedVariable]);

    setAnchorEl(null);
  };

  const handleInputChange = (event) => {
    if (event) {
      // this is to prevent the function from being called when the component is mounted
      setInputValue(event.target.value);
    }

    // regex to check if the input has a variable inside
    const regex = /{{([^}]+)}}/g;
    const matches = [...inputValue.matchAll(regex)];

    // Get the current list of variable labels from matches
    const currentVariableLabels = matches.map((match) => match[1]);

    // Update usedVariables based on changes
    const updatedUsedVariables = usedVariables.reduce((acc, variable) => {
      if (currentVariableLabels.includes(variable.label)) {
        acc.push(variable); // Keep existing variables
      }
      return acc;
    }, []);

    currentVariableLabels.forEach((label) => {
      if (!updatedUsedVariables.find((variable) => variable.label === label)) {
        updatedUsedVariables.push({
          label: label,
          nodeId: data.id, // Adjust this as needed
        });
      }
    });

    const newUsedVariables = Array.from(
      new Set([...usedVariables, ...updatedUsedVariables])
    );
    setUsedVariables(newUsedVariables);
  };

  const TextWithChip = () => {
    const regex = /{{([^}]+)}}/g;
    let match;
    let lastIndex = 0;
    const result = [];

    while ((match = regex.exec(inputValue)) !== null) {
      // Adiciona o texto antes do match
      if (lastIndex < match.index) {
        result.push(inputValue.substring(lastIndex, match.index));
      }

      // Adiciona o componente Chip
      result.push(
        <Chip
          key={match.index}
          label={match[1]}
          sx={{
            // gradient background
            background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
            color: "white",
            maxHeight: 13,
            fontSize: "0.7rem",
            margin: "0.2em",
          }}
        />
      );

      // Atualiza o lastIndex para o final do match atual
      lastIndex = regex.lastIndex;
    }

    // Adiciona qualquer texto restante após o último match
    if (lastIndex < inputValue.length) {
      result.push(inputValue.substring(lastIndex));
    }

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "200px",
          overflowpx: "hidden", // Prevents content from overflowing the box
          whiteSpace: "break-spaces", // Prevents wrapping of text
          textOverflow: "ellipsis", // Shows an ellipsis (...) when text overflows
        }}
        onClick={handleFocus}
      >
        <Tooltip
          title="Clique para editar o texto que será enviado para o usuário"
          placement="top"
          arrow
          style={{ zIndex: 9999 }}
        >
          <ChatBubbleIcon
            style={{
              color: "#4b83ff",
              marginRight: "5px",
              marginLeft: "5px",
              zIndex: 9999,
              position: "relative",
            }}
          />
        </Tooltip>
        <div
          style={{
            maxHeight: "90px",
          }}
          title={inputValue} // Fix: Removed unnecessary template literal
        >
          {result}
        </div>
      </div>
    );
  };

  return (
    <div className="customNodeBody">
      <Box
        sx={{
          "& > :not(style)": { m: 1 },
          justifyContent: "flex-start",
        }}
      >
        <Menu
          id="menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          MenuListProps={{ autoFocus: false }}
        >
          <MenuItem
            onKeyDown={(e) => e.stopPropagation()}
            sx={{ flexDirection: "column", alignItems: "flex-start" }}
          >
            Mostrar uma ou mais variáveis
            <Autocomplete
              id="combo-box-demo"
              options={variables}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="variáveis" />
              )}
              onChange={(event, value) => {
                handleSelectVariable(event, value);
              }}
              noOptionsText="Nenhuma variável encontrada"
              getOptionKey={(option) => option.label}
            />
          </MenuItem>
        </Menu>
        <Modal
          open={focused}
          onClose={handleBlur}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "fitContent",
          }}
        >
          <Box sx={style}>
            <Button
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                color: "white",
                background: "transparent",
              }}
              onClick={handleBlur}
            >
              <CloseIcon />
            </Button>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  marginBottom: "15px",
                }}
              >
                <h2 id="modal-modal-title">
                  Digite o texto que será enviado para o usuário
                </h2>
              </div>
              {/* <hr
                style={{
                  marginTop: "-15px",
                  marginBottom: "10px",
                  borderColor: "#800080",
                  marginBottom: "25px",
                  width: "100%",
                }}
              /> */}

              <FormControl variant="standard">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "400px",
                    width: "670px",
                  }}
                >
                  <Tooltip title="Adicionar uma ou mais variáveis para serem enviadas no texto">
                    <Button
                      onClick={handleClick}
                      sx={{
                        position: "absolute",
                        top: "-22px",
                        right: "0px",
                        color: "white",
                        maxHeight: 5,
                        fontSize: "0.8rem",
                        background: "#800080",
                        opacity: "0.7",
                        height: "fit-content",
                        padding: "10px",
                      }}
                    >
                      Adicionar Variável
                    </Button>
                  </Tooltip>
                  {/* <InputAdornment position="start">
                    <ChatBubbleIcon
                      style={{ color: "#4b83ff", marginRight: "5px" }}
                    />
                  </InputAdornment> */}
                  <textarea
                    id="input-with-icon-adornment"
                    value={inputValue}
                    onChange={handleInputChange}
                    cols={30}
                    style={{
                      background: "#1f1f23",
                      color: "white",
                      resize: "none",
                      border: "1px solid #5b5bf7",
                      outline: "none",
                      width: "100%",
                      padding: "10px",
                      height: "100%",
                    }}
                  />
                </div>
              </FormControl>
            </div>
          </Box>
        </Modal>
        <TextWithChip />
      </Box>
      <Handle
        type="target"
        position={Position.Left}
        id="a"
        isConnectable={isConnectable}
        style={{
          borderRadius: "0 4px 4px 0",
          background: "black",
          width: "10px",
          height: "10px",
        }}
      />
      <Handle
        type="source"
        position={Position.Right}
        id="b"
        isConnectable={isConnectable}
        style={{
          borderRadius: "0 4px 4px 0",
          background: "black",
          width: "10px",
          height: "10px",
        }}
      />
    </div>
  );
}

export default TextUpdaterNode;
