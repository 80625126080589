import React, { useState, useEffect, useContext, useCallback } from "react";
import Box from "@mui/material/Box";
import HeaderAppBar from "../../components/Appbar";
import { VariablesContext } from "../../contexts/variablesContext";
import FlowTest from "../../components/flowTest";
import { v4 as uuidv4 } from "uuid";
import { UsedVariablesContext } from "../../contexts/usedVariablesContext";
import api from "../../utils/api";
import "./styles.css";
import { QueuesContext } from "../../contexts/queuesContext";
import Swal from "sweetalert2";
import { IndexContext } from "../../contexts/indexContext";
const LandingPage = () => {
  const attributionDiv = document.querySelector(".react-flow__attribution");
  if (attributionDiv) {
    attributionDiv.style.display = "none";
  }

  const id = uuidv4();

  const decryptKey = process.env.REACT_APP_CRYPTO_PASSWORD;

  var CryptoJS = require("crypto-js");

  const url = window.location.href;
  const urlArray = url.split("/");
  const indexOfFirstSlash = urlArray.indexOf("");
  const everythingAfterFirstSlash = urlArray
    .slice(indexOfFirstSlash + 2)
    .join("/");

  const ciphertext = everythingAfterFirstSlash;
  let bytes = CryptoJS.AES.decrypt(ciphertext, decryptKey);
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  const botId = decryptedData.botId;

  const [loadedFlowFlag, setLoadedFlowFlag] = useState(false);
  // State to manage the current flow index
  // const [index, setIndex] = useState(0);
  const { headerIndex, setHeaderIndex } = useContext(IndexContext);
  // Contexts for managing variables and used variables
  const { variables, setVariables, setGlobalUser, globalUser } =
    useContext(VariablesContext);
  const { usedVariables, setUsedVariables } = useContext(UsedVariablesContext);
  const { setQueues } = useContext(QueuesContext);

  useEffect(() => {
    setQueues(decryptedData.serviceQueues);
    setGlobalUser(decryptedData.userId);
  }, []);

  const [flows, setFlows] = useState([]);
  useEffect(() => {
    const getFlows = async () => {
      try {
        await api
          .get(`/getValue/${botId}`)
          .then((res) => {
            if (res.data.parsed !== null) {
              setUsedVariables(res.data.parsed.usedVariables);
              setFlows(res.data.parsed.flows);
              const storedVariables = res.data.parsed.variables;

              const globalUserAsObject = {
                label: "IDUsuarioLogado",
                type: "texto",
                data: { label: `${decryptedData.userId}` },
              };

              const firstMessageObject = {
                label: "PrimeiraMensagem",
                type: "texto",
                data: { label: "PrimeiraMensagem" },
              };

              const globalUserExists = storedVariables.find(
                (variable) => variable.label === "IDUsuarioLogado"
              );

              const firstMessageExists = storedVariables.find(
                (variable) => variable.label === "PrimeiraMensagem"
              );

              if (!globalUserExists && !firstMessageExists) {
                setVariables([
                  ...storedVariables,
                  globalUserAsObject,
                  firstMessageObject,
                ]);
              } else {
                setVariables(res.data.parsed.variables);
              }
            } else {
              const globalUserAsObject = {
                label: "IDUsuarioLogado",
                type: "texto",
                data: { label: `${decryptedData.userId}` },
              };
              const firstMessageObject = {
                label: "PrimeiraMensagem",
                type: "texto",
                data: { label: "PrimeiraMensagem" },
              };

              const savedFlows = localStorage.getItem("flows");
              if (savedFlows.length > 2) {
                setFlows(JSON.parse(savedFlows));
                const savedVariables = localStorage.getItem("variables");
                setVariables(JSON.parse(savedVariables));
                const savedUsedVariables =
                  localStorage.getItem("usedVariables");
                setUsedVariables(JSON.parse(savedUsedVariables));
              } else {
                setFlows([
                  {
                    id: uuidv4(),
                    name: `Flow 1`,
                    label: `Flow 1`,
                    nodes: [
                      {
                        id: id,
                        type: "start",
                        position: { x: 0, y: 0 },
                        data: {
                          label: `start`,
                          id: id,
                        },
                      },
                    ],
                    edges: [],
                  },
                ]);

                setVariables([globalUserAsObject, firstMessageObject]);

                setUsedVariables([]);
              }
            }
          })
          .finally(() => {
            setLoadedFlowFlag(true);
          });
      } catch (error) {
        console.error("Error fetching flows:", error);
        // Handle error or set default value if needed
      }
    };

    getFlows();
  }, [botId]);

  // State to manage data to save, combining flows, variables, and used variables
  const [dataToSave, setDataToSave] = useState({
    flows: flows,
    variables: variables,
    usedVariables: usedVariables,
  });

  // Effect to save data to the server and local storage whenever flows, variables, or used variables change
  useEffect(() => {
    const newData = {
      flows: flows,
      variables: variables,
      usedVariables: usedVariables,
    };

    setDataToSave(newData);
    // saveFlows();

    localStorage.setItem("flows", JSON.stringify(flows));
    localStorage.setItem("variables", JSON.stringify(variables));
    localStorage.setItem("usedVariables", JSON.stringify(usedVariables));
  }, [flows, variables, usedVariables]);

  // Add new flow to the list of flows
  const addNewFlow = () => {
    const newId = uuidv4();

    setFlows([
      ...flows,
      {
        id: uuidv4(),
        name: `Flow ${flows.length + 1}`,
        label: `Flow ${flows.length + 1}`,
        nodes: [
          {
            id: newId,
            type: "start",
            position: { x: 0, y: 0 },
            data: {
              label: `start`,
              id: newId,
            },
          },
        ],
        edges: [],
      },
    ]);
  };

  // Calculate container height based on window height
  const [containerHeight, setContainerHeight] = useState(
    window.innerHeight - 90
  );

  // Adjust container height on window resize
  useEffect(() => {
    const handleResize = () => {
      setContainerHeight(window.innerHeight - 90);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerHeight]);

  const findNodesBeingTarget = () => {
    const nodesBeingTarget = [];
    flows[headerIndex].nodes.forEach((node) => {
      const nodeBeingTarget = flows[headerIndex].edges.find(
        (edge) => edge.target === node.id
      );
      if (nodeBeingTarget) {
        nodesBeingTarget.push(node);
      }
    });
    return nodesBeingTarget;
  };

  const findNodesBeingTargetButNotSource = (nodesBeingTarget) => {
    const nodesBeingTargetButNotSource = [];
    nodesBeingTarget.forEach((node) => {
      if (node.type === "api" || node.type === "date" || node.type === "day") {
        // check if theres more than one connection where the node is the source
        const nodeBeingSource = flows[headerIndex].edges.filter(
          (edge) => edge.source === node.id
        );
        if (nodeBeingSource.length < 2) {
          nodesBeingTargetButNotSource.push(node);
        }
      } else if (node.type === "condition") {
        // check how many conditions are there
        const conditions = node.data.nodeInfo.conditions;
        // count the number of conditions inside the object conditions
        if (conditions) {
          const numberOfConditions = Object.keys(conditions).length;
          const nodeBeingSource = flows[headerIndex].edges.filter(
            (edge) => edge.source === node.id
          );

          if (nodeBeingSource.length < numberOfConditions + 1) {
            nodesBeingTargetButNotSource.push(node);
          }
        } else {
          nodesBeingTargetButNotSource.push(node);
        }
      } else {
        const nodeBeingSource = flows[headerIndex].edges.find(
          (edge) => edge.source === node.id
        );

        if (
          !nodeBeingSource &&
          node.type !== "end" &&
          node.type !== "queue" &&
          node.type !== "change"
        ) {
          nodesBeingTargetButNotSource.push(node);
        }
      }
    });
    return nodesBeingTargetButNotSource;
  };

  const blinkNodes = (nodesToBlink) => {
    let elementos = document.querySelectorAll("[data-id]");
    nodesToBlink.forEach((node) => {
      let elemento = document.querySelector(`[data-id="${node.id}"]`);
      elemento.style.animation = "blinker 1s linear infinite";

      setTimeout(() => {
        elemento.style.animation = "none";
      }, 5000);
    });
  };

  const saveFlows = async () => {
    const nodesBeingTarget = findNodesBeingTarget();

    const nodesBeingTargetButNotSource =
      findNodesBeingTargetButNotSource(nodesBeingTarget);

    if (nodesBeingTargetButNotSource.length > 0) {
      blinkNodes(nodesBeingTargetButNotSource);

      // alert(
      //   "Existem nós que são alvos de conexões, mas não possuem nenhuma conexão de saída ou nós de condição sem nenhuma condição."
      // );
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Existem nós que são alvos de conexões, mas não possuem nenhuma conexão de saída ou nós de condição sem nenhuma condição.",
      });
      return;
    }

    const response = await api.post("/setValue", {
      key: `${botId}`,
      value: JSON.stringify(dataToSave),
      expirationTime: true,
    });
    if (response.status === 200) {
      // alert("Flows salvos com sucesso!");
      Swal.fire({
        icon: "success",
        title: "Flows salvos com sucesso!",
      });
    } else {
      // alert("Erro ao salvar os flows");
      Swal.fire({
        icon: "error",
        title: "Erro ao salvar os flows",
      });
    }
  };

  return (
    <div>
      {loadedFlowFlag ? (
        <Box>
          <HeaderAppBar
            addNewFlow={addNewFlow}
            flows={flows}
            setIndex={setHeaderIndex}
            index={headerIndex}
            botId={botId}
            userId={decryptedData.userId}
            setFlows={setFlows}
          />
          {/* Main container for displaying the flow chart */}
          <div
            style={{
              background: "#1f1f23",
              height: `${containerHeight}px`,
              width: "100vw",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            {/* FlowTest component for rendering the flow chart */}
            <FlowTest
              key={headerIndex}
              flow={flows[headerIndex]}
              setFlows={setFlows}
              saveFlows={saveFlows}
              flows={flows}
              setIndex={setHeaderIndex}
              botId={botId}
            />
          </div>
        </Box>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default LandingPage;
