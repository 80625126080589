import { Handle, Position } from "reactflow";
import Box from "@mui/material/Box";
import FlagIcon from "@mui/icons-material/Flag";

function StartNode({ data, isConnectable }) {
  return (
    <div className="startNodeBody">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <FlagIcon />
        <h1 style={{ color: "white", marginLeft: "10px" }}>Início</h1>
      </Box>
      <Handle
        type="source"
        position={Position.Right}
        id="b"
        isConnectable={isConnectable}
        style={{
          borderRadius: "0 4px 4px 0",
          background: "black",
          width: "10px",
          height: "10px",
        }}
      />
    </div>
  );
}

export default StartNode;
