import React, { createContext, useState } from "react";

export const VariablesContext = createContext();

export const VariablesProvider = ({ children }) => {
  const [variables, setVariables] = useState(() => []);
  const [globalUser, setGlobalUser] = useState(() => {});

  return (
    <VariablesContext.Provider
      value={{ variables, setVariables, globalUser, setGlobalUser }}
    >
      {children}
    </VariablesContext.Provider>
  );
};
