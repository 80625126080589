import { useCallback, useEffect, useState, useContext } from "react";
import { Handle, Position } from "reactflow";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

function DateNode({ data, isConnectable }) {
  const [pickedDate, setPickedDate] = useState(
    data.nodeInfo.pickedDate ? data.nodeInfo.pickedDate : null
  );

  const handleDatePick = (event) => {
    // check if the date is prior to today
    const today = new Date();
    const pickedDate = new Date(event.target.value);
    // if (pickedDate < today - 1) {
    //   alert("Please pick a date that is today or later.");
    //   return;
    // }

    setPickedDate(event.target.value);
  };

  useEffect(() => {
    // save switchstate to localstorage whenever it changes inside the node with the same id
    const prevFlows = JSON.parse(localStorage.getItem("flows"));
    prevFlows.forEach((flow) => {
      const node = flow.nodes.find((node) => node.id === data.id);
      if (node) {
        const object = {
          pickedDate: pickedDate,
        };
        data.nodeInfo = object;
        // setFlows(prevFlows);
        localStorage.setItem("flows", JSON.stringify(prevFlows));
      }
    });
  }, [pickedDate, data]);

  return (
    <div className="startNodeBody">
      <Box>
        {/* date picker */}
        <input
          type="date"
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            outline: "none",
            textAlign: "center",
            fontSize: "1.2rem",
            fontWeight: "bold",
            color: "white",
            background: "transparent",
          }}
          onChange={(e) => {
            handleDatePick(e);
          }}
          value={pickedDate}
        />
        <Tooltip title="Saída para caso a data seja a selecionada">
          <Handle
            type="source"
            position={Position.Right}
            id="within"
            isConnectable={isConnectable}
            style={{
              borderRadius: "0 4px 4px 0",
              background: "black",
              width: "10px",
              height: "10px",
            }}
          />
        </Tooltip>
        <Handle
          type="target"
          position={Position.Left}
          id="a"
          isConnectable={isConnectable}
          style={{
            borderRadius: "0 4px 4px 0",
            background: "black",
            width: "10px",
            height: "10px",
          }}
        />
        <Tooltip title="Saída para caso a data não seja a selecionada">
          <Handle
            type="source"
            position={Position.Bottom}
            id="outside"
            isConnectable={isConnectable}
            style={{
              borderRadius: "0 4px 4px 0",
              background: "darkred",
              width: "10px",
              height: "10px",
            }}
          />
        </Tooltip>
      </Box>
    </div>
  );
}

export default DateNode;
