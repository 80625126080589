import React, { useContext, useState, useRef, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeContext } from "../../contexts/themeContext";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import Divider from "@mui/material/Divider";
import ReplayIcon from "@mui/icons-material/Replay";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import { v4 as uuidv4 } from "uuid";
import api from "../../utils/api";
import "./styles.css";
import Tooltip from "@mui/material/Tooltip";

const drawerWidth = 400;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginRight: `${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

// http://192.168.200.80:3000/api/v1/bots/main-menu---tchat/converse/2

export default function Emulador(props) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { darkMode, setDarkMode } = useContext(ThemeContext);
  const handleDrawerOpen = () => {
    props.handleDrawerOpen();
  };
  const [isSending, setIsSending] = useState(false);
  const [messages, setMessages] = useState([]);

  const receiveResponse = async (text) => {
    const response = await api.post(
      `api/v1/bots/${props.botId}/converse/${props.userId}`,
      {
        text: text,
      }
    );

    const nodeId = response.data.responses[0].nodeId;
    // const nodeId = 2;

    let elemento = document.querySelector(`[data-id="${nodeId}"]`);
    elemento.style.animation = "blinker 1s linear infinite";
    setTimeout(() => {
      elemento.style.animation = "none";
    }, 5000);

    const msgArray = response.data.responses;
    setTimeout(() => {
      msgArray.forEach((msg) => {
        const newMessage = { id: uuidv4(), text: msg.text, sender: "bot" };
        if (msg.text !== "<fim>") {
          setMessages((prev) => {
            // take out every message from messages that the value is "Digitando..."
            const newArr = prev.filter(
              (message) => message.text !== "Digitando..."
            );
            // const newArr = [...prev];
            return [...newArr, newMessage];
          });
          setIsSending(false);
        }
      });
    }, 1000);
  };

  const handleResetMessages = async () => {
    setMessages([]);
    await api.post(`api/v1/bots/${props.botId}/converse/${props.userId}`, {
      resetExecution: true,
    });
  };

  const sendMessage = (text) => {
    const newMessage = { id: uuidv4(), text, sender: "user" };
    const newBotMessage = { id: uuidv4(), text: "Digitando...", sender: "bot" };
    setMessages([...messages, newMessage, newBotMessage]);
    setIsSending(true);

    receiveResponse(text);
  };
  const ChatComponent = () => {
    const chatContainerRef = useRef(null);

    useEffect(() => {
      // Scroll to the bottom when messages change
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }, [messages]);

    return (
      <div
        ref={chatContainerRef}
        style={{
          width: "380px",
          borderRadius: "8px",
          padding: "16px",
          position: "absolute",
          marginTop: "64px",
          // paddingBottom: "90px",
          maxHeight: "calc(100vh - 150px)",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            height: "fit-content - 20px",
            minHeight: "200px",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {messages.map((message) => (
            <div
              key={message.id}
              style={{
                marginBottom: "8px",
                padding: "8px",
                borderRadius: "8px",
                width: "300px",
                backgroundColor:
                  message.sender === "user" ? "#5b5bf7" : "#800080",
                alignSelf:
                  message.sender === "user" ? "flex-end" : "flex-start",
              }}
            >
              {message.text}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Drawer
        sx={{
          // width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            background: darkMode === "dark" ? "#18181b" : "#b2acab",
            color: darkMode === "dark" ? "#e7e7e4" : "black",
          },
        }}
        variant="persistent"
        anchor="right"
        open={props.open}
      >
        <DrawerHeader
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            justifyContent: " space-between",
          }}
        >
          <Tooltip title="Retornar ao início do fluxo." placement="top" arrow>
            <IconButton
              sx={{ color: darkMode === "dark" ? "#e7e7e4" : "#18181b" }}
              onClick={handleResetMessages}
            >
              <ReplayIcon />
            </IconButton>
          </Tooltip>
          <IconButton
            onClick={handleDrawerOpen}
            sx={{ color: darkMode === "dark" ? "#e7e7e4" : "#18181b" }}
          >
            {theme.direction === "ltr" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <ChatComponent />
        <div
          style={{
            marginTop: "50px",
            display: "flex",
            position: "fixed", // Set position to fixed
            bottom: 0, // Place at the bottom
            width: "400px",
            right: 0, // Align with the right side
            padding: "16px", // Add padding
            background: darkMode === "dark" ? "#18181b" : "#b2acab",
          }}
        >
          <Input
            type="text"
            placeholder="Digite uma mensagem..."
            style={{
              flex: 1,
              padding: "8px",
              color: darkMode === "dark" ? "#e7e7e4" : "black",
              borderColor: darkMode === "dark" ? "#e7e7e4" : "black",
              border: "1px solid",
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                sendMessage(e.target.value);
                e.target.value = "";
              }
            }}
          />
          <Button
            style={{
              marginLeft: "8px",
              padding: "8px",
              borderRadius: "4px",
              backgroundColor: "#4caf50",
              color: "white",
              cursor: "pointer",
            }}
            onClick={() => {
              const input = document.querySelector("input");
              sendMessage(input.value);
              input.value = "";
            }}
          >
            Enviar
          </Button>
        </div>
      </Drawer>
      <Main open={props.open}>
        <DrawerHeader />
      </Main>
    </Box>
  );
}
