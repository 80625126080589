import React, { createContext, useState } from "react";

export const QueuesContext = createContext();

export const QueuesProvider = ({ children }) => {
  const [queues, setQueues] = useState(() => {});

  return (
    <QueuesContext.Provider value={{ queues, setQueues }}>
      {children}
    </QueuesContext.Provider>
  );
};
