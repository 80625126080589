import React, { useCallback, useContext } from "react";
import { useReactFlow } from "reactflow";
import { UsedVariablesContext } from "./usedVariablesContext";
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";

export default function ContextMenu({
  id,
  top,
  left,
  right,
  bottom,
  nodes,
  pushToHistory,
  edges,
  ...props
}) {
  const { getNode, setNodes, setEdges } = useReactFlow();
  const { usedVariables, setUsedVariables } = useContext(UsedVariablesContext);
  const node = getNode(id);

  const duplicateNode = () => {
    if (node?.type === "start") {
      // alert("Não é possível duplicar o nó inicial");
      Swal.fire({
        icon: "error",
        title: "Não é possível duplicar o nó inicial",
      });
      return;
    }
    const newId = uuidv4();
    const position = { x: node.position.x + 50, y: node.position.y + 50 };
    // console.log("oldnote", node);
    const newNode = {
      ...node,
      data: {
        ...node.data,
        id: newId,
      },
      id: newId,
      position,
      type: node.type,
    };
    // console.log("newnode", newNode);

    setNodes((nodes) => nodes.concat(newNode));
  };

  const deleteNode = useCallback(() => {
    // check if node is start node
    if (node?.type === "start") {
      // alert("Não é possível apagar o nó inicial");
      Swal.fire({
        icon: "error",
        title: "Não é possível apagar o nó inicial",
      });
      return;
    }
    // get the edge thats connected on this nodes source
    const edge = edges.find((edge) => edge.target === id);
    // console.log(edge);

    setNodes((nodes) => nodes.filter((node) => node.id !== id));
    setEdges((edges) => edges.filter((edge) => edge.source !== id));
    setEdges((edges) => edges.filter((edge) => edge.target !== id));

    // remove usedVariables linked with this node
    setUsedVariables(
      usedVariables.filter((usedVariable) => usedVariable.nodeId !== id)
    );

    // push to history

    if (edge) {
      pushToHistory([
        { action: "deleteEdge", data: edge },
        { action: "deleteNode", data: node },
      ]);
    } else {
      pushToHistory("deleteNode", node);
    }
  }, [setNodes, setEdges, setUsedVariables, usedVariables, id]);

  const nodeType = node?.type;

  const duplicateNodes = () => {
    setNodes((prevNodes) =>
      prevNodes.concat(
        nodes
          .filter((node) => node.type !== "start")
          .map((node) => ({
            ...node,
            data: {
              ...node.data,
              id: uuidv4(),
            },
            id: uuidv4(),
            position: { x: node.position.x + 50, y: node.position.y + 50 },
          }))
      )
    );
  };

  const deleteNodes = () => {
    setNodes((prevNodes) =>
      prevNodes.filter((n) => !nodes.find((node) => node.id === n.id))
    );
    setEdges((prevEdges) =>
      prevEdges.filter(
        (edge) =>
          !nodes.find(
            (node) => edge.source === node.id || edge.target === node.id
          )
      )
    );
    setUsedVariables((prevUsedVariables) =>
      prevUsedVariables.filter(
        (usedVariable) => !nodes.find((node) => usedVariable.nodeId === node.id)
      )
    );

    pushToHistory([
      { action: "deleteEdges", data: edges },
      { action: "deleteNodes", data: nodes },
    ]);
  };

  return (
    <div
      style={{
        position: "fixed",
        top: top,
        left: left,
        right: right,
        bottom: bottom,
      }}
      className="context-menu"
      {...props}
    >
      {id === "selection" ? (
        <div>
          Modo de seleção múltipla
          <button onClick={duplicateNodes}>Duplicar nós</button>
          <button onClick={deleteNodes}>Apagar nós</button>
        </div>
      ) : (
        <div>
          Tipo do nó: {nodeType}
          <button onClick={duplicateNode}>Duplicar nó</button>
          <button onClick={deleteNode}>Apagar nó</button>
        </div>
      )}
    </div>
  );
}
