import { useCallback, useState, useContext, useEffect } from "react";
import { Handle, Position } from "reactflow";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { VariablesContext } from "../../contexts/variablesContext";
import { UsedVariablesContext } from "../../contexts/usedVariablesContext";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { v4 as uuidv4 } from "uuid";

const operators = [
  { label: "==", value: "==" },
  { label: "!=", value: "!=" },
  { label: ">", value: ">" },
  { label: "<", value: "<" },
  { label: ">=", value: ">=" },
  { label: "<=", value: "<=" },
];

const andor = [
  { label: "E", value: "&&" },
  { label: "OU", value: "||" },
];

const initialConditions = { 0: [{ variable: "", operator: "", value: "" }] };

function ConditionalNode({ data, isConnectable }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { variables } = useContext(VariablesContext);
  const { usedVariables, setUsedVariables } = useContext(UsedVariablesContext);
  const [selectedVariable, setSelectedVariable] = useState(null);
  const open = Boolean(anchorEl);
  const [conditions, setConditions] = useState(
    data.nodeInfo.conditions ?? initialConditions
  );
  const [currentMenuIndex, setCurrentMenuIndex] = useState(null);

  useEffect(() => {
    // save conditions to localstorage whenever it changes inside the node with the same id
    const prevFlows = JSON.parse(localStorage.getItem("flows"));
    prevFlows.forEach((flow) => {
      const node = flow.nodes.find((node) => node.id === data.id);
      if (node) {
        const object = {
          conditions: conditions,
        };

        data.nodeInfo = object;

        localStorage.setItem("flows", JSON.stringify(prevFlows));
      }
    });
  }, [conditions, data]);

  const handleOpenMenu = (e, index) => {
    setCurrentMenuIndex(index);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectVariable = (event, value, conditionIndex, index) => {
    const variableExistsInSameCondition = usedVariables?.find(
      (variable) =>
        variable.nodeId === data.id &&
        variable.conditionIndex === conditionIndex &&
        variable.index === index
    );

    if (variableExistsInSameCondition) {
      // update variable in the same conditionIndex and index
      const updatedUsedVariables = usedVariables.map((variable) => {
        if (
          variable.nodeId === data.id &&
          variable.conditionIndex === conditionIndex &&
          variable.index === index
        ) {
          return { ...variable, variableId: value?.label };
        }
        return variable;
      });
      setUsedVariables(updatedUsedVariables);
    } else {
      // If the variable does not exist, create a new one
      const newUsedVariable = {
        nodeId: data.id,
        variableId: value.label,
        conditionIndex: conditionIndex,
        index: index,
      };
      setUsedVariables([...usedVariables, newUsedVariable]);
    }
  };

  const addCondition = () => {
    const newIndex = Object.keys(conditions).length;
    setConditions((prevConditions) => ({
      ...prevConditions,
      [newIndex]: [{ variable: "", operator: "", value: "" }],
    }));
  };

  return (
    <div className="customNodeBodyConditional">
      <Box sx={{ "& > :not(style)": { m: 1 } }}>
        <Menu
          id="menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{ autoFocus: false }}
        >
          {Object.entries(conditions).map(([index, conditionsArray]) => (
            <div key={index}>
              {index !== currentMenuIndex ? null : (
                <div key={index}>
                  {conditionsArray.map((condition, conditionIndex) => (
                    <Paper
                      key={conditionIndex}
                      sx={{
                        width: "fitContent",
                        height: "fitContent",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        padding: 2,
                        margin: 2,
                        backgroundColor: "#1f1f23",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      elevation={5}
                    >
                      {conditionIndex === 0 ? null : (
                        <Button
                          sx={{
                            top: 0,
                            left: 0,
                            color: "white",
                            backgroundColor: "purple",
                          }}
                          onClick={() => {
                            const prevArray = conditions[index];
                            const newArray = [...prevArray];
                            newArray.splice(conditionIndex, 1);
                            // delete the andor property from the last condition
                            if (conditionIndex === newArray.length) {
                              newArray[conditionIndex - 1].andor = null;
                            }

                            setConditions((prevConditions) => ({
                              ...prevConditions,
                              [index]: newArray,
                            }));
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      )}
                      <MenuItem
                        key={uuidv4()}
                        onKeyDown={(e) => e.stopPropagation()}
                        sx={{
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        Selecione uma variável
                        <Autocomplete
                          key={conditionIndex}
                          id="combo-box-demo"
                          options={variables}
                          sx={{
                            width: 300,
                            border: "1px solid purple",
                            color: "white",
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="variáveis"
                              // change the label color to white
                              InputLabelProps={{
                                style: {
                                  color: "white",
                                },
                              }}
                              // change the color of the input to white
                              InputProps={{
                                ...params.InputProps, // change the color of the input to white}
                                style: {
                                  color: "white",
                                },
                              }}
                            />
                          )}
                          // set the value of the selected variable to the current index of the map
                          noOptionsText="Nenhuma variável encontrada"
                          // getOptionKey={(option) => option.label}
                          value={condition.variable ? condition.variable : null}
                          size="small"
                          onChange={(event, value) => {
                            const prevArray = conditions[index];
                            const newArray = [...prevArray];
                            newArray[conditionIndex] = {
                              ...newArray[conditionIndex],
                              variable: value,
                            };
                            setConditions((prevConditions) => ({
                              ...prevConditions,
                              [index]: newArray,
                            }));
                            handleSelectVariable(
                              event,
                              value,
                              conditionIndex,
                              index
                            );
                          }}
                        />
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onKeyDown={(e) => e.stopPropagation()}
                        sx={{
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        Selecione uma condição
                        <Select
                          sx={{
                            width: 300,
                            height: 40,
                            border: "1px solid purple",
                            color: "white",
                          }}
                          value={condition.operator ? condition.operator : ""}
                          // change the selected item color to white
                          onChange={(event, value) => {
                            const prevArray = conditions[index];
                            const newArray = [...prevArray];
                            // console.log(value.props.value);
                            newArray[conditionIndex] = {
                              ...newArray[conditionIndex],
                              operator: value.props.value,
                            };
                            setConditions((prevConditions) => ({
                              ...prevConditions,
                              [index]: newArray,
                            }));
                          }}
                        >
                          {operators.map((operator) => (
                            <MenuItem value={operator.value}>
                              {operator.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onKeyDown={(e) => e.stopPropagation()}
                        sx={{
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        Selecione um valor para a condição
                        <TextField
                          sx={{ width: 300, border: "1px solid purple" }}
                          inputProps={{
                            style: {
                              height: 10,
                              color: "white",
                            },
                          }}
                          onChange={(event) => {
                            const prevArray = conditions[index];
                            const newArray = [...prevArray];
                            newArray[conditionIndex] = {
                              ...newArray[conditionIndex],
                              value: event.target.value,
                            };
                            setConditions((prevConditions) => ({
                              ...prevConditions,
                              [index]: newArray,
                            }));
                          }}
                          value={condition.value ? condition.value : ""}
                        />
                      </MenuItem>
                      <Divider />
                      {conditionIndex !== conditionsArray.length - 1 ? (
                        <Select
                          sx={{
                            width: 100,
                            marginLeft: 13,
                            color: "white",
                            border: "1px solid purple",
                          }}
                          placeholder="Selecione um valor"
                          value={condition.andor}
                          onChange={(event, value) => {
                            const prevArray = conditions[index];
                            const newArray = [...prevArray];
                            newArray[conditionIndex] = {
                              ...newArray[conditionIndex],
                              andor: event.target.value,
                            };
                            setConditions((prevConditions) => ({
                              ...prevConditions,
                              [index]: newArray,
                            }));
                          }}
                        >
                          {andor.map((andor) => (
                            <MenuItem value={andor.value}>
                              {andor.label}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : null}
                    </Paper>
                  ))}
                </div>
              )}
            </div>
          ))}

          {Object.entries(conditions).map(([index, condition]) => (
            <div key={index}>
              {Object.entries(conditions).length !==
              parseInt(index) + 1 ? null : (
                <MenuItem
                  onKeyDown={(e) => e.stopPropagation()}
                  sx={{ flexDirection: "column", alignItems: "flex-start" }}
                >
                  <Button
                    onClick={() => {
                      const newCondition = {
                        variable: "",
                        operator: "",
                        value: "",
                      };

                      const prevArray = conditions[index];

                      const newArray = [...prevArray, newCondition];

                      setConditions((prevConditions) => ({
                        ...prevConditions,
                        [index]: newArray,
                      }));
                    }}
                    sx={{
                      width: 300,
                      height: 40,
                      border: "1px solid purple",
                      color: "#1f1f23",
                    }}
                  >
                    Adicionar condição
                  </Button>
                </MenuItem>
              )}
            </div>
          ))}
        </Menu>
        {Object.entries(conditions).map(([index, condition]) => (
          <div
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "8px", // Adjust as needed
            }}
          >
            <Button
              onClick={() => {
                addCondition();
              }}
              sx={{
                position: "fixed",
                top: `${
                  Object.entries(conditions).length === 1 ? 60 : 99 + index * 95
                }px`,
                right: "95px",
                background: "transparent",
                color: "transparent",
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "white",
                },
              }}
            >
              <AddIcon />
            </Button>
            {Object.entries(conditions).length === 1 ? null : (
              <Button
                onClick={() => {
                  const newConditions = Object.entries(conditions).filter(
                    ([key, value]) => key !== index
                  );

                  setConditions(Object.fromEntries(newConditions));
                }}
                sx={{
                  top: 20,
                  left: 170,
                  backgroundColor: "transparent",
                  color: "transparent",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "purple",
                  },
                }}
              >
                <DeleteIcon
                  sx={{
                    height: "20px",
                    width: "20px",
                  }}
                />
              </Button>
            )}
            <TextField
              label="If"
              value={`${
                condition[0].variable
                  ? condition
                      .map(
                        (condition) =>
                          condition.variable.label +
                          " " +
                          condition.operator +
                          " " +
                          condition.value +
                          " " +
                          (condition.andor ? condition.andor : "") +
                          " "
                      )
                      .join(" ")
                  : "Selecione uma variável"
              }`}
              onClick={(e) => handleOpenMenu(e, index)}
              InputProps={{
                readOnly: true,
                sx: {
                  color: "white",
                  height: "fit-content",
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "purple",
                },
              }}
              style={{
                height: "fit-content",
              }}
            />
            <Handle
              type="source"
              id={`${index}`}
              position={Position.Right}
              isConnectable={isConnectable}
              style={{
                borderRadius: "0 4px 4px 0",
                background: "black",
                width: "10px",
                // adjust the position of the handle to the number of conditions
                top: `${
                  Object.entries(conditions).length === 1 ? 35 : 66 + index * 98
                }px`,
                height: "10px",
              }}
            />
          </div>
        ))}
        <Divider />
        <TextField
          label="Else"
          value="Selecione uma saída"
          InputProps={{
            readOnly: true,
            sx: {
              marginTop: "8px",
              color: "white",
            },
          }}
          InputLabelProps={{
            sx: {
              marginTop: "8px",
              color: "purple",
            },
          }}
        />
      </Box>
      <Handle
        type="source"
        id="else"
        isConnectable={isConnectable}
        position={Position.Bottom}
        style={{
          borderRadius: "0 4px 4px 0",
          background: "black",
          width: "10px",
          height: "10px",
        }}
      />
      <Handle
        type="target"
        position={Position.Left}
        id="a"
        isConnectable={isConnectable}
        style={{
          borderRadius: "0 4px 4px 0",
          background: "black",
          width: "10px",
          height: "10px",
        }}
      />
    </div>
  );
}

export default ConditionalNode;
