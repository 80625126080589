import { useCallback, useEffect, useState, useContext } from "react";
import { Handle, Position } from "reactflow";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { v4 as uuidv4 } from "uuid";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IndexContext } from "../../contexts/indexContext";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "#1f1f23",
  border: "2px solid #000",
  boxShadow: 24,
  color: "white",
  height: "400px",
  p: 4,
};

function ChangeFlowNode({ data, isConnectable }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { headerIndex, setHeaderIndex } = useContext(IndexContext);
  const [flows, setFlows] = useState(() => {
    const savedFlows = localStorage.getItem("flows");
    return JSON.parse(
      savedFlows ??
        JSON.stringify([
          {
            id: uuidv4(),
            name: `Flow 1`,
            label: `Flow 1`,
            nodes: [
              {
                id: "-1",
                type: "start",
                position: { x: 0, y: 0 },
                data: {
                  label: `start`,
                  id: "-1",
                },
              },
            ],
            edges: [],
          },
        ])
    );
  });
  const [fluxo, setFluxo] = useState(() => {
    const flowId = data.nodeInfo?.flowId;
    const flow = flows.find((flow) => flow.id === flowId);
    return flow;
  });
  const [selectedNode, setSelectedNode] = useState(() => {
    const nodeId = data.nodeInfo?.selectedNode;
    const node = fluxo?.nodes.find((node) => node.id === nodeId);
    const object = {
      id: node?.id ?? null,
      label: node?.data.label ?? null,
    };
    return object;
  });
  const [switchState, setSwitchState] = useState(
    data.nodeInfo?.switchState ?? false
  );

  const handleChangeFlow = () => {
    const newIndex = flows.findIndex((flow) => flow.id === fluxo.id);
    setHeaderIndex(newIndex);
  };
  const [nodeOptions, setNodeOptions] = useState([]);
  useEffect(() => {
    // save switchstate to localstorage whenever it changes inside the node with the same id
    const prevFlows = JSON.parse(localStorage.getItem("flows"));
    prevFlows.forEach((flow) => {
      const node = flow.nodes.find((node) => node.id === data.id);
      if (node) {
        const object = {
          switchState: switchState,
          selectedNode: selectedNode ? selectedNode.id : null,
          flowId: fluxo ? fluxo.id : null,
        };
        data.nodeInfo = object;
        // setFlows(prevFlows);
        localStorage.setItem("flows", JSON.stringify(prevFlows));
      }
    });
  }, [switchState, selectedNode, fluxo, data]);

  useEffect(() => {
    if (fluxo) {
      const nodeOptions = fluxo.nodes.map((node) => {
        return {
          label: node.data.label,
          id: node.id,
        };
      });
      setNodeOptions(nodeOptions);
    }
  }, [fluxo]);

  return (
    <div className="changeFlowNodeBody">
      <Box>
        <Button
          onClick={handleOpen}
          sx={{
            color: "#384449",
            fontWeight: "bold",
          }}
        >
          Configurar Fluxo
        </Button>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "skyblue",
          }}
        >
          {fluxo?.label ?? "Nenhum fluxo selecionado"},{" "}
          {selectedNode?.label ?? "Nenhum nó selecionado"}
          {fluxo ? (
            <Button
              sx={{
                margin: "0 10px",
                color: "transparent",
                position: "absolute",
                right: "-20px",
                top: "28px",
                ":hover": {
                  color: "#4a81fa",
                  transition: "all 0.5s ease-in-out",
                  background: "transparent",
                },
              }}
              onClick={handleChangeFlow}
            >
              <ArrowForwardIosIcon />
            </Button>
          ) : null}
        </div>
      </Box>

      <Box sx={{ "& > :not(style)": { m: 1 } }}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableEnforceFocus
        >
          <Box sx={style}>
            <Tooltip title="Selecionar nó específico" placement="top">
              <Switch
                checked={switchState}
                onChange={() => setSwitchState(!switchState)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  position: "fixed",
                  top: "10",
                  right: "45px",
                  "& hover": {
                    cursor: "pointer",
                  },
                }}
              />
            </Tooltip>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <h2 id="modal-modal-title">Configurar Fluxo</h2>
              <p id="modal-modal-description">
                Selecione o fluxo que deseja utilizar
              </p>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={flows}
                onChange={(event, newValue) => {
                  setFluxo(newValue);
                }}
                value={fluxo}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Fluxos"
                    InputProps={{
                      ...params.InputProps, // change the color of the input to white}
                      style: {
                        color: "white",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "white",
                      },
                    }}
                  />
                )}
                sx={{
                  width: 300,
                  border: "1px solid purple",
                  color: "white",
                }}
                // set the value of the selected variable to the current index of the map
                noOptionsText="Nenhuma variável encontrada"
              />
              {switchState && fluxo ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p style={{ marginBottom: "-5px" }}>
                    Selecione o fluxo que deseja utilizar
                  </p>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={nodeOptions}
                    value={selectedNode}
                    onChange={(event, newValue) => {
                      setSelectedNode(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Nós"
                        InputProps={{
                          ...params.InputProps, // change the color of the input to white}
                          style: {
                            color: "white",
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: "white",
                          },
                        }}
                      />
                    )}
                    sx={{
                      width: 300,
                      border: "1px solid purple",
                      color: "white",
                      marginTop: "20px",
                    }}
                    // set the value of the selected variable to the current index of the map
                    noOptionsText="Nenhuma variável encontrada"
                  />
                </div>
              ) : null}
            </div>
          </Box>
        </Modal>

        <Handle
          type="target"
          position={Position.Left}
          id="a"
          isConnectable={isConnectable}
          style={{
            borderRadius: "0 4px 4px 0",
            background: "black",
            width: "10px",
            height: "10px",
            left: "-12px",
          }}
        />
      </Box>
    </div>
  );
}

export default ChangeFlowNode;
