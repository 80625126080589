import React, { useState, useCallback, useContext, useEffect } from "react";
import { Handle, Position } from "reactflow";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { VariablesContext } from "../../contexts/variablesContext";
import { UsedVariablesContext } from "../../contexts/usedVariablesContext";
import Paper from "@mui/material/Paper";
// import ControlledEditor from "@monaco-editor/react";
import Editor from "@monaco-editor/react";
import hljs from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
hljs.registerLanguage("javascript", javascript);

function JsNode({ data, isConnectable }) {
  const { variables, setVariables } = useContext(VariablesContext);
  const { usedVariables, setUsedVariables } = useContext(UsedVariablesContext);
  const [open, setOpen] = useState(false);
  const [selectedVariable, setSelectedVariable] = useState(
    data.nodeInfo?.selectedVariable ? data.nodeInfo.selectedVariable : null
  );
  const [whatsWritten, setWhatsWritten] = useState("");
  const variablesWithoutIDUsuarioLogado = variables?.filter(
    (variable) => variable.label !== "IDUsuarioLogado"
  );
  const [jsCodeInput, setJsCodeInput] = useState(
    data.nodeInfo?.jsCodeInput ? data.nodeInfo.jsCodeInput : ""
  );
  const [jsChecked, setJsChecked] = useState(false);
  const [initialJsCode, setInitialJsCode] = useState("");
  const [value, setValue] = useState(0);
  const [description, setDescription] = useState(
    data.nodeInfo?.description ? data.nodeInfo.description : ""
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const prevFlows = JSON.parse(localStorage.getItem("flows"));
    prevFlows.forEach((flow) => {
      const node = flow.nodes.find((node) => node.id === data.id);
      if (node) {
        const object = {
          selectedVariable: selectedVariable,
          jsCodeInput: jsCodeInput,
          description: description,
        };
        data.nodeInfo = object;
        localStorage.setItem("flows", JSON.stringify(prevFlows));
      }
    });
  }, [selectedVariable, jsCodeInput]);

  const editorOptions = {
    automaticLayout: true,
    fontSize: 16,
    language: "javascript",
    minimap: {
      enabled: false,
    },
    readOnly: false,
    //monaco highlight the code
    codeLens: true,
  };

  const handleSelectVariable = (event, value) => {
    // Check if the variable already exists
    const variableExists = usedVariables?.find(
      (variable) => variable.nodeId === data.id
    );

    // If the variable exists, update its value
    if (variableExists) {
      const updatedUsedVariables = usedVariables?.map((variable) => {
        if (variable?.nodeId === data.id) {
          return { ...variable, variableId: value?.label };
        }
        return variable;
      });
      setUsedVariables(updatedUsedVariables);
    } else {
      // If the variable does not exist, create a new one
      const newUsedVariable = {
        nodeId: data.id,
        variableId: value.label,
      };
      setUsedVariables([...usedVariables, newUsedVariable]);
    }

    setInitialJsCode(`var selectedVar = ${value.data.label};`);
    setSelectedVariable(value);
  };

  const handleNewVariable = (event, value) => {
    if (!variables?.includes(whatsWritten)) {
      const newVariable = {
        label: whatsWritten,
        type: "texto",
        data: { label: whatsWritten },
      };
      setVariables([...variables, newVariable]);
    }
  };

  const checkIfJavaScriptIsValid = (jsCode) => {
    try {
      new Function(jsCode)();
      setJsChecked(true);
      return true;
    } catch (error) {
      setJsChecked(false);
      return false;
    }
  };

  const handleEditorChange = (newValue, e) => {
    // check if the editor is in the first line
    const firstLine = e.changes[0].range.startLineNumber;
    if (firstLine === 1) {
      // concat the initialvalue at the beggining of jscodeinput
      setJsCodeInput(`${initialJsCode}`);
    } else {
      setJsCodeInput(newValue);
    }
  };

  function handleEditorValidation(markers) {
    // model markers
    markers.forEach((marker) => console.log("onValidate:", marker.message));
  }

  return (
    <div className="customNodeBodyInput" title={description}>
      <Button
        sx={{
          color: "#e99848",
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        Configurar JS
      </Button>
      <Box sx={{ "& > :not(style)": { m: 1 } }}>
        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 800,
              height: 600,
              backgroundColor: "#18181b",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Autocomplete
                onInputChange={(event, value) => {
                  setWhatsWritten(value);
                }}
                disablePortal
                id="combo-box-demo"
                options={
                  variablesWithoutIDUsuarioLogado
                    ? variablesWithoutIDUsuarioLogado
                    : []
                }
                value={selectedVariable ? selectedVariable : null}
                sx={{ width: 300, color: "white", border: "1px solid #e7e7e4" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Selecione uma variável para manipular."}
                    InputLabelProps={{
                      style: { color: "#e7e7e4" },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      style: { color: "white" },
                    }}
                    value={selectedVariable ? selectedVariable : null}
                  />
                )}
                onChange={(event, value) => {
                  handleSelectVariable(event, value);
                }}
                noOptionsText={
                  <button
                    onClick={(value) => {
                      handleNewVariable(value);
                    }}
                  >
                    adicionar variável
                  </button>
                }
              />

              {selectedVariable ? (
                <>
                  <Tabs
                    sx={{
                      "& .MuiTabs-indicator": {
                        backgroundColor: "#4678e7",
                        color: "white",
                      },
                    }}
                    TabScrollButtonProps={{
                      style: {
                        color: "white",
                      },
                    }}
                    value={value}
                    onChange={handleChange}
                  >
                    <Tab
                      label="Código JS"
                      sx={{
                        color: "white",
                      }}
                    />
                    <Tab
                      sx={{
                        color: "white",
                      }}
                      label="Descrição do código"
                    />
                  </Tabs>
                  {value === 0 ? (
                    <>
                      <Paper
                        elevation={3}
                        style={{
                          width: "80%",
                          marginLeft: "10%",
                          marginTop: "20px",
                          height: "fit-content",
                          backgroundColor: "#18181b",
                          color: "white",
                          padding: "10px",
                        }}
                      >
                        Escreva um Código JavaScript válido para manipular a
                        variável selecionada. Assegure-se de que o código seja
                        válido.
                        <Tooltip title='O código sempre deverá começar com "var selectedVar = {variavel selecionada}" e sempre deverá conter um retorno.'>
                          <HelpIcon
                            fontSize="small"
                            style={{ marginLeft: "5px" }}
                          />
                        </Tooltip>
                      </Paper>
                      <Editor
                        width="80%"
                        height="300px"
                        language="javascript"
                        theme="vs-dark"
                        // onValidate={handleEditorValidation}
                        options={editorOptions}
                        value={jsCodeInput}
                        onChange={handleEditorChange}
                      />
                    </>
                  ) : (
                    <Paper
                      elevation={3}
                      style={{
                        width: "80%",
                        marginLeft: "10%",
                        marginTop: "20px",
                        height: "fit-content",
                        backgroundColor: "#18181b",
                        color: "white",
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <> Escreva uma descrição para o seu Código JavaScript.</>
                      <TextField
                        onChange={(event) => setDescription(event.target.value)}
                        value={description}
                        InputLabelProps={{
                          style: { color: "white" },
                        }}
                        inputProps={{
                          style: { color: "white", height: "165px" },
                        }}
                        variant="outlined"
                        sx={{
                          width: "80%",
                          color: "white",
                          border: "1px solid white",
                          borderColor: "white",
                          height: "200px",
                          marginLeft: "10%",
                          marginTop: "20px",
                        }}
                        multiline
                      />
                    </Paper>
                  )}
                </>
              ) : null}
            </div>
          </Box>
        </Modal>
      </Box>
      <Handle
        type="target"
        position={Position.Left}
        id="a"
        isConnectable={isConnectable}
        style={{
          borderRadius: "0 4px 4px 0",
          background: "black",
          width: "10px",
          height: "10px",
        }}
      />
      <Handle
        type="source"
        position={Position.Right}
        id="b"
        isConnectable={isConnectable}
        style={{
          borderRadius: "0 4px 4px 0",
          background: "black",
          width: "10px",
          height: "10px",
        }}
      />
    </div>
  );
}

export default JsNode;
