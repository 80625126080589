import React, { createContext, useState } from "react";

export const UsedVariablesContext = createContext();

export const UsedVariablesProvider = ({ children }) => {
  const [usedVariables, setUsedVariables] = useState(() => {});

  return (
    <UsedVariablesContext.Provider value={{ usedVariables, setUsedVariables }}>
      {children}
    </UsedVariablesContext.Provider>
  );
};
