export const salesForceTemplate = {
  flows: [
    {
      nodes: [
        {
          width: 180,
          height: 40,
          id: "307695a0-acdf-45df-8936-f60f2f24575e",
          type: "start",
          position: { x: -530.383465699462, y: 12.934386742573892 },
          data: { label: "start", id: "307695a0-acdf-45df-8936-f60f2f24575e" },
          selected: false,
          positionAbsolute: { x: -530.383465699462, y: 12.934386742573892 },
          dragging: false,
        },
        {
          width: 180,
          height: 40,
          id: "a437aa2f-b026-4c40-a9e3-891b1828b111",
          type: "end",
          position: { x: 323.6641466184859, y: 46.20460584326136 },
          data: {
            label: "end",
            id: "a437aa2f-b026-4c40-a9e3-891b1828b111",
            nodeInfo: [],
          },
          positionAbsolute: { x: 323.6641466184859, y: 46.20460584326136 },
          selected: false,
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "e6bbdc2e-663c-4b0d-90d9-d9682674d8b9",
          type: "text",
          position: { x: -201.01015874372314, y: 10.801218078941725 },
          data: {
            label: "text",
            id: "e6bbdc2e-663c-4b0d-90d9-d9682674d8b9",
            nodeInfo: { inputValue: "" },
          },
          positionAbsolute: { x: -201.01015874372314, y: 10.801218078941725 },
        },
        {
          width: 218,
          height: 100,
          id: "9a98a150-8dc8-4983-bb43-ae81e517ed06",
          type: "text",
          position: { x: -193.8822974774557, y: -174.8367385201974 },
          data: {
            label: "text",
            id: "9a98a150-8dc8-4983-bb43-ae81e517ed06",
            nodeInfo: { inputValue: "SALESFORCE" },
          },
          selected: true,
          dragging: false,
          positionAbsolute: { x: -193.8822974774557, y: -174.8367385201974 },
        },
      ],
      edges: [
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "d80e4987-b97d-4f9d-9085-d0156718b0c2",
          sourceHandle: "b",
          target: "a437aa2f-b026-4c40-a9e3-891b1828b111",
          targetHandle: "b",
          tooltipText: "teste",
          type: "default",
          id: "b-d80e4987-b97d-4f9d-9085-d0156718b0c2->a437aa2f-b026-4c40-a9e3-891b1828b111/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "1eae3eec-28f7-46f0-a3db-2bba44838c7c",
          sourceHandle: "b",
          target: "a437aa2f-b026-4c40-a9e3-891b1828b111",
          targetHandle: "b",
          tooltipText: "teste",
          type: "default",
          id: "b-1eae3eec-28f7-46f0-a3db-2bba44838c7c->a437aa2f-b026-4c40-a9e3-891b1828b111/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "ed73700d-56e6-4d93-b93e-c7e17e6a1084",
          sourceHandle: "b",
          target: "a437aa2f-b026-4c40-a9e3-891b1828b111",
          targetHandle: "b",
          tooltipText: "teste",
          type: "default",
          id: "b-ed73700d-56e6-4d93-b93e-c7e17e6a1084->a437aa2f-b026-4c40-a9e3-891b1828b111/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "1e88a64f-ff96-471d-876b-c375fd1fa0d4",
          sourceHandle: "b",
          target: "a437aa2f-b026-4c40-a9e3-891b1828b111",
          targetHandle: "b",
          tooltipText: "teste",
          type: "default",
          id: "b-1e88a64f-ff96-471d-876b-c375fd1fa0d4->a437aa2f-b026-4c40-a9e3-891b1828b111/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "307695a0-acdf-45df-8936-f60f2f24575e",
          sourceHandle: "b",
          target: "e6bbdc2e-663c-4b0d-90d9-d9682674d8b9",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-307695a0-acdf-45df-8936-f60f2f24575e->e6bbdc2e-663c-4b0d-90d9-d9682674d8b9/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "e6bbdc2e-663c-4b0d-90d9-d9682674d8b9",
          sourceHandle: "b",
          target: "a437aa2f-b026-4c40-a9e3-891b1828b111",
          targetHandle: "b",
          tooltipText: "teste",
          type: "default",
          id: "b-e6bbdc2e-663c-4b0d-90d9-d9682674d8b9->a437aa2f-b026-4c40-a9e3-891b1828b111/Conexão comum",
        },
      ],
      viewport: {
        x: 728.7252323069007,
        y: 340.8817056803771,
        zoom: 1.2519205490389054,
      },
      id: "b260685a-dc40-4963-a01a-a8d8a98f1426",
      name: "Flow 1",
      label: "Flow 1",
    },
  ],
  variables: [
    { label: "IDUsuarioLogado", type: "texto", data: { label: "1" } },
    {
      label: "PrimeiraMensagem",
      type: "texto",
      data: { label: "PrimeiraMensagem" },
    },
  ],
  usedVariables: [],
};
