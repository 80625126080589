import React, { createContext, useState } from "react";

export const IndexContext = createContext();

export const IndexProvider = ({ children }) => {
  const [headerIndex, setHeaderIndex] = useState(0); // Initialize with movideskTemplate

  return (
    <IndexContext.Provider
      value={{ headerIndex, setHeaderIndex }} // Pass handleTemplateSelection function to context
    >
      {children}
    </IndexContext.Provider>
  );
};
