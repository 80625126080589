import { useCallback, useState, useContext } from "react";
import {
  useStore,
  getBezierPath,
  getStraightPath,
  MarkerType,
  Position,
  BaseEdge,
  EdgeLabelRenderer,
  getSmoothStepPath,
} from "reactflow";
import { getEdgeParams } from "./utils.js";
import Tooltip from "@mui/material/Tooltip";
import { ThemeContext } from "../../contexts/themeContext";

function DefaultEdge({
  id,
  source,
  target,
  markerEnd,
  sourceX,
  sourceY,
  targetX,
  targetY,
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const { pathType, setPathType } = useContext(ThemeContext);
  const sourceNode = useStore(
    useCallback((store) => store.nodeInternals.get(source), [source])
  );
  const targetNode = useStore(
    useCallback((store) => store.nodeInternals.get(target), [target])
  );

  const text = id.split("/").pop();

  if (!sourceNode || !targetNode) {
    return null;
  }

  let [edgePath, labelX, labelY] = [null, null, null];

  switch (pathType) {
    case "smoothstep":
      [edgePath, labelX, labelY] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition: Position.Right,
        targetX,
        targetY,
        targetPosition: Position.Left,
      });
      break;
    case "straight":
      [edgePath, labelX, labelY] = getStraightPath({
        sourceX,
        sourceY,
        sourcePosition: Position.Right,
        targetX,
        targetY,
        targetPosition: Position.Left,
      });
      break;
    case "bezier":
      [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition: Position.Right,
        targetX,
        targetY,
        targetPosition: Position.Left,
      });
      break;
    default:
      break;
    // return null;
  }

  const edgeOptions = {
    style: { strokeWidth: 3, stroke: "#5b5bf7" },
    type: {
      type: MarkerType.ArrowClosed,
      color: "aquamarine",
    },
  };

  return (
    <>
      <BaseEdge
        id={id}
        className="react-flow__edge-path"
        path={edgePath}
        markerEnd={markerEnd}
        style={edgeOptions.style}
        type="success"
      />
      <EdgeLabelRenderer
        style={{
          pointerEvents: "all",
        }}
      >
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            background: "transparent",
            padding: 10,
            borderRadius: 5,
            fontSize: 12,
            fontWeight: 700,
            pointerEvents: "all",
            color: "transparent",
          }}
          onMouseOver={() => setShowTooltip(true)}
          onMouseOut={() => setShowTooltip(false)}
          className="nodrag nopan"
        >
          {showTooltip && (
            <Tooltip title={text} placement="top">
              <div></div>
            </Tooltip>
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export default DefaultEdge;
