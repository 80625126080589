import { useCallback, useEffect, useState, useContext } from "react";
import { Handle, Position } from "reactflow";
import RttIcon from "@mui/icons-material/Rtt";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { VariablesContext } from "../../contexts/variablesContext";
import { UsedVariablesContext } from "../../contexts/usedVariablesContext";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
function InputTextNode({ data, isConnectable }) {
  const onChange = useCallback((evt) => {}, []);
  const [anchorEl, setAnchorEl] = useState(null);
  const { usedVariables, setUsedVariables } = useContext(UsedVariablesContext);
  const { variables, setVariables } = useContext(VariablesContext);
  const [selectedVariable, setSelectedVariable] = useState(() => {
    const usedVariable =
      usedVariables?.find((variable) => variable?.nodeId === data?.id) ?? null;
    const variable =
      variables?.find(
        (variable) => variable?.label === usedVariable?.variableId
      ) ?? null;
    return variable;
  });
  const [whatsWritten, setWhatsWritten] = useState("");
  // take the variable with label IDUsuarioLogado out from the variables array
  const variablesWithoutIDUsuarioLogadoAndPrimeiraMensagem = variables?.filter(
    (variable) =>
      variable.label !== "IDUsuarioLogado" &&
      variable.label !== "PrimeiraMensagem"
  );

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log("selectedVariable", selectedVariable);

  useEffect(() => {
    // save selectedVariable to localstorage whenever it changes inside the node with the same id
    const prevFlows = JSON.parse(localStorage.getItem("flows"));
    prevFlows.forEach((flow) => {
      const node = flow.nodes.find((node) => node.id === data.id);

      if (node) {
        const object = {
          selectedVariable: selectedVariable,
        };

        node.data.nodeInfo = object;
      }
      // console.log("prevflows", prevFlows);
      localStorage.setItem("flows", JSON.stringify(prevFlows));
    });
  }, [selectedVariable, data]);

  const handleSelectVariable = (event, value) => {
    // Check if the variable already exists
    const variableExists = usedVariables?.find(
      (variable) => variable.nodeId === data.id
    );

    // If the variable exists, update its value
    if (variableExists) {
      const updatedUsedVariables = usedVariables?.map((variable) => {
        if (variable?.nodeId === data.id) {
          return { ...variable, variableId: value?.label };
        }
        return variable;
      });
      setUsedVariables(updatedUsedVariables);
    } else {
      // If the variable does not exist, create a new one
      const newUsedVariable = {
        nodeId: data.id,
        variableId: value.label,
      };
      setUsedVariables([...usedVariables, newUsedVariable]);
    }

    setSelectedVariable(value);

    setAnchorEl(null);
  };

  // console.log(data);

  const handleNewVariable = (event, value) => {
    if (!variables?.includes(whatsWritten)) {
      const newVariable = {
        label: whatsWritten,
        type: "texto",
        data: { label: whatsWritten },
      };
      setVariables([...variables, newVariable]);
    }
  };

  return (
    <div className="customNodeBodyInput">
      <Box sx={{ "& > :not(style)": { m: 1 } }}>
        <Menu
          id="menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          MenuListProps={{ autoFocus: false }}
        >
          <Tooltip
            title="Digite uma variável que não existe para criá-la"
            style={{ zIndex: 10 }} // Set a higher zIndex
          >
            <HelpOutlineIcon
              style={{
                zIndex: 10, // Set a higher zIndex
                color: "#1f1f23",
                position: "absolute",
                right: "0",
                top: "0",
                marginRight: "10px",
                marginTop: "10px",
                opacity: "0.7",
              }}
            />
          </Tooltip>

          <MenuItem
            onKeyDown={(e) => e.stopPropagation()}
            sx={{ flexDirection: "column", alignItems: "flex-start" }}
          >
            Salvar resposta em uma variável:
            <Autocomplete
              onInputChange={(event, value) => {
                setWhatsWritten(value);
              }}
              disablePortal
              id="combo-box-demo"
              options={
                variablesWithoutIDUsuarioLogadoAndPrimeiraMensagem
                  ? variablesWithoutIDUsuarioLogadoAndPrimeiraMensagem
                  : []
              }
              value={selectedVariable ? selectedVariable : null}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="variáveis" />
              )}
              onChange={(event, value) => {
                handleSelectVariable(event, value);
              }}
              noOptionsText={
                <button
                  onClick={(value) => {
                    handleNewVariable(value);
                  }}
                >
                  adicionar variável
                </button>
              }
            />
          </MenuItem>
        </Menu>
        <Button
          onClick={handleClick}
          sx={{
            background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
            color: "white",
            maxHeight: "15px",
            fontSize: "0.6rem",
          }}
        >
          Selecionar variável
        </Button>
        <FormControl variant="standard">
          {/* <InputLabel
            htmlFor="input-with-icon-adornment"
            style={{
              display: "block",
              textAlign: "center",
              cursor: "grab",
              color: "white",
            }}
          ></InputLabel> */}
          <Input
            id="input-with-icon-adornment"
            onChange={onChange}
            // disabled={true}
            sx={{
              color: "white",
            }}
            value={
              selectedVariable
                ? `coletar ${selectedVariable.label}`
                : "selecionar"
            }
            style={{
              color: "white",
            }}
            startAdornment={
              <InputAdornment position="start">
                <ChatBubbleIcon
                  style={{ color: "#ffa54c", marginRight: "5px" }}
                />
              </InputAdornment>
            }
            inputProps={{
              style: {
                color: "white",
              },
            }}
          />
        </FormControl>
      </Box>
      <Handle
        type="target"
        position={Position.Left}
        id="a"
        isConnectable={isConnectable}
        style={{
          borderRadius: "0 4px 4px 0",
          background: "black",
          width: "10px",
          height: "10px",
        }}
      />
      <Handle
        type="source"
        position={Position.Right}
        id="b"
        isConnectable={isConnectable}
        style={{
          borderRadius: "0 4px 4px 0",
          background: "black",
          width: "10px",
          height: "10px",
        }}
      />
    </div>
  );
}

export default InputTextNode;
