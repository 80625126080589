export const movideskTemplate = {
  flows: [
    {
      nodes: [
        {
          width: 180,
          height: 40,
          id: "307695a0-acdf-45df-8936-f60f2f24575e",
          type: "start",
          position: { x: -912.51918693145, y: -241.9604761445848 },
          data: { label: "start", id: "307695a0-acdf-45df-8936-f60f2f24575e" },
          selected: false,
          positionAbsolute: { x: -912.51918693145, y: -241.9604761445848 },
          dragging: false,
        },
        {
          width: 250,
          height: 368,
          id: "568df032-d772-4a72-a32f-4ebcadcaa753",
          type: "choice",
          position: { x: -501.84061699429463, y: -365.88420247988995 },
          data: {
            label: "choice",
            id: "568df032-d772-4a72-a32f-4ebcadcaa753",
            nodeInfo: {
              text: "Olá! Bem vindo(a) ao suporte MoviDesk! Por favor escolha uma das opções abaixo.",
              options: [
                "Criar ticket ",
                "Atualizar ticket",
                "Finalizar ticket",
              ],
            },
          },
          selected: false,
          positionAbsolute: { x: -501.84061699429463, y: -365.88420247988995 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "7684702c-540e-412d-b84f-c51479217034",
          type: "text",
          position: { x: -938.3153292871694, y: -57.00839710025231 },
          data: {
            label: "text",
            id: "7684702c-540e-412d-b84f-c51479217034",
            nodeInfo: { inputValue: "Por favor, escolha uma opção válida!" },
          },
          selected: false,
          positionAbsolute: { x: -938.3153292871694, y: -57.00839710025231 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "72585c61-1957-4896-b75c-2629357f79b1",
          type: "api",
          position: { x: 134.96666470734067, y: -239.82286109457928 },
          data: {
            label: "api",
            id: "72585c61-1957-4896-b75c-2629357f79b1",
            nodeInfo: {
              httpRequestType: "GET",
              httpRequestUrl:
                "https://api.movidesk.com/public/v1/persons?token=8b538fc4-949f-4935-ab88-c5ec6ab6e768&$filter=Contacts/any(e: e/contact eq '{{IDCliente}}')",
              requestBody: "",
              requestHeaders: "",
              responseBody: "",
              selectedVariable: {
                label: "respostaChamadaAPIPersons",
                type: "texto",
                data: { label: "respostaChamadaAPIPersons" },
              },
              spec: "",
              flowId: "b260685a-dc40-4963-a01a-a8d8a98f1426",
              description: "",
            },
          },
          selected: false,
          positionAbsolute: { x: 134.96666470734067, y: -239.82286109457928 },
          dragging: false,
        },
        {
          width: 200,
          height: 63,
          id: "d107adc8-0ae0-4348-9ee6-d9545be5fbb1",
          type: "change",
          position: { x: 703.6301182455971, y: -253.73648655740536 },
          data: {
            label: "change",
            id: "d107adc8-0ae0-4348-9ee6-d9545be5fbb1",
            nodeInfo: {
              switchState: true,
              selectedNode: "bbf9c0f6-6183-47d4-9180-8469b7883aad",
              flowId: "e8ad4996-20bc-4275-a73f-454fd85ba80d",
            },
          },
          selected: false,
          positionAbsolute: { x: 703.6301182455971, y: -253.73648655740536 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "d20062de-0a5e-4daf-967e-82a5b14cf4ae",
          type: "text",
          position: { x: 136.13857761103623, y: -108.48232976036225 },
          data: {
            label: "text",
            id: "d20062de-0a5e-4daf-967e-82a5b14cf4ae",
            nodeInfo: {
              inputValue:
                "Seu usuário não foi encontrado na base de dados do MoviDesk. Por favor, informe um ID válido.",
            },
          },
          selected: false,
          positionAbsolute: { x: 136.13857761103623, y: -108.48232976036225 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "690f0ec6-fe88-4544-88ed-1c2a129a4eeb",
          type: "inputText",
          position: { x: 477.51403655576087, y: -97.31290391205225 },
          data: {
            label: "inputText",
            id: "690f0ec6-fe88-4544-88ed-1c2a129a4eeb",
            nodeInfo: [],
          },
          selected: false,
          positionAbsolute: { x: 477.51403655576087, y: -97.31290391205225 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "851b1eab-a224-4b81-9fea-d371968db265",
          type: "api",
          position: { x: 141.3967861830566, y: 38.51868523189549 },
          data: {
            label: "api",
            id: "851b1eab-a224-4b81-9fea-d371968db265",
            nodeInfo: {
              httpRequestType: "GET",
              httpRequestUrl:
                "https://api.movidesk.com/public/v1/persons?token=8b538fc4-949f-4935-ab88-c5ec6ab6e768&$filter=Contacts/any(e: e/contact eq '{{IDCliente}}')",
              requestBody: "",
              requestHeaders: "",
              responseBody: "",
              selectedVariable: {
                label: "respostaChamadaAPIPersons",
                type: "texto",
                data: { label: "respostaChamadaAPIPersons" },
              },
              spec: "",
              flowId: "b260685a-dc40-4963-a01a-a8d8a98f1426",
              description: "",
            },
          },
          selected: false,
          positionAbsolute: { x: 141.3967861830566, y: 38.51868523189549 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "14cbeb9a-e39e-475b-ada0-b701a418fd75",
          type: "text",
          position: { x: 136.99261757844744, y: 181.0598691187668 },
          data: {
            label: "text",
            id: "14cbeb9a-e39e-475b-ada0-b701a418fd75",
            nodeInfo: {
              inputValue:
                "Seu usuário não foi encontrado na base de dados do MoviDesk. Por favor, informe um ID válido.",
            },
          },
          selected: false,
          positionAbsolute: { x: 136.99261757844744, y: 181.0598691187668 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "28d9febd-4fbe-47a8-97bb-f52aff363cda",
          type: "inputText",
          position: { x: 401.4887729131624, y: 190.77229599756328 },
          data: {
            label: "inputText",
            id: "28d9febd-4fbe-47a8-97bb-f52aff363cda",
            nodeInfo: [],
          },
          selected: false,
          positionAbsolute: { x: 401.4887729131624, y: 190.77229599756328 },
          dragging: false,
        },
        {
          width: 200,
          height: 63,
          id: "08118d37-ee84-4b85-b5ec-5925e8693de3",
          type: "change",
          position: { x: 708.6705428790999, y: 46.10882313501065 },
          data: {
            label: "change",
            id: "08118d37-ee84-4b85-b5ec-5925e8693de3",
            nodeInfo: {
              switchState: true,
              selectedNode: "76ce4228-f87c-4aeb-911e-eb3a70d610ea",
              flowId: "4f795745-821d-4f5f-b830-fba0e82648b7",
            },
          },
          selected: false,
          positionAbsolute: { x: 708.6705428790999, y: 46.10882313501065 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "dac11446-3616-4436-a1b3-3edf643840fd",
          type: "inputText",
          position: { x: 382.1386540951017, y: 614.0736091618278 },
          data: {
            label: "inputText",
            id: "b0ddc3c4-bc3f-4780-8d2d-ff8cc765dc06",
            nodeInfo: [],
          },
          selected: false,
          positionAbsolute: { x: 382.1386540951017, y: 614.0736091618278 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "cf648761-f0e0-449b-a4cd-1a934f1370ae",
          type: "jsNode",
          position: { x: -150.8212520900147, y: -231.63815437279152 },
          data: {
            label: "jsNode",
            id: "cf648761-f0e0-449b-a4cd-1a934f1370ae",
            nodeInfo: {
              selectedVariable: {
                label: "IDCliente",
                type: "texto",
                data: { label: "IDCliente" },
              },
              jsCodeInput:
                "var selectedVar = IDCliente;\r\n\r\n    let phoneNumber = selectedVar.toString();\r\n    const areaCode = phoneNumber.slice(2, 4);\r\n\r\n    const firstPart = phoneNumber.slice(4, 9);\r\n\r\n    const secondPart = phoneNumber.slice(9);\r\n\r\n    const formattedNumber = `(${areaCode}) ${firstPart}-${secondPart}`;\r\n\r\nreturn formattedNumber;",
              description:
                "Este código JS cria uma máscara para o número do WhatsApp ficar no formato necessário para a chamada API. ",
            },
          },
          selected: false,
          positionAbsolute: { x: -150.8212520900147, y: -231.63815437279152 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "b3ca0624-4109-4297-b234-983e5a78a07e",
          type: "jsNode",
          position: { x: -142.63655616141904, y: 36.22861911096564 },
          data: {
            label: "jsNode",
            id: "b3ca0624-4109-4297-b234-983e5a78a07e",
            nodeInfo: {
              selectedVariable: {
                label: "IDCliente",
                type: "texto",
                data: { label: "IDCliente" },
              },
              jsCodeInput:
                "var selectedVar = IDCliente;\r\n\r\n let phoneNumber = selectedVar.toString();\r\n    const areaCode = phoneNumber.slice(2, 4);\r\n\r\n    const firstPart = phoneNumber.slice(4, 9);\r\n\r\n    const secondPart = phoneNumber.slice(9);\r\n\r\n    const formattedNumber = `(${areaCode}) ${firstPart}-${secondPart}`;\r\n\r\nreturn formattedNumber;",
              description: "",
            },
          },
          selected: false,
          positionAbsolute: { x: -142.63655616141904, y: 36.22861911096564 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "1dba00e4-791b-449f-a270-e28edd7093a4",
          type: "jsNode",
          position: { x: -160.56789183657855, y: 398.0958400088424 },
          data: {
            label: "jsNode",
            id: "1dba00e4-791b-449f-a270-e28edd7093a4",
            nodeInfo: {
              selectedVariable: {
                label: "IDCliente",
                type: "texto",
                data: { label: "IDCliente" },
              },
              jsCodeInput:
                "var selectedVar = IDCliente;\r\n\r\n let phoneNumber = selectedVar.toString();\r\n    const areaCode = phoneNumber.slice(2, 4);\r\n\r\n    const firstPart = phoneNumber.slice(4, 9);\r\n\r\n    const secondPart = phoneNumber.slice(9);\r\n\r\n    const formattedNumber = `(${areaCode}) ${firstPart}-${secondPart}`;\r\n\r\nreturn formattedNumber;",
              description: "",
            },
          },
          selected: false,
          positionAbsolute: { x: -160.56789183657855, y: 398.0958400088424 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "44ac10ec-29c8-4e54-bf52-0eee36f2438a",
          type: "jsNode",
          position: { x: 400.4921193949904, y: -251.2358671079828 },
          data: {
            label: "jsNode",
            id: "44ac10ec-29c8-4e54-bf52-0eee36f2438a",
            nodeInfo: {
              selectedVariable: {
                label: "respostaChamadaAPIPersons",
                type: "texto",
                data: { label: "respostaChamadaAPIPersons" },
              },
              jsCodeInput:
                "var selectedVar = respostaChamadaAPIPersons;\r\n\r\n// console.log('selectedVarrrrrrrrrrr', selectedVar[0].id)\r\n\r\nreturn selectedVar[0].id",
              description: "",
            },
          },
          selected: false,
          positionAbsolute: { x: 400.4921193949904, y: -251.2358671079828 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "44c60e79-d5ff-4567-9900-3e10ff4ec582",
          type: "jsNode",
          position: { x: 387.4039583026166, y: 41.72066816264845 },
          data: {
            label: "jsNode",
            id: "44c60e79-d5ff-4567-9900-3e10ff4ec582",
            nodeInfo: {
              selectedVariable: {
                label: "respostaChamadaAPIPersons",
                type: "texto",
                data: { label: "respostaChamadaAPIPersons" },
              },
              jsCodeInput:
                "var selectedVar = respostaChamadaAPIPersons;\r\n\r\n// console.log('selectedVarrrrrrrrrrr', selectedVar[0].id)\r\n\r\nreturn selectedVar[0].id",
              description: "",
            },
          },
          selected: false,
          positionAbsolute: { x: 387.4039583026166, y: 41.72066816264845 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "b3a71b4d-4a87-4192-b60a-29cfd86cd61b",
          type: "jsNode",
          position: { x: 458.8479954784083, y: 399.1722674289338 },
          data: {
            label: "jsNode",
            id: "b3a71b4d-4a87-4192-b60a-29cfd86cd61b",
            nodeInfo: {
              selectedVariable: {
                label: "respostaChamadaAPIPersons",
                type: "texto",
                data: { label: "respostaChamadaAPIPersons" },
              },
              jsCodeInput:
                "var selectedVar = respostaChamadaAPIPersons;\r\n\r\nreturn selectedVar[0].id",
              description: "",
            },
          },
          selected: false,
          positionAbsolute: { x: 458.8479954784083, y: 399.1722674289338 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "c499b744-592e-4fb6-92e7-d500f83a0e6c",
          type: "text",
          position: { x: -46.284786263025985, y: 605.6299680362507 },
          data: {
            label: "text",
            id: "c499b744-592e-4fb6-92e7-d500f83a0e6c",
            nodeInfo: { inputValue: "deu ruim faz de novo ae" },
          },
          selected: false,
          positionAbsolute: { x: -46.284786263025985, y: 605.6299680362507 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "1207bcd8-5b2a-44c7-9dd2-860833745188",
          type: "api",
          position: { x: 174.00596534712312, y: 386.4821281631188 },
          data: {
            label: "api",
            id: "1207bcd8-5b2a-44c7-9dd2-860833745188",
            nodeInfo: {
              httpRequestType: "GET",
              httpRequestUrl:
                "https://api.movidesk.com/public/v1/persons?token=8b538fc4-949f-4935-ab88-c5ec6ab6e768&$filter=Contacts/any(e: e/contact eq '{{IDCliente}}')",
              requestBody: "",
              requestHeaders: "",
              responseBody: "",
              selectedVariable: {
                label: "respostaChamadaAPIPersons",
                type: "texto",
                data: { label: "respostaChamadaAPIPersons" },
              },
              spec: "",
              flowId: "b260685a-dc40-4963-a01a-a8d8a98f1426",
              description: "",
            },
          },
          selected: false,
          positionAbsolute: { x: 174.00596534712312, y: 386.4821281631188 },
          dragging: false,
        },
        {
          width: 200,
          height: 63,
          id: "13fa83ec-2f20-4f4a-927d-3759000cceca",
          type: "change",
          position: { x: 783.3999756967497, y: 392.2583747067172 },
          data: {
            label: "change",
            id: "13fa83ec-2f20-4f4a-927d-3759000cceca",
            nodeInfo: {
              switchState: true,
              selectedNode: "349931d7-0a90-41b1-ab82-906a01493a38",
              flowId: "8ff99297-fe79-4f38-96d8-f1abdc88678c",
            },
          },
          selected: false,
          positionAbsolute: { x: 783.3999756967497, y: 392.2583747067172 },
          dragging: false,
        },
      ],
      edges: [
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "307695a0-acdf-45df-8936-f60f2f24575e",
          sourceHandle: "b",
          target: "568df032-d772-4a72-a32f-4ebcadcaa753",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-307695a0-acdf-45df-8936-f60f2f24575e->568df032-d772-4a72-a32f-4ebcadcaa753/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "568df032-d772-4a72-a32f-4ebcadcaa753",
          sourceHandle: "else",
          target: "7684702c-540e-412d-b84f-c51479217034",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "else-568df032-d772-4a72-a32f-4ebcadcaa753->7684702c-540e-412d-b84f-c51479217034/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "7684702c-540e-412d-b84f-c51479217034",
          sourceHandle: "b",
          target: "568df032-d772-4a72-a32f-4ebcadcaa753",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-7684702c-540e-412d-b84f-c51479217034->568df032-d772-4a72-a32f-4ebcadcaa753/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#a14e4e" },
          source: "72585c61-1957-4896-b75c-2629357f79b1",
          sourceHandle: "failure",
          target: "d20062de-0a5e-4daf-967e-82a5b14cf4ae",
          targetHandle: "a",
          type: "failure",
          id: "failure72585c61-1957-4896-b75c-2629357f79b1->d20062de-0a5e-4daf-967e-82a5b14cf4ae",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "d20062de-0a5e-4daf-967e-82a5b14cf4ae",
          sourceHandle: "b",
          target: "690f0ec6-fe88-4544-88ed-1c2a129a4eeb",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-d20062de-0a5e-4daf-967e-82a5b14cf4ae->690f0ec6-fe88-4544-88ed-1c2a129a4eeb/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "690f0ec6-fe88-4544-88ed-1c2a129a4eeb",
          sourceHandle: "b",
          target: "72585c61-1957-4896-b75c-2629357f79b1",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-690f0ec6-fe88-4544-88ed-1c2a129a4eeb->72585c61-1957-4896-b75c-2629357f79b1/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#a14e4e" },
          source: "851b1eab-a224-4b81-9fea-d371968db265",
          sourceHandle: "failure",
          target: "14cbeb9a-e39e-475b-ada0-b701a418fd75",
          targetHandle: "a",
          type: "failure",
          id: "failure851b1eab-a224-4b81-9fea-d371968db265->14cbeb9a-e39e-475b-ada0-b701a418fd75",
          selected: false,
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "14cbeb9a-e39e-475b-ada0-b701a418fd75",
          sourceHandle: "b",
          target: "28d9febd-4fbe-47a8-97bb-f52aff363cda",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-14cbeb9a-e39e-475b-ada0-b701a418fd75->28d9febd-4fbe-47a8-97bb-f52aff363cda/Conexão comum",
          selected: false,
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "28d9febd-4fbe-47a8-97bb-f52aff363cda",
          sourceHandle: "b",
          target: "851b1eab-a224-4b81-9fea-d371968db265",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-28d9febd-4fbe-47a8-97bb-f52aff363cda->851b1eab-a224-4b81-9fea-d371968db265/Conexão comum",
          selected: false,
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "d9cd034e-29db-4a8a-8d30-4aa501cc2add",
          sourceHandle: "b",
          target: "dac11446-3616-4436-a1b3-3edf643840fd",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-d9cd034e-29db-4a8a-8d30-4aa501cc2add->dac11446-3616-4436-a1b3-3edf643840fd/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "e6c4b56f-163e-4dc1-802d-1f65a23d0278",
          sourceHandle: "b",
          target: "72585c61-1957-4896-b75c-2629357f79b1",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-e6c4b56f-163e-4dc1-802d-1f65a23d0278->72585c61-1957-4896-b75c-2629357f79b1/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "568df032-d772-4a72-a32f-4ebcadcaa753",
          sourceHandle: "0",
          target: "cf648761-f0e0-449b-a4cd-1a934f1370ae",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "0-568df032-d772-4a72-a32f-4ebcadcaa753->cf648761-f0e0-449b-a4cd-1a934f1370ae/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "cf648761-f0e0-449b-a4cd-1a934f1370ae",
          sourceHandle: "b",
          target: "72585c61-1957-4896-b75c-2629357f79b1",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-cf648761-f0e0-449b-a4cd-1a934f1370ae->72585c61-1957-4896-b75c-2629357f79b1/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "568df032-d772-4a72-a32f-4ebcadcaa753",
          sourceHandle: "1",
          target: "b3ca0624-4109-4297-b234-983e5a78a07e",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "1-568df032-d772-4a72-a32f-4ebcadcaa753->b3ca0624-4109-4297-b234-983e5a78a07e/Conexão comum",
          selected: false,
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "b3ca0624-4109-4297-b234-983e5a78a07e",
          sourceHandle: "b",
          target: "851b1eab-a224-4b81-9fea-d371968db265",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-b3ca0624-4109-4297-b234-983e5a78a07e->851b1eab-a224-4b81-9fea-d371968db265/Conexão comum",
          selected: false,
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "568df032-d772-4a72-a32f-4ebcadcaa753",
          sourceHandle: "2",
          target: "1dba00e4-791b-449f-a270-e28edd7093a4",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "2-568df032-d772-4a72-a32f-4ebcadcaa753->1dba00e4-791b-449f-a270-e28edd7093a4/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#4a8e4e" },
          source: "72585c61-1957-4896-b75c-2629357f79b1",
          sourceHandle: "success",
          target: "44ac10ec-29c8-4e54-bf52-0eee36f2438a",
          targetHandle: "a",
          tooltipText: "teste0",
          type: "success",
          id: "success72585c61-1957-4896-b75c-2629357f79b1->44ac10ec-29c8-4e54-bf52-0eee36f2438a",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "44ac10ec-29c8-4e54-bf52-0eee36f2438a",
          sourceHandle: "b",
          target: "d107adc8-0ae0-4348-9ee6-d9545be5fbb1",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-44ac10ec-29c8-4e54-bf52-0eee36f2438a->d107adc8-0ae0-4348-9ee6-d9545be5fbb1/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#4a8e4e" },
          source: "851b1eab-a224-4b81-9fea-d371968db265",
          sourceHandle: "success",
          target: "44c60e79-d5ff-4567-9900-3e10ff4ec582",
          targetHandle: "a",
          tooltipText: "teste0",
          type: "success",
          id: "success851b1eab-a224-4b81-9fea-d371968db265->44c60e79-d5ff-4567-9900-3e10ff4ec582",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "44c60e79-d5ff-4567-9900-3e10ff4ec582",
          sourceHandle: "b",
          target: "08118d37-ee84-4b85-b5ec-5925e8693de3",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-44c60e79-d5ff-4567-9900-3e10ff4ec582->08118d37-ee84-4b85-b5ec-5925e8693de3/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#4a8e4e" },
          source: "3013af26-f7e7-4b1d-9097-d5591a47d409",
          sourceHandle: "success",
          target: "b3a71b4d-4a87-4192-b60a-29cfd86cd61b",
          targetHandle: "a",
          tooltipText: "teste0",
          type: "success",
          id: "success3013af26-f7e7-4b1d-9097-d5591a47d409->b3a71b4d-4a87-4192-b60a-29cfd86cd61b",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#a14e4e" },
          source: "3013af26-f7e7-4b1d-9097-d5591a47d409",
          sourceHandle: "failure",
          target: "c499b744-592e-4fb6-92e7-d500f83a0e6c",
          targetHandle: "a",
          type: "failure",
          id: "failure3013af26-f7e7-4b1d-9097-d5591a47d409->c499b744-592e-4fb6-92e7-d500f83a0e6c",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "c499b744-592e-4fb6-92e7-d500f83a0e6c",
          sourceHandle: "b",
          target: "dac11446-3616-4436-a1b3-3edf643840fd",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-c499b744-592e-4fb6-92e7-d500f83a0e6c->dac11446-3616-4436-a1b3-3edf643840fd/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "1dba00e4-791b-449f-a270-e28edd7093a4",
          sourceHandle: "b",
          target: "1207bcd8-5b2a-44c7-9dd2-860833745188",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-1dba00e4-791b-449f-a270-e28edd7093a4->1207bcd8-5b2a-44c7-9dd2-860833745188/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#4a8e4e" },
          source: "1207bcd8-5b2a-44c7-9dd2-860833745188",
          sourceHandle: "success",
          target: "b3a71b4d-4a87-4192-b60a-29cfd86cd61b",
          targetHandle: "a",
          tooltipText: "teste0",
          type: "success",
          id: "success1207bcd8-5b2a-44c7-9dd2-860833745188->b3a71b4d-4a87-4192-b60a-29cfd86cd61b",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#a14e4e" },
          source: "1207bcd8-5b2a-44c7-9dd2-860833745188",
          sourceHandle: "failure",
          target: "c499b744-592e-4fb6-92e7-d500f83a0e6c",
          targetHandle: "a",
          type: "failure",
          id: "failure1207bcd8-5b2a-44c7-9dd2-860833745188->c499b744-592e-4fb6-92e7-d500f83a0e6c",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "dac11446-3616-4436-a1b3-3edf643840fd",
          sourceHandle: "b",
          target: "1207bcd8-5b2a-44c7-9dd2-860833745188",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-dac11446-3616-4436-a1b3-3edf643840fd->1207bcd8-5b2a-44c7-9dd2-860833745188/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "b3a71b4d-4a87-4192-b60a-29cfd86cd61b",
          sourceHandle: "b",
          target: "13fa83ec-2f20-4f4a-927d-3759000cceca",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-b3a71b4d-4a87-4192-b60a-29cfd86cd61b->13fa83ec-2f20-4f4a-927d-3759000cceca/Conexão comum",
        },
      ],
      viewport: {
        x: 565.5023972513993,
        y: 226.36452063048063,
        zoom: 0.6924912172305183,
      },
      id: "b260685a-dc40-4963-a01a-a8d8a98f1426",
      name: "Recepção",
      label: "Recepção",
    },
    {
      nodes: [
        {
          width: 180,
          height: 40,
          id: "bbf9c0f6-6183-47d4-9180-8469b7883aad",
          type: "start",
          position: { x: -140.02275752713317, y: -181.76878788146632 },
          data: { label: "start", id: "bbf9c0f6-6183-47d4-9180-8469b7883aad" },
          selected: false,
          positionAbsolute: { x: -140.02275752713317, y: -181.76878788146632 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "acf985d7-63ba-47cd-8208-6ee64947b281",
          type: "api",
          position: { x: 110.97009208581747, y: -207.92288632531222 },
          data: {
            label: "api",
            id: "acf985d7-63ba-47cd-8208-6ee64947b281",
            nodeInfo: {
              httpRequestType: "POST",
              httpRequestUrl:
                "https://api.movidesk.com/public/v1/tickets?token=8b538fc4-949f-4935-ab88-c5ec6ab6e768&returnAllProperties=false",
              requestBody:
                '{\r\n  "type": "1",\r\n  "createdBy": {\r\n    "id": "{{respostaChamadaAPIPersons}}",\r\n    "phone": "{{IDCliente}}"\r\n  },\r\n  "clients": [\r\n    {\r\n      "id": "{{respostaChamadaAPIPersons}}",\r\n      "profileType": 1,\r\n      "personType": 1\r\n    }\r\n  ],\r\n  "actions": [\r\n    {\r\n      "type": 1,\r\n      "description": "Descrever a ação"\r\n    }\r\n  ]\r\n\r\n}',
              requestHeaders: "Content-Type: application/json",
              responseBody: "",
              selectedVariable: {
                label: "respostaAbrirTicket",
                type: "texto",
                data: { label: "respostaAbrirTicket" },
              },
              spec: "protocol",
              flowId: "e8ad4996-20bc-4275-a73f-454fd85ba80d",
              description: "",
            },
          },
          selected: true,
          dragging: false,
          positionAbsolute: { x: 110.97009208581747, y: -207.92288632531222 },
        },
        {
          width: 218,
          height: 100,
          id: "a1e45417-dd59-4940-9ae8-4c7f6dcfee28",
          type: "text",
          position: { x: 829.2183737391083, y: 26.575138731488323 },
          data: {
            label: "text",
            id: "a1e45417-dd59-4940-9ae8-4c7f6dcfee28",
            nodeInfo: { inputValue: "Ok, muito obrigado pelo contato! " },
          },
          selected: false,
          positionAbsolute: { x: 829.2183737391083, y: 26.575138731488323 },
          dragging: false,
        },
        {
          width: 180,
          height: 40,
          id: "7afa460f-f964-45e6-9092-06847c8847a6",
          type: "end",
          position: { x: 1100.3978927507394, y: 58.28193222571588 },
          data: {
            label: "end",
            id: "7afa460f-f964-45e6-9092-06847c8847a6",
            nodeInfo: [],
          },
          selected: false,
          positionAbsolute: { x: 1100.3978927507394, y: 58.28193222571588 },
          dragging: false,
        },
        {
          width: 200,
          height: 63,
          id: "95405f2e-a106-4988-a647-d05d31c10f93",
          type: "change",
          position: { x: 839.2242681432871, y: -76.3483635680591 },
          data: {
            label: "change",
            id: "95405f2e-a106-4988-a647-d05d31c10f93",
            nodeInfo: {
              switchState: true,
              selectedNode: "307695a0-acdf-45df-8936-f60f2f24575e",
              flowId: "b260685a-dc40-4963-a01a-a8d8a98f1426",
            },
          },
          selected: false,
          positionAbsolute: { x: 839.2242681432871, y: -76.3483635680591 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "c800fd80-2054-4837-a551-4d7e440d3348",
          type: "text",
          position: { x: 415.0115228856882, y: 253.03271480775692 },
          data: {
            label: "text",
            id: "c800fd80-2054-4837-a551-4d7e440d3348",
            nodeInfo: { inputValue: "Por favor, selecione uma opção válida." },
          },
          selected: false,
          positionAbsolute: { x: 415.0115228856882, y: 253.03271480775692 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "6a1118ee-21cf-426f-9252-2c51ee4a5e8b",
          type: "text",
          position: { x: -132.38416296945837, y: 35.85533698838364 },
          data: {
            label: "text",
            id: "6a1118ee-21cf-426f-9252-2c51ee4a5e8b",
            nodeInfo: {
              inputValue:
                "Não foi possível abrir o ticket com as informações informadas. Por favor, informe um ID válido.",
            },
          },
          selected: false,
          positionAbsolute: { x: -132.38416296945837, y: 35.85533698838364 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "56076060-57de-4623-bd94-abe920132eec",
          type: "inputText",
          position: { x: 121.83878221801345, y: 39.98831101423079 },
          data: {
            label: "inputText",
            id: "56076060-57de-4623-bd94-abe920132eec",
            nodeInfo: [],
          },
          selected: false,
          positionAbsolute: { x: 121.83878221801345, y: 39.98831101423079 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "f60c380e-a403-4008-aafd-7b2e87e3b256",
          type: "text",
          position: { x: 372.2991114071647, y: -236.0858401797987 },
          data: {
            label: "text",
            id: "f60c380e-a403-4008-aafd-7b2e87e3b256",
            nodeInfo: {
              inputValue:
                "Olá, o ticket foi criado com sucesso!\n\nDeseja realizar mais alguma ação? \n\n1 - Sim\n2 - Não ",
            },
          },
          selected: false,
          positionAbsolute: { x: 372.2991114071647, y: -236.0858401797987 },
          dragging: false,
        },
        {
          width: 250,
          height: 291,
          id: "6de55873-0cb4-4211-a23e-b160a371905c",
          type: "condition",
          position: { x: 393.9698729845378, y: -84.27818477043026 },
          data: {
            label: "condition",
            id: "6de55873-0cb4-4211-a23e-b160a371905c",
            nodeInfo: {
              conditions: {
                0: [
                  {
                    variable: {
                      label: "respostaUsuario",
                      type: "texto",
                      data: { label: "respostaUsuario" },
                    },
                    operator: "==",
                    value: "1",
                  },
                ],
                1: [
                  {
                    variable: {
                      label: "respostaUsuario",
                      type: "texto",
                      data: { label: "respostaUsuario" },
                    },
                    operator: "==",
                    value: "2",
                  },
                ],
              },
            },
          },
          selected: false,
          positionAbsolute: { x: 393.9698729845378, y: -84.27818477043026 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "79ea25d1-d1e5-4c64-af73-5bf72a35596e",
          type: "inputText",
          position: { x: 721.0384379319364, y: -230.7428991019455 },
          data: {
            label: "inputText",
            id: "79ea25d1-d1e5-4c64-af73-5bf72a35596e",
            nodeInfo: [],
          },
          selected: false,
          positionAbsolute: { x: 721.0384379319364, y: -230.7428991019455 },
          dragging: false,
        },
      ],
      edges: [
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "bbf9c0f6-6183-47d4-9180-8469b7883aad",
          sourceHandle: "b",
          target: "acf985d7-63ba-47cd-8208-6ee64947b281",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-bbf9c0f6-6183-47d4-9180-8469b7883aad->acf985d7-63ba-47cd-8208-6ee64947b281/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "a1e45417-dd59-4940-9ae8-4c7f6dcfee28",
          sourceHandle: "b",
          target: "7afa460f-f964-45e6-9092-06847c8847a6",
          targetHandle: "b",
          tooltipText: "teste",
          type: "default",
          id: "b-a1e45417-dd59-4940-9ae8-4c7f6dcfee28->7afa460f-f964-45e6-9092-06847c8847a6/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "4affb99e-a53d-428d-bab6-ef5b26d52e5c",
          sourceHandle: "1",
          target: "a1e45417-dd59-4940-9ae8-4c7f6dcfee28",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "1-4affb99e-a53d-428d-bab6-ef5b26d52e5c->a1e45417-dd59-4940-9ae8-4c7f6dcfee28/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "4affb99e-a53d-428d-bab6-ef5b26d52e5c",
          sourceHandle: "0",
          target: "95405f2e-a106-4988-a647-d05d31c10f93",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "0-4affb99e-a53d-428d-bab6-ef5b26d52e5c->95405f2e-a106-4988-a647-d05d31c10f93/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "4affb99e-a53d-428d-bab6-ef5b26d52e5c",
          sourceHandle: "else",
          target: "c800fd80-2054-4837-a551-4d7e440d3348",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "else-4affb99e-a53d-428d-bab6-ef5b26d52e5c->c800fd80-2054-4837-a551-4d7e440d3348/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#a14e4e" },
          source: "acf985d7-63ba-47cd-8208-6ee64947b281",
          sourceHandle: "failure",
          target: "6a1118ee-21cf-426f-9252-2c51ee4a5e8b",
          targetHandle: "a",
          type: "failure",
          id: "failureacf985d7-63ba-47cd-8208-6ee64947b281->6a1118ee-21cf-426f-9252-2c51ee4a5e8b",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "6a1118ee-21cf-426f-9252-2c51ee4a5e8b",
          sourceHandle: "b",
          target: "56076060-57de-4623-bd94-abe920132eec",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-6a1118ee-21cf-426f-9252-2c51ee4a5e8b->56076060-57de-4623-bd94-abe920132eec/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "56076060-57de-4623-bd94-abe920132eec",
          sourceHandle: "b",
          target: "acf985d7-63ba-47cd-8208-6ee64947b281",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-56076060-57de-4623-bd94-abe920132eec->acf985d7-63ba-47cd-8208-6ee64947b281/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#4a8e4e" },
          source: "acf985d7-63ba-47cd-8208-6ee64947b281",
          sourceHandle: "success",
          target: "f60c380e-a403-4008-aafd-7b2e87e3b256",
          targetHandle: "a",
          tooltipText: "teste0",
          type: "success",
          id: "successacf985d7-63ba-47cd-8208-6ee64947b281->f60c380e-a403-4008-aafd-7b2e87e3b256",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "f60c380e-a403-4008-aafd-7b2e87e3b256",
          sourceHandle: "b",
          target: "79ea25d1-d1e5-4c64-af73-5bf72a35596e",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-f60c380e-a403-4008-aafd-7b2e87e3b256->79ea25d1-d1e5-4c64-af73-5bf72a35596e/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "79ea25d1-d1e5-4c64-af73-5bf72a35596e",
          sourceHandle: "b",
          target: "6de55873-0cb4-4211-a23e-b160a371905c",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-79ea25d1-d1e5-4c64-af73-5bf72a35596e->6de55873-0cb4-4211-a23e-b160a371905c/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "6de55873-0cb4-4211-a23e-b160a371905c",
          sourceHandle: "else",
          target: "c800fd80-2054-4837-a551-4d7e440d3348",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "else-6de55873-0cb4-4211-a23e-b160a371905c->c800fd80-2054-4837-a551-4d7e440d3348/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "c800fd80-2054-4837-a551-4d7e440d3348",
          sourceHandle: "b",
          target: "79ea25d1-d1e5-4c64-af73-5bf72a35596e",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-c800fd80-2054-4837-a551-4d7e440d3348->79ea25d1-d1e5-4c64-af73-5bf72a35596e/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "6de55873-0cb4-4211-a23e-b160a371905c",
          sourceHandle: "0",
          target: "95405f2e-a106-4988-a647-d05d31c10f93",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "0-6de55873-0cb4-4211-a23e-b160a371905c->95405f2e-a106-4988-a647-d05d31c10f93/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "6de55873-0cb4-4211-a23e-b160a371905c",
          sourceHandle: "1",
          target: "a1e45417-dd59-4940-9ae8-4c7f6dcfee28",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "1-6de55873-0cb4-4211-a23e-b160a371905c->a1e45417-dd59-4940-9ae8-4c7f6dcfee28/Conexão comum",
        },
      ],
      viewport: {
        x: 252.8533776113277,
        y: 346.9424724958284,
        zoom: 1.1981085895120296,
      },
      id: "e8ad4996-20bc-4275-a73f-454fd85ba80d",
      name: "criar ticket",
      label: "criar ticket",
    },
    {
      nodes: [
        {
          width: 180,
          height: 40,
          id: "76ce4228-f87c-4aeb-911e-eb3a70d610ea",
          type: "start",
          position: { x: -2158.5957453780384, y: -233.3488410758494 },
          data: { label: "start", id: "76ce4228-f87c-4aeb-911e-eb3a70d610ea" },
          selected: false,
          positionAbsolute: { x: -2158.5957453780384, y: -233.3488410758494 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "48e05d82-89df-4a06-a28b-c4e402b230ac",
          type: "api",
          position: { x: -524.3065656181095, y: -172.842933341343 },
          data: {
            label: "api",
            id: "48e05d82-89df-4a06-a28b-c4e402b230ac",
            nodeInfo: {
              httpRequestType: "PATCH",
              httpRequestUrl:
                "https://api.movidesk.com/public/v1/tickets?token=8b538fc4-949f-4935-ab88-c5ec6ab6e768&id=2",
              requestBody: '{\r\n  "subject": "Movidesk"\r\n}',
              requestHeaders: "Content-Type: application/json",
              responseBody: "",
              selectedVariable: {
                label: "textoAtualizacaoTicket",
                type: "texto",
                data: { label: "textoAtualizacaoTicket" },
              },
              spec: "",
              flowId: "4f795745-821d-4f5f-b830-fba0e82648b7",
              description: "",
            },
          },
          selected: true,
          positionAbsolute: { x: -524.3065656181095, y: -172.842933341343 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "7d97b9fd-9fa1-47bf-a5fe-4a99c21abc8b",
          type: "text",
          position: { x: -542.1289208461453, y: -29.64322219004822 },
          data: {
            label: "text",
            id: "7d97b9fd-9fa1-47bf-a5fe-4a99c21abc8b",
            nodeInfo: { inputValue: "deu ruim faz de novo " },
          },
          selected: false,
          positionAbsolute: { x: -542.1289208461453, y: -29.64322219004822 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "1ae5e70e-51a6-4238-9efd-9a082ead22f8",
          type: "inputText",
          position: { x: -517.2208191832433, y: 193.52444135995196 },
          data: {
            label: "inputText",
            id: "1ae5e70e-51a6-4238-9efd-9a082ead22f8",
            nodeInfo: [],
          },
          selected: false,
          positionAbsolute: { x: -517.2208191832433, y: 193.52444135995196 },
          dragging: false,
        },
        {
          width: 250,
          height: 334,
          id: "88420f81-d59a-4d30-8e01-134aaa6ff7b2",
          type: "choice",
          position: { x: -22.00951545672556, y: -379.0740114024096 },
          data: {
            label: "choice",
            id: "88420f81-d59a-4d30-8e01-134aaa6ff7b2",
            nodeInfo: {
              text: "Olá, o ticket foi atualizado com sucesso!\n\nDeseja realizar mais alguma ação? ",
              options: ["Sim", "Não"],
            },
          },
          selected: false,
          positionAbsolute: { x: -22.00951545672556, y: -379.0740114024096 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "5d8b51c9-94ec-4290-abfc-772f635d9bc2",
          type: "text",
          position: { x: 56.68820485768194, y: 104.40570942999338 },
          data: {
            label: "text",
            id: "5d8b51c9-94ec-4290-abfc-772f635d9bc2",
            nodeInfo: { inputValue: "Por favor informe uma opção válida." },
          },
          selected: false,
          positionAbsolute: { x: 56.68820485768194, y: 104.40570942999338 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "6ff05af2-b70c-40ae-a89d-c5295d6f306d",
          type: "text",
          position: { x: 372.17972210203845, y: -56.87436592201885 },
          data: {
            label: "text",
            id: "6ff05af2-b70c-40ae-a89d-c5295d6f306d",
            nodeInfo: { inputValue: "Ok, obrigado pelo contato!" },
          },
          selected: false,
          positionAbsolute: { x: 372.17972210203845, y: -56.87436592201885 },
          dragging: false,
        },
        {
          width: 180,
          height: 40,
          id: "262a3748-5391-4949-a1a9-6bd28e8e5f49",
          type: "end",
          position: { x: 382.6185438019561, y: 77.57098533309122 },
          data: {
            label: "end",
            id: "262a3748-5391-4949-a1a9-6bd28e8e5f49",
            nodeInfo: [],
          },
          selected: false,
          positionAbsolute: { x: 382.6185438019561, y: 77.57098533309122 },
          dragging: false,
        },
        {
          width: 200,
          height: 63,
          id: "bdb82e7b-13e6-4f2a-8749-2d950c6e7c1d",
          type: "change",
          position: { x: 394.6476376076876, y: -196.5945372795996 },
          data: {
            label: "change",
            id: "bdb82e7b-13e6-4f2a-8749-2d950c6e7c1d",
            nodeInfo: {
              switchState: true,
              selectedNode: "307695a0-acdf-45df-8936-f60f2f24575e",
              flowId: "b260685a-dc40-4963-a01a-a8d8a98f1426",
            },
          },
          selected: false,
          positionAbsolute: { x: 394.6476376076876, y: -196.5945372795996 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "0e5577b8-c12c-4cc1-b8f9-c01dfc932672",
          type: "jsNode",
          position: { x: -1545.4612289123097, y: -228.8826836608278 },
          data: {
            label: "jsNode",
            id: "0e5577b8-c12c-4cc1-b8f9-c01dfc932672",
            nodeInfo: {
              selectedVariable: {
                label: "todosTickets",
                type: "texto",
                data: { label: "todosTickets" },
              },
              jsCodeInput:
                "var selectedVar = todosTickets;\r\n\r\n  const list = selectedVar.map(item => `- ${item.id}`);\r\n    return list.join('\\n'); // Join array elements with newline character\r\n\r\n\r\n",
              description: "",
            },
          },
          selected: false,
          positionAbsolute: { x: -1545.4612289123097, y: -228.8826836608278 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "5afc762b-e9b7-4bf3-b2de-a7de46d92912",
          type: "api",
          position: { x: -1841.597365313454, y: -228.40805948215703 },
          data: {
            label: "api",
            id: "5afc762b-e9b7-4bf3-b2de-a7de46d92912",
            nodeInfo: {
              httpRequestType: "GET",
              httpRequestUrl:
                "https://api.movidesk.com/public/v1/tickets?token=8b538fc4-949f-4935-ab88-c5ec6ab6e768&$filter=clients/any(client: client/id eq '241179668')&$select=id&expand=clients",
              requestBody: "",
              requestHeaders: "Content-Type: application/json",
              responseBody: "",
              selectedVariable: {
                label: "todosTickets",
                type: "texto",
                data: { label: "todosTickets" },
              },
              spec: "",
              flowId: "4f795745-821d-4f5f-b830-fba0e82648b7",
              description: "",
            },
          },
          selected: false,
          positionAbsolute: { x: -1841.597365313454, y: -228.40805948215703 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "359c875a-edf6-466f-98ee-2172bec2bf9e",
          type: "text",
          position: { x: -1936.860004318709, y: -70.32452653621395 },
          data: {
            label: "text",
            id: "359c875a-edf6-466f-98ee-2172bec2bf9e",
            nodeInfo: { inputValue: "deu ruim\n" },
          },
          selected: false,
          dragging: false,
          positionAbsolute: { x: -1936.860004318709, y: -70.32452653621395 },
        },
        {
          width: 200,
          height: 80,
          id: "fbd8378f-8dc2-466f-aa13-620893b4f2a3",
          type: "inputText",
          position: { x: -1666.1480782186302, y: -66.06703594450447 },
          data: {
            label: "inputText",
            id: "fbd8378f-8dc2-466f-aa13-620893b4f2a3",
            nodeInfo: [],
          },
          selected: false,
          dragging: false,
          positionAbsolute: { x: -1666.1480782186302, y: -66.06703594450447 },
        },
        {
          width: 218,
          height: 100,
          id: "67a3b94c-6a86-46a0-a494-27d5aef2518c",
          type: "text",
          position: { x: -1250.6932255624524, y: -242.90100321170047 },
          data: {
            label: "text",
            id: "67a3b94c-6a86-46a0-a494-27d5aef2518c",
            nodeInfo: {
              inputValue:
                " Escolha o número do ticket dentre os seus tickets abertos: \n{{todosTickets}}\n",
            },
          },
          selected: false,
          positionAbsolute: { x: -1250.6932255624524, y: -242.90100321170047 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "f9d6144c-c73d-4a56-8492-f2c30e4a4334",
          type: "inputText",
          position: { x: -1247.942935747759, y: 1.2536361055642828 },
          data: {
            label: "inputText",
            id: "f9d6144c-c73d-4a56-8492-f2c30e4a4334",
            nodeInfo: [],
          },
          selected: false,
          positionAbsolute: { x: -1247.942935747759, y: 1.2536361055642828 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "3556b195-216c-43cc-a8dd-73d79920b5c3",
          type: "text",
          position: { x: -1246.1669845713661, y: -119.51104388916468 },
          data: {
            label: "text",
            id: "3556b195-216c-43cc-a8dd-73d79920b5c3",
            nodeInfo: { inputValue: "" },
          },
          selected: false,
          positionAbsolute: { x: -1246.1669845713661, y: -119.51104388916468 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "9783898f-eb17-4ad4-9eba-cf187b58496f",
          type: "text",
          position: { x: -980.6622837006015, y: -255.3713088832348 },
          data: {
            label: "text",
            id: "9783898f-eb17-4ad4-9eba-cf187b58496f",
            nodeInfo: {
              inputValue:
                "Ok, você escolheu o ticket de id n° {{respostaUsuario}}. \nNa próxima mensagem, por favor, informe qual o texto que será adicionado ao ticket.",
            },
          },
          selected: false,
          positionAbsolute: { x: -980.6622837006015, y: -255.3713088832348 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "5816c83b-0d67-4956-925c-3dbbcfae8558",
          type: "text",
          position: { x: -967.3426498776535, y: -126.61484859473694 },
          data: {
            label: "text",
            id: "5816c83b-0d67-4956-925c-3dbbcfae8558",
            nodeInfo: { inputValue: "" },
          },
          selected: false,
          positionAbsolute: { x: -967.3426498776535, y: -126.61484859473694 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "4a5b9daf-9525-4074-a416-c6f3ecb823d4",
          type: "inputText",
          position: { x: -970.8945522304397, y: -1.4102906590252928 },
          data: {
            label: "inputText",
            id: "4a5b9daf-9525-4074-a416-c6f3ecb823d4",
            nodeInfo: [],
          },
          selected: false,
          positionAbsolute: { x: -970.8945522304397, y: -1.4102906590252928 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "77d1987a-9db6-4340-a49b-e995c0424117",
          type: "text",
          position: { x: -995.6745738155054, y: 99.69210224122455 },
          data: {
            label: "text",
            id: "77d1987a-9db6-4340-a49b-e995c0424117",
            nodeInfo: { inputValue: "" },
          },
          selected: false,
          positionAbsolute: { x: -995.6745738155054, y: 99.69210224122455 },
          dragging: false,
        },
      ],
      edges: [
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#a14e4e" },
          source: "48e05d82-89df-4a06-a28b-c4e402b230ac",
          sourceHandle: "failure",
          target: "7d97b9fd-9fa1-47bf-a5fe-4a99c21abc8b",
          targetHandle: "a",
          type: "failure",
          id: "failure48e05d82-89df-4a06-a28b-c4e402b230ac->7d97b9fd-9fa1-47bf-a5fe-4a99c21abc8b",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "7d97b9fd-9fa1-47bf-a5fe-4a99c21abc8b",
          sourceHandle: "b",
          target: "1ae5e70e-51a6-4238-9efd-9a082ead22f8",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-7d97b9fd-9fa1-47bf-a5fe-4a99c21abc8b->1ae5e70e-51a6-4238-9efd-9a082ead22f8/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#4a8e4e" },
          source: "48e05d82-89df-4a06-a28b-c4e402b230ac",
          sourceHandle: "success",
          target: "88420f81-d59a-4d30-8e01-134aaa6ff7b2",
          targetHandle: "a",
          tooltipText: "teste0",
          type: "success",
          id: "success48e05d82-89df-4a06-a28b-c4e402b230ac->88420f81-d59a-4d30-8e01-134aaa6ff7b2",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "88420f81-d59a-4d30-8e01-134aaa6ff7b2",
          sourceHandle: "else",
          target: "5d8b51c9-94ec-4290-abfc-772f635d9bc2",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "else-88420f81-d59a-4d30-8e01-134aaa6ff7b2->5d8b51c9-94ec-4290-abfc-772f635d9bc2/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "5d8b51c9-94ec-4290-abfc-772f635d9bc2",
          sourceHandle: "b",
          target: "88420f81-d59a-4d30-8e01-134aaa6ff7b2",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-5d8b51c9-94ec-4290-abfc-772f635d9bc2->88420f81-d59a-4d30-8e01-134aaa6ff7b2/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "88420f81-d59a-4d30-8e01-134aaa6ff7b2",
          sourceHandle: "1",
          target: "6ff05af2-b70c-40ae-a89d-c5295d6f306d",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "1-88420f81-d59a-4d30-8e01-134aaa6ff7b2->6ff05af2-b70c-40ae-a89d-c5295d6f306d/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "6ff05af2-b70c-40ae-a89d-c5295d6f306d",
          sourceHandle: "b",
          target: "262a3748-5391-4949-a1a9-6bd28e8e5f49",
          targetHandle: "b",
          tooltipText: "teste",
          type: "default",
          id: "b-6ff05af2-b70c-40ae-a89d-c5295d6f306d->262a3748-5391-4949-a1a9-6bd28e8e5f49/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "88420f81-d59a-4d30-8e01-134aaa6ff7b2",
          sourceHandle: "0",
          target: "bdb82e7b-13e6-4f2a-8749-2d950c6e7c1d",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "0-88420f81-d59a-4d30-8e01-134aaa6ff7b2->bdb82e7b-13e6-4f2a-8749-2d950c6e7c1d/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#4a8e4e" },
          source: "5afc762b-e9b7-4bf3-b2de-a7de46d92912",
          sourceHandle: "success",
          target: "0e5577b8-c12c-4cc1-b8f9-c01dfc932672",
          targetHandle: "a",
          tooltipText: "teste0",
          type: "success",
          id: "success5afc762b-e9b7-4bf3-b2de-a7de46d92912->0e5577b8-c12c-4cc1-b8f9-c01dfc932672",
          selected: false,
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "359c875a-edf6-466f-98ee-2172bec2bf9e",
          sourceHandle: "b",
          target: "fbd8378f-8dc2-466f-aa13-620893b4f2a3",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-359c875a-edf6-466f-98ee-2172bec2bf9e->fbd8378f-8dc2-466f-aa13-620893b4f2a3/Conexão comum",
          selected: false,
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "fbd8378f-8dc2-466f-aa13-620893b4f2a3",
          sourceHandle: "b",
          target: "5afc762b-e9b7-4bf3-b2de-a7de46d92912",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-fbd8378f-8dc2-466f-aa13-620893b4f2a3->5afc762b-e9b7-4bf3-b2de-a7de46d92912/Conexão comum",
          selected: false,
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "0e5577b8-c12c-4cc1-b8f9-c01dfc932672",
          sourceHandle: "b",
          target: "67a3b94c-6a86-46a0-a494-27d5aef2518c",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-0e5577b8-c12c-4cc1-b8f9-c01dfc932672->67a3b94c-6a86-46a0-a494-27d5aef2518c/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "3f0d54ec-8f82-4a91-a6bc-3dc75161df64",
          sourceHandle: "b",
          target: "5afc762b-e9b7-4bf3-b2de-a7de46d92912",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-3f0d54ec-8f82-4a91-a6bc-3dc75161df64->5afc762b-e9b7-4bf3-b2de-a7de46d92912/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "76ce4228-f87c-4aeb-911e-eb3a70d610ea",
          sourceHandle: "b",
          target: "5afc762b-e9b7-4bf3-b2de-a7de46d92912",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-76ce4228-f87c-4aeb-911e-eb3a70d610ea->5afc762b-e9b7-4bf3-b2de-a7de46d92912/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#a14e4e" },
          source: "5afc762b-e9b7-4bf3-b2de-a7de46d92912",
          sourceHandle: "failure",
          target: "359c875a-edf6-466f-98ee-2172bec2bf9e",
          targetHandle: "a",
          type: "failure",
          id: "failure5afc762b-e9b7-4bf3-b2de-a7de46d92912->359c875a-edf6-466f-98ee-2172bec2bf9e",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "ba070ea1-9183-4dae-9080-018d97f66390",
          sourceHandle: "b",
          target: "f9d6144c-c73d-4a56-8492-f2c30e4a4334",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-ba070ea1-9183-4dae-9080-018d97f66390->f9d6144c-c73d-4a56-8492-f2c30e4a4334/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "67a3b94c-6a86-46a0-a494-27d5aef2518c",
          sourceHandle: "b",
          target: "3556b195-216c-43cc-a8dd-73d79920b5c3",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-67a3b94c-6a86-46a0-a494-27d5aef2518c->3556b195-216c-43cc-a8dd-73d79920b5c3/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "3556b195-216c-43cc-a8dd-73d79920b5c3",
          sourceHandle: "b",
          target: "f9d6144c-c73d-4a56-8492-f2c30e4a4334",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-3556b195-216c-43cc-a8dd-73d79920b5c3->f9d6144c-c73d-4a56-8492-f2c30e4a4334/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "f9d6144c-c73d-4a56-8492-f2c30e4a4334",
          sourceHandle: "b",
          target: "9783898f-eb17-4ad4-9eba-cf187b58496f",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-f9d6144c-c73d-4a56-8492-f2c30e4a4334->9783898f-eb17-4ad4-9eba-cf187b58496f/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "9783898f-eb17-4ad4-9eba-cf187b58496f",
          sourceHandle: "b",
          target: "5816c83b-0d67-4956-925c-3dbbcfae8558",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-9783898f-eb17-4ad4-9eba-cf187b58496f->5816c83b-0d67-4956-925c-3dbbcfae8558/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "5816c83b-0d67-4956-925c-3dbbcfae8558",
          sourceHandle: "b",
          target: "4a5b9daf-9525-4074-a416-c6f3ecb823d4",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-5816c83b-0d67-4956-925c-3dbbcfae8558->4a5b9daf-9525-4074-a416-c6f3ecb823d4/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "4a5b9daf-9525-4074-a416-c6f3ecb823d4",
          sourceHandle: "b",
          target: "77d1987a-9db6-4340-a49b-e995c0424117",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-4a5b9daf-9525-4074-a416-c6f3ecb823d4->77d1987a-9db6-4340-a49b-e995c0424117/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "77d1987a-9db6-4340-a49b-e995c0424117",
          sourceHandle: "b",
          target: "48e05d82-89df-4a06-a28b-c4e402b230ac",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-77d1987a-9db6-4340-a49b-e995c0424117->48e05d82-89df-4a06-a28b-c4e402b230ac/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "1ae5e70e-51a6-4238-9efd-9a082ead22f8",
          sourceHandle: "b",
          target: "77d1987a-9db6-4340-a49b-e995c0424117",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-1ae5e70e-51a6-4238-9efd-9a082ead22f8->77d1987a-9db6-4340-a49b-e995c0424117/Conexão comum",
        },
      ],
      viewport: { x: 1236.9870269425878, y: 442.3873925106144, zoom: 0.5 },
      id: "4f795745-821d-4f5f-b830-fba0e82648b7",
      name: "Atualizar ticket",
      label: "Atualizar ticket",
    },
    {
      nodes: [
        {
          width: 180,
          height: 40,
          id: "349931d7-0a90-41b1-ab82-906a01493a38",
          type: "start",
          position: { x: -500.5259891341427, y: -162.3592811146419 },
          data: { label: "start", id: "349931d7-0a90-41b1-ab82-906a01493a38" },
          selected: false,
          positionAbsolute: { x: -500.5259891341427, y: -162.3592811146419 },
          dragging: false,
        },
        {
          width: 200,
          height: 80,
          id: "3444dfaa-ccd4-4e2e-a8fe-ae3259fa1559",
          type: "api",
          position: { x: -209.11984357959992, y: 16.12916994963652 },
          data: {
            label: "api",
            id: "3444dfaa-ccd4-4e2e-a8fe-ae3259fa1559",
            nodeInfo: {
              httpRequestType: "PATCH",
              httpRequestUrl:
                "https://api.movidesk.com/public/v1/tickets?token=8b538fc4-949f-4935-ab88-c5ec6ab6e768&id={{respostaUsuario}}",
              requestBody: '{\r\n  "baseStatus": "Resolved"\r\n}',
              requestHeaders: "",
              responseBody: "",
              selectedVariable: {
                label: "todosTickets",
                type: "texto",
                data: { label: "todosTickets" },
              },
              spec: "",
              flowId: "8ff99297-fe79-4f38-96d8-f1abdc88678c",
              description: "",
            },
          },
          selected: true,
          dragging: false,
          positionAbsolute: { x: -209.11984357959992, y: 16.12916994963652 },
        },
        {
          width: 200,
          height: 80,
          id: "b7ae608f-ff11-401c-8e3c-623e8a917f0f",
          type: "inputText",
          position: { x: -551.2533716060886, y: 192.90268341779878 },
          data: {
            label: "inputText",
            id: "b7ae608f-ff11-401c-8e3c-623e8a917f0f",
            nodeInfo: [],
          },
          selected: false,
          positionAbsolute: { x: -551.2533716060886, y: 192.90268341779878 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "2f138675-67ab-4863-9196-1ef8e4b435b3",
          type: "text",
          position: { x: -547.4914879020179, y: 9.320244832681169 },
          data: {
            label: "text",
            id: "2f138675-67ab-4863-9196-1ef8e4b435b3",
            nodeInfo: {
              inputValue: "Qual o ID do ticket que você deseja finalizar?",
            },
          },
          selected: false,
          positionAbsolute: { x: -547.4914879020179, y: 9.320244832681169 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "91224efb-a4f0-44e7-b5af-8336e030ce78",
          type: "text",
          position: { x: -227.46880818015626, y: 178.61685489424838 },
          data: {
            label: "text",
            id: "91224efb-a4f0-44e7-b5af-8336e030ce78",
            nodeInfo: {
              inputValue:
                "Não foi possível atualizar um ticket com este ID. \nPor favor, informe um ID válido.",
            },
          },
          selected: false,
          positionAbsolute: { x: -227.46880818015626, y: 178.61685489424838 },
          dragging: false,
        },
        {
          width: 250,
          height: 334,
          id: "47cc3b3c-2140-4ced-8516-0ea503df4ab4",
          type: "choice",
          position: { x: 76.39860472623845, y: -179.50293228899864 },
          data: {
            label: "choice",
            id: "47cc3b3c-2140-4ced-8516-0ea503df4ab4",
            nodeInfo: {
              text: "Olá, o ticket foi finalizado com sucesso!\n\nDeseja realizar mais alguma ação? ",
              options: ["Sim", "Não"],
            },
          },
          selected: false,
          positionAbsolute: { x: 76.39860472623845, y: -179.50293228899864 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "e6d1ec87-5063-4254-8133-fbb05aacf5cb",
          type: "text",
          position: { x: 92.89860472623855, y: 195.4970677110014 },
          data: {
            label: "text",
            id: "e6d1ec87-5063-4254-8133-fbb05aacf5cb",
            nodeInfo: { inputValue: "Por favor, informe um valor válido." },
          },
          selected: false,
          positionAbsolute: { x: 92.89860472623855, y: 195.4970677110014 },
          dragging: false,
        },
        {
          width: 218,
          height: 100,
          id: "16c5ce1c-723d-4ddd-9cb8-622b94b63d0d",
          type: "text",
          position: { x: 390.8986047262386, y: 77.49706771100134 },
          data: {
            label: "text",
            id: "16c5ce1c-723d-4ddd-9cb8-622b94b63d0d",
            nodeInfo: { inputValue: "Ok, muito obrigado pelo contato!" },
          },
          selected: false,
          positionAbsolute: { x: 390.8986047262386, y: 77.49706771100134 },
          dragging: false,
        },
        {
          width: 180,
          height: 40,
          id: "975893d6-1e3f-47c8-86d2-648a72b415a6",
          type: "end",
          position: { x: 670.1171509574733, y: 110.09446818443104 },
          data: {
            label: "end",
            id: "975893d6-1e3f-47c8-86d2-648a72b415a6",
            nodeInfo: [],
          },
          selected: false,
          positionAbsolute: { x: 670.1171509574733, y: 110.09446818443104 },
          dragging: false,
        },
        {
          width: 200,
          height: 63,
          id: "1fa8c737-e24d-4998-a736-6cb68186e41a",
          type: "change",
          position: { x: 565.5327078682556, y: -52.087204432181636 },
          data: {
            label: "change",
            id: "1fa8c737-e24d-4998-a736-6cb68186e41a",
            nodeInfo: {
              switchState: true,
              selectedNode: "307695a0-acdf-45df-8936-f60f2f24575e",
              flowId: "b260685a-dc40-4963-a01a-a8d8a98f1426",
            },
          },
          selected: false,
          positionAbsolute: { x: 565.5327078682556, y: -52.087204432181636 },
          dragging: false,
        },
      ],
      edges: [
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "349931d7-0a90-41b1-ab82-906a01493a38",
          sourceHandle: "b",
          target: "2f138675-67ab-4863-9196-1ef8e4b435b3",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-349931d7-0a90-41b1-ab82-906a01493a38->2f138675-67ab-4863-9196-1ef8e4b435b3/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "2f138675-67ab-4863-9196-1ef8e4b435b3",
          sourceHandle: "b",
          target: "b7ae608f-ff11-401c-8e3c-623e8a917f0f",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-2f138675-67ab-4863-9196-1ef8e4b435b3->b7ae608f-ff11-401c-8e3c-623e8a917f0f/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "b7ae608f-ff11-401c-8e3c-623e8a917f0f",
          sourceHandle: "b",
          target: "3444dfaa-ccd4-4e2e-a8fe-ae3259fa1559",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-b7ae608f-ff11-401c-8e3c-623e8a917f0f->3444dfaa-ccd4-4e2e-a8fe-ae3259fa1559/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#a14e4e" },
          source: "3444dfaa-ccd4-4e2e-a8fe-ae3259fa1559",
          sourceHandle: "failure",
          target: "91224efb-a4f0-44e7-b5af-8336e030ce78",
          targetHandle: "a",
          type: "failure",
          id: "failure3444dfaa-ccd4-4e2e-a8fe-ae3259fa1559->91224efb-a4f0-44e7-b5af-8336e030ce78",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "91224efb-a4f0-44e7-b5af-8336e030ce78",
          sourceHandle: "b",
          target: "b7ae608f-ff11-401c-8e3c-623e8a917f0f",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-91224efb-a4f0-44e7-b5af-8336e030ce78->b7ae608f-ff11-401c-8e3c-623e8a917f0f/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#4a8e4e" },
          source: "3444dfaa-ccd4-4e2e-a8fe-ae3259fa1559",
          sourceHandle: "success",
          target: "47cc3b3c-2140-4ced-8516-0ea503df4ab4",
          targetHandle: "a",
          tooltipText: "teste0",
          type: "success",
          id: "success3444dfaa-ccd4-4e2e-a8fe-ae3259fa1559->47cc3b3c-2140-4ced-8516-0ea503df4ab4",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "47cc3b3c-2140-4ced-8516-0ea503df4ab4",
          sourceHandle: "else",
          target: "e6d1ec87-5063-4254-8133-fbb05aacf5cb",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "else-47cc3b3c-2140-4ced-8516-0ea503df4ab4->e6d1ec87-5063-4254-8133-fbb05aacf5cb/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "e6d1ec87-5063-4254-8133-fbb05aacf5cb",
          sourceHandle: "b",
          target: "47cc3b3c-2140-4ced-8516-0ea503df4ab4",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "b-e6d1ec87-5063-4254-8133-fbb05aacf5cb->47cc3b3c-2140-4ced-8516-0ea503df4ab4/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "47cc3b3c-2140-4ced-8516-0ea503df4ab4",
          sourceHandle: "1",
          target: "16c5ce1c-723d-4ddd-9cb8-622b94b63d0d",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "1-47cc3b3c-2140-4ced-8516-0ea503df4ab4->16c5ce1c-723d-4ddd-9cb8-622b94b63d0d/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "16c5ce1c-723d-4ddd-9cb8-622b94b63d0d",
          sourceHandle: "b",
          target: "975893d6-1e3f-47c8-86d2-648a72b415a6",
          targetHandle: "b",
          tooltipText: "teste",
          type: "default",
          id: "b-16c5ce1c-723d-4ddd-9cb8-622b94b63d0d->975893d6-1e3f-47c8-86d2-648a72b415a6/Conexão comum",
        },
        {
          style: { strokeWidth: 3, stroke: "black" },
          markerEnd: { type: "arrowclosed", color: "#5b5bf7" },
          source: "47cc3b3c-2140-4ced-8516-0ea503df4ab4",
          sourceHandle: "0",
          target: "1fa8c737-e24d-4998-a736-6cb68186e41a",
          targetHandle: "a",
          tooltipText: "teste",
          type: "default",
          id: "0-47cc3b3c-2140-4ced-8516-0ea503df4ab4->1fa8c737-e24d-4998-a736-6cb68186e41a/Conexão comum",
        },
      ],
      viewport: {
        x: 892.6076670588552,
        y: 344.1220206822147,
        zoom: 0.7000875071804148,
      },
      id: "8ff99297-fe79-4f38-96d8-f1abdc88678c",
      name: "Finalizar ticket",
      label: "Finalizar ticket",
    },
  ],
  variables: [
    { label: "IDUsuarioLogado", type: "texto", data: { label: "1" } },
    {
      label: "PrimeiraMensagem",
      type: "texto",
      data: { label: "PrimeiraMensagem" },
    },
    { label: "IDCliente", type: "texto", data: { label: "IDCliente" } },
    {
      label: "respostaAbrirTicket",
      type: "texto",
      data: { label: "respostaAbrirTicket" },
    },
    {
      label: "respostaUsuario",
      type: "texto",
      data: { label: "respostaUsuario" },
    },
    {
      label: "respostaChamadaAPIPersons",
      type: "texto",
      data: { label: "respostaChamadaAPIPersons" },
    },
    { label: "todosTickets", type: "texto", data: { label: "todosTickets" } },
    {
      label: "textoAtualizacaoTicket",
      type: "texto",
      data: { label: "textoAtualizacaoTicket" },
    },
  ],
  usedVariables: [
    { nodeId: "690f0ec6-fe88-4544-88ed-1c2a129a4eeb", variableId: "IDCliente" },
    { nodeId: "28d9febd-4fbe-47a8-97bb-f52aff363cda", variableId: "IDCliente" },
    { nodeId: "b0ddc3c4-bc3f-4780-8d2d-ff8cc765dc06", variableId: "IDCliente" },
    { nodeId: "56076060-57de-4623-bd94-abe920132eec", variableId: "IDCliente" },
    {
      nodeId: "acf985d7-63ba-47cd-8208-6ee64947b281",
      variableId: "respostaAbrirTicket",
      variable: {
        label: "respostaAbrirTicket",
        type: "texto",
        data: { label: "respostaAbrirTicket" },
      },
    },
    {
      variableId: "respostaAbrirTicket",
      nodeId: "f60c380e-a403-4008-aafd-7b2e87e3b256",
    },
    {
      label: "respostaAbrirTicket",
      nodeId: "f60c380e-a403-4008-aafd-7b2e87e3b256",
    },
    {
      nodeId: "79ea25d1-d1e5-4c64-af73-5bf72a35596e",
      variableId: "respostaUsuario",
    },
    {
      nodeId: "6de55873-0cb4-4211-a23e-b160a371905c",
      variableId: "respostaUsuario",
      conditionIndex: 0,
      index: "0",
    },
    {
      nodeId: "6de55873-0cb4-4211-a23e-b160a371905c",
      variableId: "respostaUsuario",
      conditionIndex: 0,
      index: "1",
    },
    {
      nodeId: "72585c61-1957-4896-b75c-2629357f79b1",
      variableId: "respostaChamadaAPIPersons",
      variable: {
        label: "respostaChamadaAPIPersons",
        type: "texto",
        data: { label: "respostaChamadaAPIPersons" },
      },
    },
    {
      variableId: "respostaChamadaAPIPersons",
      nodeId: "f60c380e-a403-4008-aafd-7b2e87e3b256",
    },
    {
      label: "respostaChamadaAPIPersons",
      nodeId: "f60c380e-a403-4008-aafd-7b2e87e3b256",
    },
    { nodeId: "cf648761-f0e0-449b-a4cd-1a934f1370ae", variableId: "IDCliente" },
    {
      nodeId: "b7ae608f-ff11-401c-8e3c-623e8a917f0f",
      variableId: "respostaUsuario",
    },
    {
      nodeId: "44ac10ec-29c8-4e54-bf52-0eee36f2438a",
      variableId: "respostaChamadaAPIPersons",
    },
    {
      nodeId: "1ae5e70e-51a6-4238-9efd-9a082ead22f8",
      variableId: "respostaChamadaAPIPersons",
    },
    {
      nodeId: "fbd8378f-8dc2-466f-aa13-620893b4f2a3",
      variableId: "respostaChamadaAPIPersons",
    },
    {
      nodeId: "5afc762b-e9b7-4bf3-b2de-a7de46d92912",
      variableId: "todosTickets",
      variable: {
        label: "todosTickets",
        type: "texto",
        data: { label: "todosTickets" },
      },
    },
    {
      variableId: "todosTickets",
      nodeId: "67a3b94c-6a86-46a0-a494-27d5aef2518c",
    },
    { nodeId: "b3ca0624-4109-4297-b234-983e5a78a07e", variableId: "IDCliente" },
    {
      nodeId: "851b1eab-a224-4b81-9fea-d371968db265",
      variableId: "respostaChamadaAPIPersons",
      variable: {
        label: "respostaChamadaAPIPersons",
        type: "texto",
        data: { label: "respostaChamadaAPIPersons" },
      },
    },
    {
      nodeId: "0e5577b8-c12c-4cc1-b8f9-c01dfc932672",
      variableId: "todosTickets",
    },
    { label: "todosTickets", nodeId: "67a3b94c-6a86-46a0-a494-27d5aef2518c" },
    {
      nodeId: "f9d6144c-c73d-4a56-8492-f2c30e4a4334",
      variableId: "respostaUsuario",
    },
    {
      variableId: "respostaUsuario",
      nodeId: "9783898f-eb17-4ad4-9eba-cf187b58496f",
    },
    {
      label: "respostaUsuario",
      nodeId: "9783898f-eb17-4ad4-9eba-cf187b58496f",
    },
    {
      nodeId: "4a5b9daf-9525-4074-a416-c6f3ecb823d4",
      variableId: "textoAtualizacaoTicket",
    },
    {
      nodeId: "48e05d82-89df-4a06-a28b-c4e402b230ac",
      variableId: "textoAtualizacaoTicket",
      variable: {
        label: "textoAtualizacaoTicket",
        type: "texto",
        data: { label: "textoAtualizacaoTicket" },
      },
    },
    { nodeId: "1dba00e4-791b-449f-a270-e28edd7093a4", variableId: "IDCliente" },
    {
      nodeId: "bf1f094d-f06b-48ab-99fe-0d8c5d3d58b4",
      variableId: "respostaChamadaAPIPersons",
      variable: {
        label: "respostaChamadaAPIPersons",
        type: "texto",
        data: { label: "respostaChamadaAPIPersons" },
      },
    },
    {
      nodeId: "b3a71b4d-4a87-4192-b60a-29cfd86cd61b",
      variableId: "respostaChamadaAPIPersons",
    },
    {
      nodeId: "1207bcd8-5b2a-44c7-9dd2-860833745188",
      variableId: "respostaChamadaAPIPersons",
      variable: {
        label: "respostaChamadaAPIPersons",
        type: "texto",
        data: { label: "respostaChamadaAPIPersons" },
      },
    },
    {
      nodeId: "3444dfaa-ccd4-4e2e-a8fe-ae3259fa1559",
      variableId: "todosTickets",
      variable: {
        label: "todosTickets",
        type: "texto",
        data: { label: "todosTickets" },
      },
    },
  ],
};
