import { useCallback, useState, useContext, useEffect } from "react";
import { Handle, Position } from "reactflow";
import Box from "@mui/material/Box";
import ListIcon from "@mui/icons-material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Popover from "@mui/material/Popover";
import { TextField } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

function ChoiceNode({ data, isConnectable }) {
  const [text, setText] = useState(data.nodeInfo.text ?? "");
  const [optionsText, setOptionsText] = useState();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(data.nodeInfo.options ?? []);
  const [editOptionFlag, setEditOptionFlag] = useState(false);
  const [editText, setEditText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  useEffect(() => {
    // save inputvalue to localstorage whenever it changes inside the node with the same id
    const prevFlows = JSON.parse(localStorage.getItem("flows"));
    prevFlows.forEach((flow) => {
      const node = flow.nodes.find((node) => node.id === data.id);
      if (node) {
        const object = {
          text: text,
          options: options,
        };

        data.nodeInfo = object;

        localStorage.setItem("flows", JSON.stringify(prevFlows));
      }
    });
  }, [text, options]);

  useEffect(() => {
    if (options.length > 0) {
      const optionsInTextList = options.map((option, index) => {
        return `${index + 1} - ${option}`;
      });
      setOptionsText(optionsInTextList.join("\n"));
    } else {
      setOptionsText("");
    }
  }, [options]);

  const TextToShow = () => {
    return (
      <Box
        style={{
          display: "flex",
          height: "100px",
          background: "#1f1f23",
          color: "white",
          fontSize: "0.7rem",
          padding: "10px",
          overflow: "auto",
          whiteSpace: "pre-wrap", // Allow wrapping of text
        }}
        onClick={() => {
          setOpen(true);
        }}
        key={data.id}
      >
        {text || optionsText
          ? text + "\n\n" + optionsText
          : "Clique para adicionar texto"}
      </Box>
    );
  };

  const handleEditOptionRow = () => {
    setEditOptionFlag(true);
  };

  const saveEditOptionRow = (index) => {
    const modifiedOptions = [...options];
    modifiedOptions[index] = editText;
    setOptions(modifiedOptions);
  };

  const handleDeleteOptionRow = (index) => {
    const modifiedOptions = [...options];
    modifiedOptions.splice(index, 1);
    setOptions(modifiedOptions);
  };

  return (
    <div className="customNodeBodyConditional">
      <Box sx={{ "& > :not(style)": { m: 1 } }}>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "fitContent",
          }}
        >
          <Box
            sx={{
              backgroundColor: "black",
              color: "white",
              padding: "20px",
              borderRadius: "8px",
              width: "40vw",
            }}
          >
            <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
              Digite o texto que será enviado
              <Tooltip
                title="A lista de opções será exibida após o texto automaticamente de acordo com as opções criadas."
                placement="right"
                style={{
                  marginLeft: "10px",
                }}
              >
                <InfoIcon />
              </Tooltip>
            </h2>
            <FormControl fullWidth>
              <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                style={{
                  width: "100%",
                  height: "200px",
                  background: "black",
                  color: "white",
                  border: "1px solid #555",
                  borderRadius: "8px",
                  padding: "10px",
                }}
              />
            </FormControl>
          </Box>
        </Modal>
        <Card>
          <CardHeader
            avatar={<ListIcon />}
            title="Escolher dentre opções"
            style={{
              background: "#1f1f23",
              color: "white",
            }}
          />
          <CardMedia component={TextToShow} />
          <CardContent
            style={{
              background: "#1f1f23",
              color: "white",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {options.map((option, index) => {
              return (
                <div key={index}>
                  <div
                    key={index}
                    style={{
                      top: `${188 + (index * 10 + 10) + index * 19}px`,
                    }}
                  >
                    <Popover
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={() => {
                        setEditOptionFlag(false);
                        setAnchorEl(null);
                      }}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      sx={{
                        backgroundColor: "transparent",
                        color: "transparent",
                        background: "transparent",
                      }}
                      style={{
                        backgroundColor: "transparent",
                        color: "transparent",
                        background: "transparent",
                      }}
                    >
                      {editOptionFlag ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "0.7rem",
                            alignItems: "center",
                            background: "#1f1f23",
                            color: "white",
                          }}
                        >
                          <TextField
                            InputProps={{
                              style: {
                                color: "white",
                              },
                            }}
                            onChange={(e) => {
                              setEditText(e.target.value);
                            }}
                            defaultValue={option}
                          />
                          <Button
                            onClick={() => {
                              saveEditOptionRow(currentIndex);
                              setAnchorEl(null);
                              setEditOptionFlag(false);
                            }}
                            style={{
                              background: "#1f1f23",
                              color: "white",
                            }}
                          >
                            Salvar
                          </Button>
                        </div>
                      ) : (
                        <>
                          <Button
                            onClick={() => {
                              handleEditOptionRow();
                            }}
                            style={{
                              background: "#1f1f23",
                              color: "white",
                            }}
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            onClick={() => {
                              handleDeleteOptionRow(currentIndex);
                              setAnchorEl(null);
                            }}
                            style={{
                              background: "#1f1f23",
                              color: "white",
                            }}
                          >
                            <DeleteIcon />
                          </Button>
                        </>
                      )}
                    </Popover>
                    <div
                      key={index}
                      style={{
                        background: "#1f1f23",
                        color: "white",
                        margin: "5px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          fontSize: "0.7rem",
                          alignItems: "center",
                        }}
                      >
                        {option}
                        <MoreVertIcon
                          style={{ float: "right" }}
                          onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                            setCurrentIndex(index);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <Handle
                    type="source"
                    id={`${index}`}
                    position={Position.Right}
                    isConnectable={isConnectable}
                    style={{
                      borderRadius: "0 4px 4px 0",
                      background: "black",
                      width: "10px",
                      // adjust the position of the handle to the number of conditions
                      top: `${188 + (index * 10 + 10) + index * 19}px`,
                      height: "10px",
                    }}
                  />
                </div>
              );
            })}
            <Button
              sx={{
                background: "#1f1f23",
                color: "white",
                fontSize: "0.7rem",
                marginTop: "20px",
                "&:hover": {
                  background: "#7f007f",
                  transition: "background-color 5s",
                },
              }}
              onClick={() => {
                setOptions([...options, "Nova opção"]);
              }}
            >
              Adicionar opção
            </Button>
          </CardContent>
        </Card>
      </Box>
      <Handle
        type="source"
        id="else"
        isConnectable={isConnectable}
        position={Position.Bottom}
        style={{
          borderRadius: "0 4px 4px 0",
          background: "black",
          width: "10px",
          height: "10px",
        }}
      />
      <Handle
        type="target"
        position={Position.Left}
        id="a"
        isConnectable={isConnectable}
        style={{
          borderRadius: "0 4px 4px 0",
          background: "black",
          width: "10px",
          height: "10px",
        }}
      />
    </div>
  );
}

export default ChoiceNode;
