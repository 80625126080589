import React, { createContext, useState } from "react";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [pathType, setPathType] = useState(() => {
    const path = localStorage.getItem("pathType");
    return JSON.parse(path ?? JSON.stringify("smoothstep"));
  });
  const [hasMiniMap, setHasMiniMap] = useState(() => {
    const miniMap = localStorage.getItem("hasMiniMap");
    return JSON.parse(miniMap ?? JSON.stringify(true));
  });
  const [darkMode, setDarkMode] = useState("dark");

  return (
    <ThemeContext.Provider
      value={{
        pathType,
        setPathType,
        hasMiniMap,
        setHasMiniMap,
        darkMode,
        setDarkMode,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
