import React, { useMemo, useEffect, useState, useRef } from "react";
import { Handle, Position } from "reactflow";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Table } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";

const hours = [
  { hour: "00:00" },
  { hour: "01:00" },
  { hour: "02:00" },
  { hour: "03:00" },
  { hour: "04:00" },
  { hour: "05:00" },
  { hour: "06:00" },
  { hour: "07:00" },
  { hour: "08:00" },
  { hour: "09:00" },
  { hour: "10:00" },
  { hour: "11:00" },
  { hour: "12:00" },
  { hour: "13:00" },
  { hour: "14:00" },
  { hour: "15:00" },
  { hour: "16:00" },
  { hour: "17:00" },
  { hour: "18:00" },
  { hour: "19:00" },
  { hour: "20:00" },
  { hour: "21:00" },
  { hour: "22:00" },
  { hour: "23:00" },
];

const DayNode = React.memo(({ data, isConnectable }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [selectedSegments, setSelectedSegments] = useState(
    data.nodeInfo?.selectedSegments ?? {
      Segunda: [],
      Terca: [],
      Quarta: [],
      Quinta: [],
      Sexta: [],
      Sabado: [],
      Domingo: [],
    }
  );
  useEffect(() => {
    const prevFlows = JSON.parse(localStorage.getItem("flows"));
    prevFlows.forEach((flow) => {
      const node = flow.nodes.find((node) => node.id === data.id);
      if (node) {
        const object = {
          selectedSegments: selectedSegments,
        };
        data.nodeInfo = object;
        localStorage.setItem("flows", JSON.stringify(prevFlows));
      }
    });
  }, [selectedSegments, data]);

  // PARTE DO DRAG

  const [isDragging, setIsDragging] = useState(false);
  const startDragIndex = useRef(null);

  const handleSegmentClick = (day, segmentIndex) => {
    setSelectedSegments((prevSelectedSegments) => {
      const newSelectedSegments = { ...prevSelectedSegments };

      // Ensure newSelectedSegments[day] is always an array
      if (!Array.isArray(newSelectedSegments[day])) {
        newSelectedSegments[day] = [];
      }

      if (newSelectedSegments[day].includes(segmentIndex)) {
        // Deselect segment if already selected
        newSelectedSegments[day] = newSelectedSegments[day].filter(
          (index) => index !== segmentIndex
        );
      } else {
        // Select segment if not selected
        newSelectedSegments[day] = [...newSelectedSegments[day], segmentIndex];
      }

      return newSelectedSegments;
    });
  };

  const handleMouseDown = (day, segmentIndex) => {
    setIsDragging(true);
    startDragIndex.current = segmentIndex;
    handleSegmentClick(day, segmentIndex);
  };

  const handleMouseEnter = (day, segmentIndex) => {
    if (isDragging && startDragIndex.current !== null) {
      setSelectedSegments((prevSelectedSegments) => {
        const newSelectedSegments = { ...prevSelectedSegments };

        // Ensure newSelectedSegments[day] is always an array
        if (!Array.isArray(newSelectedSegments[day])) {
          newSelectedSegments[day] = [];
        }

        const minIndex = Math.min(segmentIndex, startDragIndex.current);
        const maxIndex = Math.max(segmentIndex, startDragIndex.current);

        // Remove segments that are already selected
        newSelectedSegments[day] = newSelectedSegments[day].filter(
          (index) => index < minIndex || index > maxIndex
        );

        // Add new segments to the existing ones
        for (let i = minIndex; i <= maxIndex; i++) {
          if (!newSelectedSegments[day].includes(i)) {
            newSelectedSegments[day].push(i);
          }
        }

        return newSelectedSegments;
      });
    }
  };
  const timeStructure =
    "0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96";

  const timeArray = timeStructure.split(", ");

  const mapTimeWithinDay = (timeArray) => {
    const startTime = 0; // Start time in minutes
    const interval = 15; // Interval in minutes

    const add15Minutes = (time) => {
      const [hours, minutes] = time.split(":");
      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);
      date.setSeconds(0);
      date.setMilliseconds(0);
      date.setMinutes(date.getMinutes() + 15);
      return `${String(date.getHours()).padStart(2, "0")}:${String(
        date.getMinutes()
      ).padStart(2, "0")}`;
    };

    // Map each entry to a specific time within a day
    const mappedTimeArray = timeArray.map((entry, index) => {
      const minutes = startTime + index * interval;
      const hours = Math.floor(minutes / 60);
      const minutesOfDay = minutes % 60;
      const plus15 = add15Minutes(
        `${String(hours).padStart(2, "0")}:${String(minutesOfDay).padStart(
          2,
          "0"
        )}`
      );

      return {
        entry,
        timeOfDay: `${String(hours).padStart(2, "0")}:${String(
          minutesOfDay
        ).padStart(2, "0")} ~ ${plus15}`,
      };
    });

    return mappedTimeArray;
  };

  const RenderSegments = (day) => {
    const segments = useMemo(() => {
      const result = [];
      for (let i = 0; i < 96; i++) {
        const isSelected = selectedSegments[day]?.includes(i);
        result.push(
          <div
            title={mapTimeWithinDay(timeArray)[i].timeOfDay}
            key={i}
            style={{
              flex: "1",
              height: "40px",
              width: "10px",
              background: isSelected ? "blue" : "#eee",
              border: "1px solid #ddd",
              cursor: "pointer",
            }}
            onMouseDown={() => handleMouseDown(day, i)}
            onMouseEnter={() => handleMouseEnter(day, i)}
            onMouseUp={handleMouseUp}
          ></div>
        );
      }
      return result;
    }, [day, selectedSegments]);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "1007px",
        }}
      >
        {segments}
      </div>
    );
  };

  // const renderSegments = (day) => {
  //   const segments = [];
  //   const timeMap = mapTimeWithinDay(timeArray);
  //   const styleBase = {
  //     flex: "1",
  //     height: "40px",
  //     width: "10px",
  //     border: "1px solid #ddd",
  //     cursor: "pointer",
  //   };

  //   for (let i = 0; i < 96; i++) {
  //     const isSelected = selectedSegments[day]?.includes(i);
  //     const style = {
  //       ...styleBase,
  //       background: isSelected ? "blue" : "#eee",
  //     };

  //     segments.push(
  //       <div
  //         title={timeMap[i].timeOfDay}
  //         key={i}
  //         style={style}
  //         onMouseDown={() => handleMouseDown(day, i)}
  //         onMouseEnter={() => handleMouseEnter(day, i)}
  //         onMouseUp={handleMouseUp}
  //       ></div>
  //     );
  //   }

  //   return segments;
  // };

  const handleMouseUp = () => {
    setIsDragging(false);
    startDragIndex.current = null;
  };

  // FINAL DA PARTE DO DRAG

  return (
    <div className="dayNodeBody">
      <Button
        onClick={() => {
          handleOpen();
        }}
      >
        Selecionar horários
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10vh",
          }}
        >
          {/* button to close the modal */}
          <Button
            onClick={() => {
              handleClose();
            }}
            style={{
              position: "absolute",
              top: "5.1vh",
              right: "10vw",
              background: "red",
              color: "white",
            }}
          >
            Fechar
          </Button>
          <TableContainer
            component={Paper}
            sx={{
              width: "80vw",
              background: "white",
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Horários</TableCell>
                  <TableRow>
                    {hours.map((hour) => (
                      <TableCell
                        align="right"
                        key={hour.hour}
                        id={hour.hour}
                        sx={{
                          transform: "rotate(90deg)",
                          paddingTop: "20px",
                          paddingBottom: "22px",
                          paddingLeft: "3px",
                          paddingRight: "3px",
                        }}
                      >
                        {hour.hour}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    Segunda
                    <Button
                      onClick={() => {
                        // delete the selected segments from segunda
                        setSelectedSegments((prevSelectedSegments) => {
                          const newSelectedSegments = {
                            ...prevSelectedSegments,
                          };
                          newSelectedSegments["Segunda"] = [];
                          return newSelectedSegments;
                        });
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    {RenderSegments("Segunda")}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">
                    Terça
                    <Button
                      onClick={() => {
                        // delete the selected segments from segunda
                        setSelectedSegments((prevSelectedSegments) => {
                          const newSelectedSegments = {
                            ...prevSelectedSegments,
                          };
                          newSelectedSegments["Terca"] = [];
                          return newSelectedSegments;
                        });
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                  <TableCell align="right">{RenderSegments("Terca")}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">
                    Quarta
                    <Button
                      onClick={() => {
                        // delete the selected segments from segunda
                        setSelectedSegments((prevSelectedSegments) => {
                          const newSelectedSegments = {
                            ...prevSelectedSegments,
                          };
                          newSelectedSegments["Quarta"] = [];
                          return newSelectedSegments;
                        });
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    {RenderSegments("Quarta")}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">
                    Quinta
                    <Button
                      onClick={() => {
                        // delete the selected segments from segunda
                        setSelectedSegments((prevSelectedSegments) => {
                          const newSelectedSegments = {
                            ...prevSelectedSegments,
                          };
                          newSelectedSegments["Quinta"] = [];
                          return newSelectedSegments;
                        });
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    {RenderSegments("Quinta")}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">
                    Sexta
                    <Button
                      onClick={() => {
                        // delete the selected segments from segunda
                        setSelectedSegments((prevSelectedSegments) => {
                          const newSelectedSegments = {
                            ...prevSelectedSegments,
                          };
                          newSelectedSegments["Sexta"] = [];
                          return newSelectedSegments;
                        });
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                  <TableCell align="right">{RenderSegments("Sexta")}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">
                    Sábado
                    <Button
                      onClick={() => {
                        // delete the selected segments from segunda
                        setSelectedSegments((prevSelectedSegments) => {
                          const newSelectedSegments = {
                            ...prevSelectedSegments,
                          };
                          newSelectedSegments["Sabado"] = [];
                          return newSelectedSegments;
                        });
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    {RenderSegments("Sabado")}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">
                    Domingo
                    <Button
                      onClick={() => {
                        // delete the selected segments from segunda
                        setSelectedSegments((prevSelectedSegments) => {
                          const newSelectedSegments = {
                            ...prevSelectedSegments,
                          };
                          newSelectedSegments["Domingo"] = [];
                          return newSelectedSegments;
                        });
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    {RenderSegments("Domingo")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Modal>
      <Tooltip title="Saída para caso o horário esteja dentro do intervalo selecionado">
        <Handle
          type="source"
          position={Position.Right}
          id="within"
          isConnectable={isConnectable}
          style={{
            borderRadius: "0 4px 4px 0",
            background: "black",
            width: "10px",
            height: "10px",
          }}
        />
      </Tooltip>
      <Tooltip title="Saída para caso o horário esteja fora do intervalo selecionado">
        <Handle
          type="source"
          position={Position.Bottom}
          id="outside"
          isConnectable={isConnectable}
          style={{
            borderRadius: "0 4px 4px 0",
            background: "darkred",
            width: "10px",
            height: "10px",
          }}
        />
      </Tooltip>

      <Handle
        type="target"
        position={Position.Left}
        id="a"
        isConnectable={isConnectable}
        style={{
          borderRadius: "0 4px 4px 0",
          background: "black",
          width: "10px",
          height: "10px",
        }}
      />
    </div>
  );
});

export default DayNode;
