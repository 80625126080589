import { Handle, Position } from "reactflow";
import Box from "@mui/material/Box";
import FlagIcon from "@mui/icons-material/Flag";

function EndNode({ data, isConnectable }) {
  return (
    <div className="startNodeBody">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <h1 style={{ color: "white", marginRight: "10px" }}>Fim</h1>
        <FlagIcon />
      </Box>
      <Handle
        type="target"
        position={Position.Left}
        id="b"
        isConnectable={isConnectable}
        style={{
          borderRadius: "0 4px 4px 0",
          background: "black",
          width: "10px",
          height: "10px",
        }}
      />
    </div>
  );
}

export default EndNode;
