import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import PersistentDrawerLeft from "../drawer";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Logo from "../../assets/Logo.png";
import Emulador from "../emulador";
import Tooltip from "@mui/material/Tooltip";

const HeaderAppBar = ({
  flows,
  addNewFlow,
  setIndex,
  setFlows,
  index,
  userId,
  saveFlows,
  botId,
}) => {
  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0); // State to manage active tab index
  const [openRightDrawer, setOpenRightDrawer] = useState(false);

  // Handler to toggle the drawer open/close
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleRightDrawerOpen = () => {
    setOpenRightDrawer(!openRightDrawer);
  };

  // Handler to delete a flow
  const handleDeleteFlow = (flowIndex) => {
    const confirmation = window.confirm(
      "Tem certeza que deseja deletar esse fluxo?"
    );
    if (!confirmation) return;
    const newFlows = flows.filter((flow, index) => index !== flowIndex);
    setFlows(newFlows);
    setIndex(0);
  };

  // Handler to edit the name of a flow
  const handleEditFlowName = (flowIndex) => {
    const newFlows = [...flows];
    const newName = prompt("Digite o novo nome do fluxo");
    if (newName) {
      newFlows[flowIndex].name = newName;
      newFlows[flowIndex].label = newName;
      setFlows(newFlows);
    }
  };

  return (
    <AppBar
      position="static"
      style={{
        background: "#384449",
        height: "90px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {/* <Toolbar
        style={{
          display: "flex",
          flexDirection: "row",
          maxWidth: "100vw",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      > */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          overflowX: "hidden",
          alignItems: "center",
          width: "1000px",
        }}
      >
        <PersistentDrawerLeft
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          botId={botId}
          userId={userId}
        />
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleDrawerOpen}
        >
          <MenuIcon />
        </IconButton>

        {/* Button to add a new flow */}
        <Button
          color="inherit"
          onClick={addNewFlow}
          style={{
            minWidth: "104px",
          }}
        >
          Novo fluxo
        </Button>

        {/* Tabs for displaying flows */}

        <Tabs
          value={index}
          variant="scrollable"
          onChange={(e, newValue) => setTabIndex(newValue)}
          sx={{
            marginLeft: "20px",
            overflowX: "hidden",
            maxWidth: "1000px",
          }}
          scrollButtons="auto"
          TabIndicatorProps={{
            style: {
              background: "#5b5bf7",
            },
          }}
        >
          {flows.map((tab, tabIndex) => (
            <Tab
              label={tab.label}
              onClick={() => setIndex(tabIndex)}
              key={tabIndex}
              icon={
                <div>
                  {/* Edit icon for editing flow name */}
                  <EditIcon
                    sx={{
                      color: "#7d8b91",
                      opacity: "0.23",
                      marginLeft: "5px",
                      ":hover": {
                        color: "#607d8b",
                        transition: "all 0.5s ease-in-out",
                        opacity: "1",
                        transform: "rotate(316deg)",
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditFlowName(tabIndex);
                    }}
                  />

                  {/* Delete icon for deleting a flow */}
                  <DeleteOutlineIcon
                    sx={{
                      color: "#f93131",
                      opacity: "0.35",
                      ":hover": {
                        color: "#f93131",
                        opacity: "1",
                        transform: "rotate(-360deg)",
                        transition: "all 0.5s ease-in-out",
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteFlow(tabIndex);
                    }}
                  />
                </div>
              }
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "space-between",
                color: "white",
                // Don't ornament the active tab
                "&.Mui-selected": {
                  color: "white",
                  background: "transparent",
                },
              }}
            />
          ))}
        </Tabs>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // position: "absolute",
          // right: "10",
        }}
      >
        <Emulador
          open={openRightDrawer}
          handleDrawerOpen={handleRightDrawerOpen}
          botId={botId}
          userId={userId}
        />
        <Tooltip title="Simule o comportamento do seu fluxo.">
          <Button
            color="inherit"
            onClick={handleRightDrawerOpen}
            style={{
              backgroundColor: "#5b5bf7",
              opacity: "0.9",
              minWidth: "90px",
            }}
          >
            Emulador
          </Button>
        </Tooltip>
        <img
          src={Logo}
          alt="logo"
          style={{
            width: "211px",
            marginTop: "-7px",
            justifySelf: "flex-end",
          }}
        />
        {/* Drawer component */}
      </div>
    </AppBar>
  );
};

export default HeaderAppBar;
